<template>
    <div class="recommend-event">
        <div class="triangle"></div>
        <div class="head">
            <div class="title">Room报名事件</div>
        </div>
        <div style="padding: 10px 24px 24px 24px">
            <div v-for="(item, index) in eventList" :key="index">
                <div @click="a('/room/info/' + item.roomId)" v-if="item.roomName" class="hand op" style="font-size: 14px; color: #2970FF; margin: 20px 0 10px 0">
                    <i class="el-icon-s-home" style="margin-right: 4px"></i>
                    <span>{{item.roomName}}</span>
                </div>
                <div style="display: flex;">
                    <div style="width: 70%; margin-right: 10px">
                        <div style="font-weight: 600; text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;">
                            {{item.title}}
                        </div>
                        <div style="font-size: 14px; margin-top: 10px; color: #999999">{{item.endTime}}</div>
                    </div>
                    <div style="width: 28%">
                        <img :src="item.cover" style="width: 74px; height: 44px;">
                        <div @click="toEvent(item.id)" class="hand op" style="font-size: 14px; color: #2970FF">查看详情 ></div>
                    </div>
                </div>
                <hr style="border:1px solid #999999;margin: 20px 0;"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"RoomEvent",
    components: {

    },
    computed: {
        user() {
            return this.$store.state.user
        },
        is_login(){
            return this.$store.state.is_login
        }
    },
    data () {
        return {
            eventList: [],
        }
    },
    props:{
    },
    created:function(){

    },
    mounted: function () {
        this.getEventList()
    },
    methods:{
        // 进入事件详情
        toEvent(id) {
            this.$parent.toEvents(id)
        },

        // 更多商务信息
        moreEvents() {
        },

        // 跳转详情
        toBusiness(id) {
            this.utils.b('/index/details/' + id)
        },

        // 获取事件
        getEventList() {
            this.newApi.getRoomEnrollEventList({type: 2, status: 2, roomId: ''}).then(res => {
                this.eventList = res.data;
            })
        },
    }
}

</script>

<style scoped>
.recommend-event{
    width: 100%;
    min-height: 100vh;
    height:auto;
    background-color: #FFFFFF;
    position: relative;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    box-sizing: border-box;
}
.recommend-event .triangle{
    position:absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 14px solid #fff;
    border-top-color: #031F88;
    border-left-color: #031F88;
    border-bottom-color:transparent ;
    border-right-color: transparent;
}
.recommend-event .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0 24px;
}
.recommend-event .head .title{
    height: 26px;
    font-size: 18px;
    font-weight: 600;
    color: #031F88;
}
</style>