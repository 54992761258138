<template>
    <div class="friend-group">
        <div v-if="type === 1">
            <div style="padding: 16px 20px">
              <el-autocomplete
                  style="display:inline"
                  value-key="feiendsRemarks"
                  v-model="selectContent"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="搜索好友"
                  @select="openFriendInfo"
                  :highlight-first-item="true"
                  :trigger-on-focus="false"
                  :clearable = "true"
              ></el-autocomplete>
                <div style=" margin: 10px 0 0 0; display: flex; justify-content: center">
                    <div class="hand op" @click="showChangeGroupName" style="width: 100px; text-align: center; margin-right: 20px; padding: 2px 0; border: 1px solid #BDBFC1">+ 新建分组</div>
                  <div class="hand op" @click="toAddFriends" style="width: 100px; text-align: center; margin-right: 20px; padding: 2px 0; border: 1px solid #BDBFC1">+ 添加好友</div>
                </div>
            </div>
            <el-menu @open="onOpen" @close="onClose" :default-active="defaultOpened">
                <div class="scroll" style="width:100%;max-height: 760px; overflow-y: scroll;overflow-x: hidden">
                    <el-submenu style="margin-bottom: 4px" v-for="(item, index) in groups" :key="index" :index="String(index)">
                        <template slot="title">
                            <div style="display: flex; justify-content: space-between; height: 40px; line-height: 40px;">
                                <i :class="item.show === true ? 'el-icon-caret-bottom' : 'el-icon-caret-right'" style="margin: 10px 0 0 0; font-size: 20px;"></i>
                                <div style="margin: 0 8px; font-size: 14px">{{item.name}}</div>
                                <div v-if="show_g_name == true" style="margin: 0 8px; font-size: 14px">
                                    <el-input></el-input>
                                </div>
                                <el-dropdown placement="bottom-start" @command="($event)=>select_group_operation($event, item)">
                                    <i class="el-icon-more" style="float: right; margin-top: 10px"/>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="rename">重命名</el-dropdown-item>
                                        <el-dropdown-item command="delete">删除组</el-dropdown-item>
                                        <el-dropdown-item command="management">管理好友</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </template>
                        <div v-if="item.friendArr.length !==0">
                            <el-menu-item v-for="(friend, key) in item.friendArr" :key="key" :index="friend.friendInfo.uuid">
                                <div @click="openInfo(friend.friendInfo.uuid, friend.friendInfo.userType)" style="display: flex; margin: 4px 0;width: 100%; height: 40px; line-height: 40px;">
                                    <img :src="friend.friendInfo.headIco" style="width: 36px; height: 36px; margin: 6px 8px 0 -20px; border-radius: 50%"/>
                                    <div style="width: 86px;display: flex; flex-direction: column; margin-top: 8px">
                                        <div style="width: 170px; height: 30px; font-weight: 600; overflow: hidden; line-height: 16px;text-overflow:ellipsis;">{{friend.friendInfo.nickName}}</div>
                                        <div style="width: 170px; height: 30px; font-size: 12px; color: #AAAAAA; overflow: hidden;line-height: 18px;text-overflow:ellipsis;">{{friend.friendInfo.major}}</div>
                                    </div>
                                </div>
                            </el-menu-item>
                        </div>

                        <div v-if="index !== 0" style="text-align: center; margin: 10px 0;">
                            <el-button @click="to_group_operation(groups[0], item)" style="width: 120px; height: 32px; background-color: #2970FF; color: #fff; font-size: 14px; line-height: 1px;" rounf>
                                <i class="el-icon-plus" style="width: 12px; height: 12px; font-size: 12px; margin-right: 0; line-height: 10px; color: #fff; font-weight: 600"/>
                                <span>新增分组好友</span>
                            </el-button>
                        </div>
                    </el-submenu>

                    <div class="management-dialog">
                        <el-dialog width="698px" :visible.sync="show_management" append-to-body>
                            <div style="width: 100%; display: flex; margin-left: 20px">
                                <div class="management-box">
                                    <div class="management-tittle">
                                        <div>{{friendData.name}}</div>
                                        <div v-if="friendData.friendArr" class="friend-num">（{{friendData.friendArr.length}}）</div>
                                    </div>
                                    <div class="management-body">
                                        <div style="padding: 10px">
                                            <div class="management-select" v-for="(item, index) in friendData.friendArr" :key="index">
                                                <el-radio v-model="selectFriend" :label="index">{{item.friendInfo.nickName}}</el-radio>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="margin: 27% 26px">
                                    <div style="margin-bottom: 20px">
                                        <el-button type="primary" size="small" @click="deleteFriends">删 除</el-button>
                                    </div>
                                    <div>
                                        <el-button type="primary" size="small" @click="moveFriends">添 加</el-button>
                                    </div>

                                </div>
                                <div class="management-box">
                                    <div class="management-tittle">
                                        <div>好友分组列表</div>
                                        <div class="friend-num">（{{groups.length}}）</div>
                                    </div>
                                    <div class="management-body">
                                        <div style="padding: 10px">
                                            <div class="management-select" v-for="(item, index) in groups" :key="index">
                                                <el-radio v-model="selectGroup" :label="item.id">{{item.name}}</el-radio>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div slot="footer" class="dialog-footer">
                                <el-button type="primary" size="mini" @click="friendsManagementEnd">确 定</el-button>
                            </div>
                        </el-dialog>
                    </div>

                  <div class="management-dialog">
                    <el-dialog width="800px" :visible.sync="show_management_one" append-to-body>
                      <div v-if="friendData.friendArr">
                        <div style="padding: 10px 30px 30px 30px; display: flex; flex-wrap: wrap" v-if="friendData.friendArr.length > 0">
                          <div class="ref-box hand" :style="{border: selectFriend === index ? '2px solid #2970FF' : ''}" v-for="(item, index) in friendData.friendArr" :key="index" @click="selectFriend = index">
                            <div style="display: flex;">
                              <div style="margin-right: 12px">
                                <img :src="item.friendInfo.headIco" style="width: 50px; height: 50px; border-radius: 50%"/>
                              </div>
                              <div class="major" style="margin-top: 2px">
                                <div class="userName" style="display: flex">
                                  <div style="max-width: 120px;font-size: 16px; font-weight: 600; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{item.friendInfo.nickName}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style="padding: 10px 30px 30px 30px; display: flex; flex-wrap: wrap" v-else>
                          暂无未分组的好友...
                        </div>
                      </div>
                      <div slot="footer" class="dialog-footer">
                        <el-button type="primary" size="mini" @click="moveFriends">确 定</el-button>
                      </div>
                    </el-dialog>
                  </div>

                    <div class="management-dialog">
                        <el-dialog width="600px" :visible.sync="edit_group_name" append-to-body>
                            <div style="margin: 20px; font-size: 16px; font-weight: 600">分组名称</div>
                            <el-input v-model="new_group_name" size="small" style="width: 300px; margin: 0 20px 20px 20px"></el-input>
                            <el-button type="primary" size="small" @click="newGrouping(changeGroupId)">确 定</el-button>
                        </el-dialog>
                    </div>
                </div>
            </el-menu>
        </div>
        <div v-if="type === 2" style="margin-top: 10px">
            <el-menu>
                <div class="scroll" style="width:100%;max-height: 760px; overflow-y: scroll;overflow-x: hidden" ref="sub">
                    <el-menu-item v-for="(item, index) in subList" :key="index" :index="item.uuid">
                        <div style="display: flex; margin: 4px 20px;width: 120px; height: 40px; line-height: 40px;" @click="openInfo(item.uuid, item.userType)">
                            <img :src="item.headIco" style="width: 36px; height: 36px; margin: 6px 8px 0 -18px; border-radius: 50%"/>
                            <div style="display: flex; flex-direction: column; margin-top: 8px">
                                <div style="width: 100px; height: 30px; font-weight: 600; overflow: hidden; line-height: 16px;text-overflow:ellipsis;">{{item.nickName}}</div>
                                <div style="width: 86px; height: 30px; font-size: 12px; color: #AAAAAA; overflow: hidden;line-height: 18px;text-overflow:ellipsis;">{{item.major}}</div>
                            </div>
                        </div>
                    </el-menu-item>
                </div>
            </el-menu>
        </div>
      <div class="event-form">
        <el-dialog :visible.sync="showAddFriend" width="800px" append-to-body>
          <div style="display: flex">
            <div style="margin: 0 20px; font-size: 16px; font-weight: 600">搜索用户名称</div>
            <el-input v-model="searchContent" size="small" style="width: 300px; margin: 0 20px 20px 20px"></el-input>
            <el-button type="primary" size="small" style="height: 32px" @click="searchUser"><i class="el-icon-search"></i> 搜索</el-button>
          </div>

          <div style="padding: 10px 30px 30px 30px; display: flex; flex-wrap: wrap" v-if="searchFriendList.length > 0">
            <div class="ref-box hand op" v-for="(item, index) in searchFriendList" :key="index" @click="openUserInfo(item.uuid, item.userType)">
              <div style="display: flex;">
                <div style="margin-right: 12px">
                  <img :src="item.headIco" style="width: 50px; height: 50px; border-radius: 50%"/>
                </div>
                <div class="major" style="margin-top: 2px">
                  <div class="userName" style="display: flex">
                    <div style="max-width: 120px;font-size: 16px; font-weight: 600; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{item.nickName}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="padding: 10px 30px 30px 30px; display: flex; flex-wrap: wrap" v-else>
              没有查到此用户，请重新搜索...
          </div>
        </el-dialog>
      </div>

    </div>
</template>

<script>

export default {
    name: "FriendsGroup",
    components: {},
    data() {
        return {
            selectContent: '',

            show_management_one: false,

            // 分组
            edit_group_name: false,
            new_group_name: '',
            show_g_name: false,
            show_management: false,
            changeGroupId: '',
            selectFriend: '',
            selectGroup: '',
            friendData: {},
            groups: [],
            defaultOpened: '',

            subMore: 1,
            subPage: 1,
            pageSize: 20,
            subList: [],

            showAddFriend: false,
            searchContent: '',
            searchFriendList: [],
        }
    },
    props: {
        type: {
            type: Number
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    watch: {
      type: {
          handler() {
              if (this.type === 1) {
                  this.getFriendGroups()
              }
              if (this.type === 2) {
                  this.subPage = 1;
                  this.subMore = 1;
                  this.subList = [];
                  this.getOrganList()
              }
          }
      }
    },
    created: function () {

    },
    mounted: function () {
        if (this.type === 1) {
            this.getFriendGroups()
        }
        if (this.type === 2) {
            this.getOrganList()
            var that = this;
            if (that.$refs.sub) {
                that.$refs.sub.onscroll = function(){
                    if(that.$refs.sub.scrollHeight - that.$refs.sub.scrollTop - that.$refs.sub.clientHeight === 0){
                        if (that.subMore == 1) {
                            that.getOrganList()
                        }else{
                            console.log("已加载完");
                        }
                    }
                }
            }
        }
    },
    methods: {
        // 添加好友
        toAddFriends() {
          if (!this.utils.toAuth(1)) {
            return false
          }
          this.showAddFriend = true
        },

        // 点击打开用户信息
        openUserInfo(uuid, userType) {
          if (!this.utils.toAuth(1)) {
            return false
          }
          if (userType === 1) {
            this.utils.a('/circle/mySpace/' + uuid)
          }
          if (userType === 2 || userType === 3) {
            this.utils.a('/circle/organSpace/' + uuid)
          }
        },

        // 搜索好友
        querySearchAsync(searchContent, cb) {
          this.newApi.getFriendList({like:searchContent}).then(res=>{
            cb(res.data);
          })
        },

        // 搜索用户
        searchUser() {
          if (this.searchContent) {
            this.newApi.searchStranger({like: this.searchContent}).then(res => {
              if (res.isSuccess === 1) {
                this.searchFriendList = res.data
              }
            })
          } else {
            this.utils.msg('请输入用户名称搜索')
          }
        },

        onOpen(e) {
            this.$nextTick(() => {
                this.$set(this.groups[e], 'show', true)
            });
        },

        onClose(e) {
            this.$nextTick(() => {
                this.$set(this.groups[e], 'show', false)
            });
        },

        // 获取机构通讯录
        getOrganList() {
            this.newApi.getInstitutionContacts({page: this.subPage, pageSize: this.pageSize}).then(res =>{
                if (this.subPage === 1 && res.data.length > 0) {
                  this.openInfo(res.data[0].uuid)
                }
                for (const item of res.data) {
                    this.subList.push(item)
                    this.subMore = 1;
                }
                if (res.data.length < 20) {
                    this.subMore = 0;
                } else {
                    this.subPage ++;
                }
            })
        },

        // 点击关注打开用户信息
        openInfo(uuid) {
          if (!this.utils.toAuth(1)) {
            return false
          }
          this.$emit('showFriendInfo', uuid)

        },

        // 展示搜索好友信息
        openFriendInfo(item) {
            this.openInfo(item.friendInfo.uuid)
            this.defaultOpened = ''
        },

        // 删除好友
        deleteFriends() {
            if (this.selectFriend === '') {
                this.utils.err('请选择至少一个好友！')
            } else {
                var that = this;
                that.utils.confirm('确定要删除此好友吗？', function () {
                    that.newApi.delFriends({uuid: that.friendData.friendArr[that.selectFriend].friendInfo.uuid}).then(res =>{
                        that.getFriendGroups();
                        that.friendData.friendArr.splice(that.selectFriend, 1);
                        that.selectGroup = '';
                        that.selectFriend = '';
                        that.utils.sus(res.data)
                    })
                })
            }
        },

        // 切换分组
        moveFriends() {
            if (this.selectFriend === '') {
                this.utils.err('请选择至少一个好友！')
            }
            else if (!this.selectGroup) {
                this.utils.err('请选择至少一个分组！')
            }
            else {
                this.newApi.friendsAgainGroup({uuid: this.friendData.friendArr[this.selectFriend].friendInfo.uuid, groupId: this.selectGroup}).then(res =>{
                    this.getFriendGroups();
                    this.friendData.friendArr.splice(this.selectFriend, 1);
                    this.selectGroup = '';
                    this.selectFriend = '';
                    this.utils.sus(res.data)
                })
            }
        },

        // 获取好友分组
        getFriendGroups() {
            this.newApi.getMyFriendGroupList({}).then(res =>{
                this.groups = res.data
                for (let i = 0; i < res.data.length; i++) {
                  if (res.data[i].friendArr.length > 0) {
                    this.onOpen(i)
                    this.defaultOpened = res.data[i].friendArr[0].friendInfo.uuid
                    this.openInfo(res.data[i].friendArr[0].friendInfo.uuid)
                    return
                  } else {
                    this.openInfo()
                  }
                }

            })
        },

        // 点击新建分组
        showChangeGroupName() {
          var that = this;
          if (!this.utils.toAuth(1)) {
            return false
          }
          that.edit_group_name = true

        },

        newGrouping(id) {
            if (!this.new_group_name) {
                this.new_group_name = '未命名'
            }
            if (!id) {
                this.newApi.addFriendGroup({name: this.new_group_name}).then(res => {
                    if (res.isSuccess == 1) {
                        this.utils.sus(res.data)
                        this.edit_group_name = false
                        this.new_group_name = ''
                        this.getFriendGroups()
                    }
                })
            } else {
                this.newApi.saveFriendGroupName({newName: this.new_group_name, groupId: id}).then(res => {
                    if (res.isSuccess == 1) {
                        this.utils.sus(res.data)
                        this.edit_group_name = false
                        this.new_group_name = ''
                        this.changeGroupId = ''
                        this.getFriendGroups()
                    }
                })
            }

        },

      to_group_operation(item, select) {
          this.friendData = item
          this.selectGroup = select.id
          this.show_management_one = true
      },

        // 选择分组操作项
        select_group_operation(e, item) {
            if (e == "delete") {
                this.newApi.delFriendGroup({id: item.id}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.utils.sus(res.data)
                        this.getFriendGroups()
                    }
                })
            }

            if (e == "rename") {
                this.edit_group_name = true;
                this.changeGroupId = item.id

            }
            if (e == "management") {
                this.friendData = item
                this.show_management = true
            }

        },

        // 管理好友穿梭框确认
        friendsManagementEnd() {
            this.show_management = false
        },
    }
}
</script>

<style scoped>
.el-menu{
    border: none
}

.management-box {
    width: 240px;
    height: 500px;
    border: 1px #F5F5F5 solid;
  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
}

.management-tittle {
    height: 30px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    padding: 10px;
    background-color: #F5F5F5;
}

.management-body {
    overflow: auto;
    width: 100%;
    max-height: 360px;
}

.management-select {
    font-size: 18px;
    margin-bottom: 12px;
}

.friend-num {
    font-size: 14px;
    margin-top: 6px;
    color: #999999;
}
/deep/ .el-submenu__icon-arrow {
    display: none !important;
}
/deep/ .el-submenu__title {
    height: 42px !important;
    line-height: 42px !important;
}


.is-active {
    background-color: #EEEEEE !important;
    color: #303133 !important;
}

.el-submenu__title:hover {
    background-color: #EEEEEE !important;
}
.el-menu-item:focus, .el-menu-item:hover {
    background-color: #EEEEEE !important;
}
.el-menu-item {
    min-width: 0 !important;
}
.el-input--mini {
    width: 70px !important;
    height: 18px !important;
}
.el-input__inner {
    border-radius: 0;
    padding: 0;
}

.scroll::-webkit-scrollbar {
    display: none;
}

.ref-box {
  width: 200px;
  height: 50px;
  border: 1px solid #DCDCDC;
  padding: 10px;
  margin: 0 10px 10px 0;
  border-radius: 5px 5px 5px 5px;
}

.event-form .top {
  text-align: center;
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: #444444;
  border: 1px solid #CECFD0;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
}

.el-divider--horizontal {
  margin: 20px 0;
}

.hand{
  cursor:pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/deep/ .event-form .el-dialog__header {
  padding: 0;
}

/deep/ .event-form .el-dialog__body {
  padding: 0;
}
</style>
