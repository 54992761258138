<template>
    <div class="friend-preview" style="overflow: hidden">
        <div ref="friendContent" style="float: left; width: 900px; margin-left: 10px" v-if="uuid">
            <div class="header">
                <FriendHead :key="this.uuid" :uuid="this.uuid"></FriendHead>
            </div>
            <div>
                <div class="right-tabs">
                    <div style="display: flex">
                      <div class="tab up" v-for="(item, index) in personalMenu" :key="index">
                        <label v-if="countStart <= index && index <= countEnd">
                          <div class="head_white hand">
                            <div :class="indexTags === item.key ? 'head_white_word_act': 'head_white_word'" @click="selectTags(item.key)">{{item.value}}</div>
                            <div class="head_white_line"  v-if="indexTags === item.key"></div>
                          </div>
                        </label>
                      </div>
                    </div>
                </div>
              <div class="right-select" v-if="indexTags === 'circle' || indexTags === 'article'">
                <div class="select-left">
                  <div>
                    <el-select size="small" @change="selectOption" style="width: 300px" v-model="indexSelect" placeholder="请选择">
                      <el-option
                          v-for="item in indexTags === 'circle' ? contentOptions : articleOptions"
                          :key="item.key"
                          :label="item.value"
                          :value="item.key">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>

                <div style=" width: 100%;margin-bottom: 10px;background-color: #fff;box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
                  <div style="overflow:hidden;" v-if="loading">
                    <div v-for="(item,index) in 10" v-bind:key="index"
                         style="width: 100%;height: auto;">

                      <el-skeleton
                          style="width: 90%;background-color: white;padding: 5%; margin-bottom: 10px;"
                          :loading="loading" animated>
                        <template slot="template">
                          <div style="overflow:hidden;">
                            <el-skeleton-item variant="image"
                                              style="width: 60px; height: 60px;border-radius:30px;float: left;"/>
                            <div style="float:left;display: flex; align-items: center; justify-content: space-between;">
                              <el-skeleton-item variant="text" style="width: 50%;"/>
                              <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 16px; height: 16px;">
                                <el-skeleton-item variant="text"
                                                  style="width: 100px; margin: 12.5px 10px ; height:15px;"/>
                                <el-skeleton-item variant="text"
                                                  style="width: 30px; margin: 12.5px 10px ; height:15px;"/>
                              </div>
                            </div>
                          </div>
                          <el-skeleton-item variant="image"
                                            style="width: 90%; height: 210px;padding : 0 5%; float: left;margin-top: 10px;"/>

                          <el-skeleton-item variant="text" style="width: 50%;margin-top: 16px; "/>
                          <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 16px; height: 16px;">
                            <el-skeleton-item variant="text" style="margin-right: 16px;"/>
                            <el-skeleton-item variant="text" style="width: 30%;"/>
                          </div>
                        </template>
                      </el-skeleton>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="indexTags == 'circle'" style="width: 100%; overflow:hidden;">
                      <div v-for="(item,index) in content_list" v-bind:key="item.id" >
                        <ContentALL :data="item" @deleteContent="deleteContent" :index="index"></ContentALL>
                      </div>
                    </div>

                    <div v-if="indexTags == 'article'" style="width: 100%; overflow:hidden;">
                      <div v-for="(item,index) in content_list" v-bind:key="item.id" >
                        <ContentALL :data="item" @deleteContent="deleteContent" :index="index"></ContentALL>
                      </div>
                    </div>

                    <div v-if="indexTags == 'message'" style="width: 96%; overflow:hidden; padding: 30px 20px 0 20px">
                      <div style="padding: 10px 20px" v-for="(item, index) in content_list" :key="index">
                        <div style="display: flex">
                          <div style="width: 100px;">
                            <el-button v-if="item.type == 1" class="hand op" style="width: 66px; height: 22px; background-color: #4482FF; color: #fff; border-radius: 20px; line-height: 0;">招标</el-button>
                            <el-button v-if="item.type == 2" class="hand op" style="width: 66px; height: 22px; background-color: #4482FF; color: #fff; border-radius: 20px; line-height: 0;">采购</el-button>
                            <el-button v-if="item.type == 3" class="hand op" style="width: 66px; height: 22px; background-color: #4482FF; color: #fff; border-radius: 20px; line-height: 0;">招募</el-button>
                            <el-button v-if="item.type == 4" class="hand op" style="width: 66px; height: 22px; background-color: #4482FF; color: #fff; border-radius: 20px; line-height: 0;">商务</el-button>
                            <el-button v-if="item.type == 5" class="hand op" style="width: 66px; height: 22px; background-color: #4482FF; color: #fff; border-radius: 20px; line-height: 0;">招商</el-button>
                            <el-button v-if="item.type == 6" class="hand op" style="width: 66px; height: 22px; background-color: #4482FF; color: #fff; border-radius: 20px; line-height: 0;">需求</el-button>
                          </div>
                          <div style="width: 56%; padding: 0 20px">
                            <div style="line-height: 24px; font-size: 18px; font-weight: 600;">
                              <span>{{item.title}}</span>
                            </div>
                            <div style="margin-top: 10px; display: flex; justify-content: space-between">
                              <div>
                                <span>预算： </span>
                                <span style="font-weight: 600">{{item.other.budget}}</span>
                              </div>
                              <div>
                                <span>业务类型：</span>
                                <span style="font-weight: 600">{{item.typeName}}</span>
                              </div>
                              <div>
                                <span>工作地点：</span>
                                <span style="font-weight: 600" v-if="item.address">{{item.address.join('> ')}}</span>
                              </div>
                            </div>
                          </div>
                          <div style="width: 30%; height: 40px;">
                            <div style="display: flex; justify-content: end; margin-right: 20px">
                              <el-button @click="b('/index/details/' + item.id)" class="hand op" style="width: 106px; height: 36px; line-height: 0; font-weight: 600; margin-right: 10px; border: #1a1a1a 2px solid">查看详情</el-button>
                            </div>
                          </div>
                        </div>
                        <el-divider></el-divider>
                      </div>
                    </div>

                    <div v-if="indexTags == 'questions'" style="width: 94%; overflow:hidden; padding: 30px 30px 0 30px">
                      <div v-for="(item, index) in content_list" :key="index">
                        <div style="display: flex">
                          <div class="hand op" style="width: 80px; margin-left: 20px" @click="a('/index/page/?id=' + item.id)">
                            <img src="../../assets/imgs/circle/red.png"/>
                          </div>
                          <div style="width: 90%;">
                            <div class="re-title hand op" style="display: flex; justify-content: space-between" @click="a('/index/page/?id=' + item.id)">
                              <div style="height: 24px; line-height: 24px; font-size: 18px; font-weight: 600;text-overflow:ellipsis ;overflow:hidden;white-space: nowrap;">{{item.title}}</div>
                            </div>
                            <div class="re-content">
                              <div style="color: #666666; margin-top: 10px; font-size: 13px; display: flex; justify-content: space-between">
                                <div>
                                  <span style="margin-right: 20px">{{item.TypeArrNameList[0]}} > {{item.TypeArrNameList[1]}} > {{item.TypeArrNameList[2]}}</span>
                                  <span>{{item.createTime}}</span>
                                </div>
                                <div>
                                  <span style="margin-right: 20px"><i class="el-icon-view"></i> {{item.reviewCount}}</span>
                                  <span><i class="el-icon-chat-line-round"></i> {{item.clickCount}}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <el-divider></el-divider>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <el-dialog
                :visible.sync="showVideo"
                width="60%"
                @close="showVideo = false"
                center>
                <div v-if="showVideo === true">
                    <video-player class="video-player vjs-custom-skin" id="videoPlayer" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
                </div>
            </el-dialog>
        </div>
        <div v-if="!uuid" style="float: left; background-color: #FFFFFF; width: 900px; margin-left: 10px">
            <RecommendFriend :type="1"></RecommendFriend>
        </div>
    </div>

</template>

<script>
    import SpaceHead from "./components/SpaceHead";
    import ContentALL from "./components/ContentAll";
    import Dynamic from "./components/Dynamic";
    import Enclosure from "./components/Enclosure";
    import FriendHead from "./components/FriendHead";
    import FriendsGroup from "./components/FriendsGroup";
    import RecommendFriend from "./components/recommend/recommendFriend";
    import NullBox from "./components/NullBox";
    export default {
        name: "friendPreview",
        components: {NullBox, RecommendFriend, FriendsGroup, FriendHead, Enclosure, Dynamic, ContentALL, SpaceHead},
        props: {
            isOrgan: {
                type: Boolean,
                default() {
                    return false
                }
            },
            userId: {
                type: String,
                default() {
                    return ""
                }
            }
        },
        data() {
            return {
              uuid: this.userId,
              indexTags: 'circle',
              indexSelect: 0,
              contentOptions: [
                {
                  key: 0,
                  value: '全部'
                },
                {
                  key: 1,
                  value: '动态'
                },
                {
                  key: 2,
                  value: '笔记'
                },
                {
                  key: 3,
                  value: '图片'
                },
                {
                  key: 4,
                  value: '视频'
                },
                {
                  key: 5,
                  value: '附件'
                },
              ],
              articleOptions: [
                {
                  key: 0,
                  value: '全部'
                },
                {
                  key: 6,
                  value: '文章'
                },
                {
                  key: 7,
                  value: '图片'
                },
                {
                  key: 8,
                  value: '视频'
                },
                {
                  key: 9,
                  value: '附件'
                },
              ],
                loading: false,
                page: 1,
                more: 1,
                pageSize: 10,
                countStart: 0,
                countEnd: 8,
                content_list:[],
              personalMenu: [
                {
                  key: 'circle',
                  value: '朋友圈'
                },
                {
                  key: 'article',
                  value: '知识仓库'
                },
                {
                  key: 'message',
                  value: '信息大厅'
                },
                {
                  key: 'questions',
                  value: '红包问答'
                }
              ],

                // 视频播放
                showVideo: false,
                playerOptions: {
                    playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                    autoplay: false, // 如果为true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 是否视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4", // 类型
                        src: '' // url地址
                    }],
                    poster: '', // 封面地址
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true // 是否显示全屏按钮
                    }
                },

            }
        },

        computed: {
            user() {
                return this.$store.state.user;
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },

        watch: {
            userId: {
                handler(newData) {
                    if (newData) {
                        this.uuid = newData;
                        this.indexTags = 'circle';
                        this.indexSelect = 0;
                        this.page = 1;
                        this.more = 1;
                        this.content_list = [];
                        document.documentElement.scrollTop = 0;
                        this.clickNavigation(this.indexTags);
                    }
                }
            }
        },

        updated() {
            if (this.uuid) {
                var that = this;
                window.onscroll = function(){
                    var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                    //变量windowHeight是可视区的高度
                    var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                    //变量scrollHeight是滚动条的总高度
                    var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                    if(scrollHeight - scrollTop - windowHeight <= 0){
                        if (that.more == 1 && that.loading === false) {
                            that.clickNavigation(that.indexTags);
                        }else{
                            console.log("已加载完");
                        }
                    }
                }
            }
        },

      created() {
      },

      mounted() {
            if (this.uuid) {
                this.indexTags = 'circle';
                this.clickNavigation(this.indexTags);
                //接收事件
                this.$EventBus.$on("changeData", ({ changeData }) => {
                    this.$nextTick(() => {
                        this.page = 1;
                        this.more = 1;
                        this.content_list = [];
                        this.clickNavigation(this.indexTags);
                    });
                });
            }
            this.$EventBus.$on("goBackRecommend", () => {
                this.$nextTick(() => {
                    this.uuid = ''
                });
            });
        },

        methods: {
            // 删除内容更新
            deleteContent(e) {
              this.content_list.splice(e, 1)
            },

            // 转发后获取动态
            getForwardContent() {
                this.page = 1;
                this.more = 1;
                this.content_list = [];
                this.clickNavigation(this.indexTags)
            },

            // 视频播放准备
            playVideo(url, imgUrl) {
                this.showVideo = true;
                this.playerOptions.sources[0].src = url;
                this.playerOptions.poster = imgUrl;
            },

          // 跳转至详情页
          content_info(item){
            this.utils.b('/content/info/' + item.id + "/2");
          },

            // 计数
            counterEnd() {
                if (this.countEnd < this.personalMenu.length - 1) {
                    this.countEnd ++;
                    this.countStart ++;
                }
            },
            counterStart() {
                if (this.countStart > 0) {
                    this.countEnd --;
                    this.countStart --;
                }
            },

          // 切换类型
          selectOption() {
            this.content_list = [];
            this.page = 1;
            this.more = 1;
            this.clickNavigation(this.indexTags)
          },

          // 切换菜单
          selectTags(str) {
            this.indexSelect = 0;
            this.indexTags = str;
            this.content_list = [];
            this.page = 1;
            this.more = 1;
            this.clickNavigation(str)
          },

          // 获取动态
          clickNavigation(str){
            var that = this;
            var type = 0;
            that.loading = true;
            switch (str)
            {
              case 'circle':type = 1;
                break;
              case 'article':type = 2;
                break;
              case 'message':type = 3;
                break;
              case 'questions':type = 4;
                break;
            }
            if (that.loading) {
              that.newApi.getSpaceContent({uuid: that.uuid, type: type, page: that.page, pageSize: that.pageSize, bgbType: that.indexSelect}).then(res =>{
                for (const item of res.data) {
                  that.content_list.push(item)
                }
                if (res.data.length < 10) {
                  that.more = 0;
                }
                that.page++;
                that.loading = false;
              })
            }
          },
        }
    }
</script>

<style scoped>
    .header {
        margin: 0 0 10px 0;
        width: 100%;
        height: 220px;
        background: #ffffff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }

    .right-tabs {
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2px;
        background-color: white;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)
    }

    .right-select {
      width: 100%;
      margin-bottom: 10px;
    }

    .select-left {
      display: flex;
      padding: 10px 20px;
      background-color: white;
      box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)
    }

    .tab {
        text-align: center;
        margin: auto 3%;
        float: left;
        text-align: center;
        cursor:pointer;
    }
    .up {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.1s;
    }
    .head_white {
        height: 26px;
        padding: 14px 0;
        width: 80px
    }

    .Dynamic{
        width: 100%;
        padding: 10px 38px 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        background-color: #ffffff;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        cursor: pointer;
    }

    .head_white_word {
        width: 80px;
        height: 26px;
        text-align: center;
        line-height:26px;
        color: #999999;
        font-size: 18px;
        font-weight: 600;
        float: left;
    }

    .head_white_word_act {
        width: 80px;
        height: 26px;
        text-align: center;
        line-height:26px;
        color: #2970FF;
        font-size: 18px;
        font-weight: 600;
        float: left;
    }

    .inner-container {
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .inner-container::-webkit-scrollbar {
        display: none;
    }

    /*公用及el*/
    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;
        transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }

</style>
