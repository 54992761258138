<template>
	<div style="height: 100%;">
		<el-upload :on-change="change" :file-list="fileList" :list-type="typeList" :accept="accept" :multiple="false" :auto-upload="false" :limit="1" :on-exceed="exceed" :show-file-list="false" action>
			<el-button v-if="clickType==='button'" :size="size" :type="type" :plain="plain" :round="round" :circle="circle" :loading="loading" :disabled="disabled" :icon="icon" :autofocus="autofocus" :native-type="nativeType"
			           >{{ text }}
			</el-button>
			<div slot="tip" class="el-upload__tip" v-if="tips!=='' && clickType==='button'">{{tips}}</div>
			<div v-if="clickType==='text'">{{text}}</div>
			<img  v-if="clickType==='img'" :src="src" />
		</el-upload>
		<el-dialog  :width="imageWidth+'px'" top="5vh" :visible.sync="centerDialogVisible" destroy-on-close="true" :title="text" :modal-append-to-body="false" :close-on-click-modal="false" :destroy-on-close="true" :center="true" :before-close="beforeClose">
			<image-cropper style="width: 100%;height: 80vh;"  @update:imageInfo="getNewFile" :FileInfo="dialogPar" :width="width" :height="height"></image-cropper>
		</el-dialog>
	</div>
</template>
<script>
	//获取el-button的所以参数
	
	import ImageCropper from "@/components/common/imageCropper";
	import utils from "@/utils/utils";
	
	export default {
		name: "CropbButton",
		components: {ImageCropper},
		props:{
			//点击类型，可选值button|text|img
			clickType:{
				type: String,
				default: 'button'
			},
			src:{
				type: String,
				default:  require('../../assets/imgs/circle/zu3426.png')
			},
			//按钮文字
			text: {
				type: String,
				default: ''
			},
			//按钮类型
			type: {
				type: String,
				default: 'primary'
			},
			//按钮图标
			icon: {
				type: String,
				default: 'el-icon-upload'
			},
			//按钮大小
			size: {
				type: String,
				default:'mini'
			},
			//是否圆角按钮
			round: {
				type: Boolean,
				default: true
			},
			//是否禁用
			disabled: {
				type: Boolean,
				default: false
			},
			//是否显示loading
			loading: {
				type: Boolean,
				default: false
			},
			//是否朴素按钮
			plain: {
				type: Boolean,
				default: false
			},
			//是否圆形按钮
			circle: {
				type: Boolean,
				default: false
			},
			//是否默认聚焦
			autofocus: {
				type: Boolean,
				default: false
			},
			//按钮的原生type属性：button / submit / reset
			nativeType: {
				type: String,
				default: 'button'
			},
			//上传文件的提示
			tips: {
				type: String,
				default: ''
			},
			// --------------------以上是按钮的属性
			//文件列表的类型：text/picture/picture-card
			typeList: {
				type: String,
				default: 'picture'
			},
			//接受上传的文件类型
			accept: {
				type: String,
				default: 'image/jpg,image/jpeg,image/png'
			},
			//文件大小限制，单位mb
			fileSize:{
				type: Number,//文件大小限制
				default: 5
			},
			//裁切框的大小
			width: {
				type: Number,
				required: true,
				default: () => {
					return 800;
				}
			},
			//裁切框的大小
			height: {
				type: Number,
				required: false,
				default: () => {
					return 300;
				}
			}
			
		},
		computed: {
			user() {
				return this.$store.state.user
			},
			is_login(){
				return this.$store.state.is_login
			},
			
		},
		data() {
			return {
				fileList: [],
				dialogPar:{},
				centerDialogVisible:false,
				imageWidth:800,
			}
		},
		methods: {
			//得到最新裁切后的本地地址和文件
			getNewFile(imageInfo){
				this.$emit("update:fileInfo", imageInfo)
				this.centerDialogVisible = false
				this.fileList = [];
			},
			exceed(){
				this.$message.error('最多只能上传一张图片');
			},
			change(file, fileList){
				const isLt5M = file.size / 1024 / 1024 < this.fileSize;
				if (!isLt5M) {
					this.$message.error('上传文件大小不能超过 '+this.fileSize+'MB!')
					this.fileList = [];
					return false
				}
				if (!/\.(jpg|jpeg|png)$/.test(file.name.toLowerCase())){
					this.$message({
						message: '图片类型要求：' + this.accept,
						type: "error"
					});
					fileList = fileList.filter(item =>item.uid !== file.uid);
					this.fileList = fileList;
					return false
				}
				// 图片选择好后，将图片数据转换成Blob本地地址并赋值对应参数后，最后显示图片裁剪框
				const  filePath = URL.createObjectURL(file.raw);
				const image = new Image()
				// 创建Image的对象的url
				image.src = filePath
				image.onload = () => {
					if (image.width > 1500) {
						this.imageWidth = 1550
					}else if(image.width<800){
						this.imageWidth = this.width+50
					}else {
						this.imageWidth = image.width
					}
				this.centerDialogVisible = true
				this.dialogPar = {
						imgUrl: filePath, // 裁剪组件无法直接访问图片文件信息，故将文件信息转成本地Blob数据地址，让裁剪组件可以直接访问
					fileInfo: file
				}
				}
				
			},
			beforeClose(done){
				this.fileList = [];
				this.centerDialogVisible = false
				done()
			}
		}
	}
</script>


<style>

</style>
