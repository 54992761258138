<template>
    <div class="to-message">
        <div style="padding: 20px">
            <div style="margin-bottom: 20px" v-if="type === 1">
                <div style=" display: flex; justify-content: end">
                    <div style="margin-bottom: 10px;">
                        <img :src="user.headIco" style="width: 36px; height: 36px; border-radius: 50%; margin-right: 12px; vertical-align: middle">
                        <span style="font-size: 20px; font-weight: 600; margin-right: 12px; vertical-align: middle">{{user.nickName}}</span>
                    </div>
                </div>
                <div>
                    <el-input type="textarea" rows="4" style="width: 100%; margin-right: 12px" v-model="sendContent"></el-input>
                    <div style="display: flex; justify-content: end">
                        <div class="hand op" @click="sendCard" style="width: 100px; text-align: center; padding: 4px 0; background-color: #1C7EFF; color: #FFFFFF; border-radius: 10px; margin: 8px 20px 0 0">发送名片</div>
                        <img @click="sendMessageUser" src="../../../assets/imgs/circle/send.png" class="hand op sc" style="width: 24px; height: 24px; margin-top: 6px"/>
                    </div>
                </div>
            </div>
            <div>
                <div v-for="(item, index) in messageList" :key="index" style="margin-bottom: 20px">
                    <div v-if="user.uuid === item.receiveUserInfo.uuid">
                        <div style="display: flex; justify-content: space-between; margin-bottom: 12px">
                            <div style="display: flex">
                                <img :src="item.sendUserInfo.headIco" style="width: 36px; height: 36px; border-radius: 50%; margin-right: 12px">
                                <div style="font-size: 20px; font-weight: 600; margin-top: 2px; margin-right: 15px">{{item.sendUserInfo.nickName}}</div>
                                <div style="color: #999999; font-size: 14px; margin-top: 8px; margin-right: 20px">{{item.time}}</div>
                            </div>
                            <div style="margin-top: 10px">
                                <el-popover
                                    placement="left"
                                    width="800"
                                    trigger="click">
                                    <div>
                                        <div>
                                            <el-input type="textarea" rows="4" style="width: 100%; margin-right: 12px" v-model="sendContent"></el-input>
                                            <div style="display: flex; justify-content: end">
                                                <img @click="sendMessageUser" src="../../../assets/imgs/circle/send.png" class="hand op sc" style="width: 24px; height: 24px; margin-top: 6px"/>
                                            </div>
                                        </div>
                                    </div>
                                    <el-button slot="reference" style="background-color: #FF9800; color: #fff; width: 54px; height: 18px; font-size: 12px; line-height: 2px">回复</el-button>
                                </el-popover>
                            </div>
                        </div>
                        <div style="font-size: 16px; padding: 10px; background-color: #F3F3F3; border-radius: 5px">
                            {{item.content}}
                        </div>
                    </div>
                    <div v-if="user.uuid === item.sendUserInfo.uuid">
                        <div style="display: flex; justify-content: end; margin-bottom: 12px">
                            <div style="margin-top: 20px">
                                <span style="margin-right: 20px">{{item.time}}</span>
                                <span style="font-size: 14px; color: #2970FF">已发送</span>
                            </div>
                        </div>
                        <div style="font-size: 16px; padding: 10px; background-color: #F3F3F3; border-radius: 5px">
                            {{item.content}}
                        </div>
                    </div>
                </div>
                <div style="display: flex; justify-content: end;" v-if="messageList.length % 10 === 0 && moreUser === 1 && messageList.length !== 0">
                    <span @click="getUserMessageList(uuid)" class="hand op" style="color: #1C7EFF; font-size: 16px; margin-bottom: 20px">加载下一页</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ToMessage",
    components: {},
    data() {
        return {
            moreUser: 1,
            pageUser: 1,
            pageSize: 10,
            userInfo: {},
            sendContent: '',
            messageList: [],
        }
    },
    props: {
        uuid: {
            type: String,
            default() {
                return ''
            }
        },

        type: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    watch: {
        uuid: {
            deep: true,
            handler(newData) {
                this.sendContent = '';
                this.pageUser = 1;
                this.moreUser = 1;
                this.messageList = [];
                if (newData) {
                    this.getUserMessageList(newData)
                }
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.getUserMessageList(this.uuid)
    },
    methods: {
        // 发送名片
        sendCard() {
          this.newApi.shareVisitingCard({uuid: this.uuid}).then(res => {
              if (res.isSuccess === 1) {
                this.utils.sus(res.data)
              }
          })
        },

        // 获取私信列表
        getUserMessageList(uuid) {
            if (this.moreUser ==1) {
                this.newApi.getUserMsgList({uuid: uuid, page: this.pageUser, pageSize: this.pageSize}).then(res => {
                    for (const item of res.data) {
                        this.messageList.push(item);
                    }
                    if (res.data.length < 10) {
                        this.moreUser = 0;
                    } else {
                        this.pageUser++
                    }
                })
            }
        },

        // 给一个人发私信
        sendMessageUser() {
            var that = this;
            that.newApi.sendMsg({uuid: this.uuid, content: that.sendContent}).then(res =>{
                if (res.isSuccess == 1) {
                    that.sendContent = '';
                    that.pageUser = 1;
                    that.moreUser = 1;
                    that.messageList = [];
                    that.getUserMessageList(this.uuid);
                    that.utils.sus(res.data);
                }
            })
        },
    }
}
</script>

<style scoped>
    .to-message {

    }

</style>
