<template>
    <div class="sign-view">
        <div class="right-top">
            <div class="hand op" style="font-size: 18px; font-weight: 600;" @click="goBack">
                <i  class="el-icon-arrow-left" style="font-weight: 600">返回</i>
            </div>
            <div style="width: 720px;font-size: 20px; font-weight: 600;">
                <div style="text-align: center">
                    <span>活动详情</span>
                </div>
            </div>
        </div>
        <!-- 背景图 -->
        <div :style="{'background-image': `url(${signForm.cover})`}" class="back-image">
            <div class="back-foot">
                <div class="back-title" v-if="signForm.title">
                    {{signForm.title}}
                </div>

                <div style="display: flex; justify-content: space-between">
                    <div style="display: flex; justify-content: space-between;background-color: #FFFFFF;padding: 6px 10px; border-radius: 5px; width: 480px; font-weight: 600; font-size: 16px; margin-right: 20px">
                        <div v-if="signForm.status == 2">
                            <i class="el-icon-alarm-clock" style="color: #1a1a1a; margin-right: 6px"></i>
                            <span v-if="Date.now() > this.endTime">活动结束</span>
                            <span v-if="Date.now() < this.endTime" style="color: #2970FF">正在报名</span>
                        </div>
                        <div v-if="signForm.status == 3">
                            <i class="el-icon-alarm-clock" style="color: #1a1a1a; margin-right: 6px"></i>
                            <span>活动已取消</span>
                        </div>
                        <div style="font-size: 14px;color: red;" v-if="Date.now() < this.endTime && signForm.status == 2">
                            <span style="color: #999999">
                               剩余截止时间：
                            </span>
                            <span>{{ countdownInfo.days }}</span> 天
                            <span>{{ countdownInfo.hours }}</span> 小时
                            <span>{{ countdownInfo.minutes }}</span> 分钟
                            <span>{{ countdownInfo.seconds }}</span> 秒
                        </div>
                        <div style="font-size: 14px" v-if="Date.now() > this.endTime">
                            <span style="color: #999999">
                               剩余截止时间：
                            </span>
                            <span style="color: #F3425F">
                               0分钟
                            </span>
                        </div>
                    </div>
                    <div style="display: flex;">
                        <div style="display: flex" v-if="Date.now() < this.endTime">
                            <div class="back-btn hand op" @click="showEventInfo" v-if="user.uuid !== signForm.uuid && signForm.status == 2 && signForm.signStatus === -1">
                                <i class="el-icon-circle-plus" style="margin-right: 4px"></i>
                                <span>报名</span>
                            </div>
                            <div class="back-btn" v-if="user.uuid !== signForm.uuid && signForm.status === 2 && signForm.signStatus === 1">
                              <span @click="cancelSign" class="hand op" v-if="Date.now() < this.endTime">取消报名</span>
                              <span v-else>已报名</span>
                            </div>
                            <div class="back-btn hand op"  @click="toPay" v-if="user.uuid !== signForm.uuid && signForm.signStatus === 0">
                              <span>待支付</span>
                            </div>
                            <div class="back-btn" v-if="user.uuid === signForm.uuid && signForm.status == 2 && Date.now() < this.endTime">
                                <span style="color: #2970FF">正在报名</span>
                            </div>
                            <div class="back-btn hand op" @click="showFriends = true" v-if="signForm.status == 2 && Date.now() < this.endTime">
                                <i class="el-icon-circle-plus" style="margin-right: 4px"></i>
                                <span>邀请</span>
                            </div>
                            <div class="back-btn" v-if="user.uuid === signForm.uuid && signForm.status === 2" style="width: 35px;">
                                <el-dropdown trigger="click" @command="dropdownMenu">
                                  <span class="el-dropdown-link">
                                    <i class="el-icon-caret-bottom el-icon--right"></i>
                                  </span>
                                    <el-dropdown-menu slot="dropdown" >
                                        <el-dropdown-item command="3"  class="clearfix" v-if="userList.length == 0 && signForm.status == 2">
                                            编辑
                                        </el-dropdown-item>
                                        <el-dropdown-item command="1"  class="clearfix" v-if="userList.length == 0 && signForm.status == 2">
                                            删除活动
                                        </el-dropdown-item>
                                        <el-dropdown-item command="2"  class="clearfix" v-if="userList.length > 0 && signForm.status == 2">
                                            取消活动
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="width: 834px; min-height: 600px; padding: 20px 30px; background-color: #FFFFFF;position: relative">
          <div class="back-btn hand op" style="position: absolute; font-size: 14px; line-height: 24px;  right: 20px; top: 20px" @click="collectionRoom(signForm.roomId)" v-if="isFollow === true && signForm.roomId">
            <span>关注ROOM</span>
          </div>
            <div class="content">
                <span class="content-title" style="color: blue;">发布者：{{signForm.nickName}}</span>
            </div>
            <div class="content" v-if="signForm.roomName">
              <span class="content-title">来自ROOM：</span>
              <span>{{signForm.roomName}}</span>
            </div>
            <div class="content" v-if="signForm.status == 3">
                <span class="content-title" style="color: red;">取消理由：{{signForm.remarks}}</span>
            </div>

            <div style="display: flex; justify-content: space-between">
                <div class="content">
                    <span class="content-title">活动类型：</span>
                  <span v-if="signForm.type === 1">课程讲座</span>
                  <span v-if="signForm.type === 2">会议报名</span>
                  <span v-if="signForm.type === 3">票务预定</span>
                  <span v-if="signForm.type === 4">其他</span>
                </div>
            </div>

            <div class="content" v-if="signForm.money > 0">
                <span class="content-title">报名费：</span>
                <span style="color: red;"> {{signForm.money}}元</span>
            </div>
            <div class="content">
                <span class="content-title">报名截止日期：</span>
                <span> {{signForm.endTime}}</span>
            </div>
            <div class="content">
                <span class="content-title">活动开始日期：</span>
                <span> {{signForm.activityTime}}</span>
            </div>
            <div class="content">
                <span class="content-title">活动地点：</span>
                <span>{{signForm.location}}</span>
            </div>
<!--            <div class="content">-->
<!--                <span class="content-title">会议名单：</span>-->
<!--                <span>共有19人参加会议</span>-->
<!--            </div>-->
            <div class="content">
                <span class="content-title">活动详情描述：</span>
                <div v-html="signForm.detail"></div>
            </div>

            <div style="border: 1px #999999 dashed; margin: 20px 0"></div>

            <div class="content"  style="margin-bottom: 20px">
                <span class="content-title">附件：</span>
                <div v-if="signForm.enclosure.length > 0">
                    <div class="file-box hand op" @click="downloadFiles(item.url, item.name)" v-for="(item, index) in signForm.enclosure" :key="index">
                        <img src="https://oss.baigongbao.com/img/file.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                        <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                    </div>
                </div>
                <span v-else>暂无</span>
            </div>

            <div class="content" style="margin-bottom: 20px">
                <span class="content-title">参与报名人员：</span>
                <span>共计： {{signForm.signNum}}人</span>
                <div style="display: flex; flex-wrap: wrap;" v-if="user.uuid === signForm.uuid || signForm.signShowRange === 2">
                    <div style="width:220px; height: 60px; padding:10px 20px; margin-top: 10px; margin-right: 10px; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);border-radius: 5px" v-for="(item, index) in userList" :key="index">
                        <User :data="item.user"></User>
                    </div>
                </div>
            </div>
        </div>

        <!--邀请好友-->
        <el-dialog class="event-form" :visible.sync="showFriends" width="740px">
            <div style="display: flex;">
                <div class="dialog-title">邀请好友</div>
            </div>

            <div class="message-divider">
                <el-divider></el-divider>
            </div>

            <div class="message-body">
                <div class="title-form">邀请列表好友<span style="font-size: 13px; color: #999999">（已接受邀请加入的好友不会显示）</span></div>
                <div v-for="(item, index) in friendsList" :key="index">
                    <div class="list-title" @click="item.show = !item.show"><i :class="!item.show ? 'el-icon-caret-right' : 'el-icon-caret-bottom'" style="font-size: 17px; margin: 0 22px"></i>{{item.name}}（{{item.friendArr.length}}）</div>
                    <div style="display: flex; flex-wrap: wrap" v-if="item.show === true">
                        <div style="display: flex; justify-content: center;" class="list-content" v-for="(friend, indexs) in item.friendArr" :key="indexs">
                            <img class="head-ico" style="width: 40px; height: 40px; margin: auto 6px;" :src="friend.friendInfo.headIco">
                            <div style="margin: auto 0; width: 70%">
                                <div style="font-size: 16px; font-weight: 600; margin-bottom: 2px">
                                    {{friend.friendInfo.nickName}}
                                </div>
                                <div style="color: #AAAAAA; font-size: 14px">
                                    乌鲁木齐 | {{friend.friendInfo.major}}
                                </div>
                            </div>
                            <div v-if="friend.select === false" @click="friend.select = true" class="hand op list-btn" style="color: #FF9800; border: 1px #FF9800 solid">选择好友</div>
                            <div v-if="friend.select === true"  @click="friend.select = false" class="hand op list-btn" style="color: #FFFFFF; background-color: #FF9800">取消</div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="padding-bottom: 10px">
                <div @click="joinFriends" class="hand op" style="width: 180px; height: 40px; margin: 20px auto; text-align: center; line-height: 40px; border-radius: 5px; background-color: #2970FF; color: #FFFFFF; font-size: 16px; font-weight: 600">一键邀请好友</div>
            </div>
        </el-dialog>
          <el-dialog class="event-form" :visible.sync="command" width="530px" :close-on-click-modal="false">
              <div style="width: 500px;height: 389px; margin-top: 10px;">
                  <div style="width: 100%;line-height: 55px;text-align: center;font-size: 16px;color: blue;">温馨提示</div>
                  <div style="width: calc(100% - 50px);line-height: 25px;margin: 0 25px;">
                    活动已有人报名，取消活动后数将不可恢复请谨慎取消活动。需要缴纳报名费的活动取消后已报名的用户缴纳的报名费将原路返还回去。
                  </div>
                  <div style="width: calc(100% - 50px);margin: 0 25px;line-height: 50px;">
                      请填写取消理由<span style="color: red;">(必填项)</span>
                  </div>
                  <div style="width: calc(100% - 20px);margin: 0 25px;height: 180px;">
                      <el-input type="textarea" v-model="remarks" :rows="8"></el-input>
                  </div>
                  <div style="width: calc(100% - 50px); height:35px;display: flex;margin: 15px 25px 0 25px;">
                        <div style="width: 40px;height: 25px;margin-left: calc(100% - 40px);">
                            <el-button type="primary" size="medium" @click="cancelActivity">提交</el-button>
                        </div>
                  </div>
              </div>
          </el-dialog>

        <!--报名弹窗-->
        <el-dialog :visible.sync="showEdit" width="52%" :show-close="false" :close-on-click-modal="false" class="event-form" v-if="showEdit == true">
            <div class="top">
                {{signForm.name}}
                <img src="~@/assets/imgs/index/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showEdit = false">
            </div>
            <div style="padding: 20px 40px">
                <SignForm :data="signForm" @closeEvent="closeEvent"></SignForm>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import User from "../User.vue";
    import SignForm from "./SignForm.vue";
    export default {
        name: "signView",
        components: {SignForm, User},
        data() {
            return {
                signForm: {
                    enclosure:[]
                },
                startTime: '',
                endTime: '',
                userList: [],
                showFriends: false,
                friendsList: [],
                orderNo:"",
                countdownInfo:{days : 0,hours : 0,minutes : 0,seconds : 0},
                command:false,
                remarks:'',
                isRefund:0,
                showEdit:false,
                isFollow: false,
            }
        },

        computed: {
            user() {
                return this.$store.state.user
            },
            is_login() {
                return this.$store.state.is_login
            },
        },

        props: {
            activeId: {
                type: Number,
                default() {
                    return 0
                }
            }
        },

        watch: {
            $route: {
                handler() {
                    this.getEventInfo();
                    this.getFriendsList();
                    this.getEventHis();
                    setTimeout(()=>{this.startCountdown();},1000);
                }
            }
        },

        created() {
            var that = this;
            //预支付订单监听
            this.$EventBus.$EventBus.$off("prePayOrder");
            this.$EventBus.$on("prePayOrder", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100) {
                        that.bankPay(data.toPay);
                    }
                });
            });
        },

        mounted() {
            var that = this;
            this.getEventInfo();
            this.getFriendsList();
            this.getEventHis();

            setTimeout(()=>{that.startCountdown();},1000);

            //监听支付回调广播
            this.$EventBus.$EventBus.$off("payMsg");
            this.$EventBus.$on("payMsg", (data) => {
                this.$nextTick(() => {
                    if (data.code == 100 && that.isRefund == 0) {
                        that.utils.sus(data.msg);
                        that.getEventInfo();
                        that.getFriendsList();
                        that.getEventHis();
                    }
                });
            });

            //刷新活动详情
            this.$EventBus.$on("reshEvent", (data) => {
                this.$nextTick(() => {
                    that.getEventInfo();
                });
            });

        },

        methods: {
            // 关注room
            collectionRoom(id) {
              if (!this.utils.toAuth(1)) {
                return false;
              }
              this.newApi.collectRoom({roomId: id}).then(res =>{
                this.utils.sus(res.data);
                this.getEventInfo()
              })
            },

            // 获取room信息
            getRoomInfo(id) {
              this.newApi.getRoomInfo({roomId: id}).then(res =>{
                if (res.data.myLv === 0) {
                  this.isFollow = true
                } else {
                  this.isFollow = false
                }
              })
            },

            // 取消报名
            cancelSign() {
              var that = this;
              let msg = "确定取消报名?";
              if(that.signForm.money > 0){
                    msg = "确定取消报名并退款"+that.signForm.money+"元?";
              }
              that.utils.confirm(msg,function (){
                that.openLoading("取消中...");
                that.newApi.cancelEnroll({id: that.signForm.id}).then(res => {
                  if (res.isSuccess === 1) {
                    that.utils.sus(res.data)
                    that.getEventHis()
                    that.getEventInfo()
                  }
                })
              })
            },
            // 返回上一页
            goBack() {
                //room
              if (this.$route.query.id) {
                this.$router.go(-1)
              } else {
                  //朋友圈动态
                if (this.$route.query.activeId) {
                    this.$router.go(-1)
                } else {
                    //报名活动
                  this.$parent.closeEvent()
                }
              }
            },

            //倒计时
            startCountdown(){
                var that = this;
                if(Date.now() < this.endTime){
                    that.countdownInfo = that.utils.countdown(that.signForm.endTime);
                    // 使用 setTimeout 让 updateCountdown 每秒执行一次
                    setTimeout(() => {
                        that.countdownInfo = that.utils.countdown(that.signForm.endTime);
                        // 再次调用 startCountdown，以便每秒钟都执行 updateCountdown
                        this.startCountdown();
                    }, 1000);
                }
            },

            //银行卡确认支付
            bankPay(data){
                var that = this;
                //判断是对公还是公绑私
                if(data.activeName != 'corporate'){
                    var params = {};
                    params.orderNo = data.orderNo;
                    params.code = data.code;
                    params.payPwd = data.payPwd;

                    that.newApi.settleAccountPennyDetermine(params).then(res=>{
                        if(res.isSuccess == 1) {
                            that.getEventHis();
                            that.getEventInfo();
                        }
                    })
                }

            },

            // 获取可邀请好友列表
            getFriendsList() {
                this.newApi.canInviteUserlist({enrollId: this.activeId}).then(res => {
                    for (const item of res.data) {
                        item.show = false;
                        for (const friend of item.friendArr) {
                            friend.select = false
                        }
                    }
                    this.friendsList = res.data
                })
            },

            // 一键邀请好友
            joinFriends() {
                var params = {};
                params.enrollId = this.signForm.id;
                params.title = this.signForm.title;
                params.userList = [];
                if(this.friendsList.length==0){
                  this.showFriends = false;
                  return this.$message.error("暂无可邀请的好友");
                }
                for (const item of this.friendsList) {
                    for (const i of item.friendArr) {
                        if (i.select === true) {
                            params.userList.push(i.friendInfo.uuid)
                        }
                    }
                }
                if (params.userList.length === 0) {
                  this.utils.msg('请至少选择一个好友')
                  return
                }
                params.userList = JSON.stringify(params.userList);
                this.newApi.inviteUsersToEnroll(params).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.showFriends = false;
                        this.getFriendsList()
                    }
                })
            },

            // 获取事件报名记录
            getEventHis() {
                this.newApi.getEnrollRecords({enrollId: this.activeId}).then(res =>{
                    this.userList = res.data
                })
            },

            // 点击展示报名信息
            showEventInfo() {
              var that = this;
              if (!this.utils.toAuth(1)) {
                return false
              }
                if (this.signForm.templateId) {
                  this.$router.push({name: 'View', query: {
                      id: this.signForm.templateId,
                      enrollId: this.signForm.id,
                      status: 1
                    }})
                } else {
                    let msg = "确定报名?";
                    if(that.signForm.money > 0){
                        msg = "确定报名并支付"+that.signForm.money+"元?";
                    }
                  that.utils.confirm(msg,function (){
                      var params = {};
                      params.enrollId = that.signForm.id;
                      params.status = 1;
                      params.datas = JSON.stringify({});
                      that.newApi.toEnroll(params).then(res => {
                          if (res.isSuccess === 1) {
                              if(!res.data.needPay){
                                  that.$message.success("报名成功");
                                  that.getEventHis();
                                  that.getEventInfo();
                              }else{
                                  that.$set(that.signForm,"signStatus",0);
                                  that.$set(that.signForm,"payOrderNo",res.data.orderNo);
                                  that.$set(that.signForm,"accountExist",res.data.accountExist);
                                  that.toPay();
                              }
                          }
                      })
                  })

                }
            },

            //立即下单
            toPay(){
                //<AaDaPayCode type="3" payType="7" :payAmt="signForm.money+''" :collectionUuid="signForm.uuid" :orderNo="signForm.payOrderNo" @change="changeOrder($event)" ref="child"></AaDaPayCode>
                var that = this;
                let params = that.$store.state.payParams;
                params.type = 3;
                params.payAmt = that.signForm.money;
                params.payType = 7;
                params.collectionUuid = that.signForm.uuid;
                params.orderNo = that.signForm.payOrderNo;
                that.sign = that.utils.random_string(6);
                that.newApi.addAndSavePrePayOrder({type:1,payParams:JSON.stringify(params),sign:that.sign}).then(() => {
                    that.b('/newAdaPay/?sign='+that.sign);
                })
            },

            // 获取活动详情
            getEventInfo() {
                this.newApi.getEnrollInfo({id: this.activeId}).then(res =>{
                    this.signForm = res.data;
                    this.endTime = Date.parse(res.data.endTime);
                    if (this.signForm.roomId) {
                      this.getRoomInfo(this.signForm.roomId)
                    }
                })
            },

            // 下载附件
            downloadFiles(url, name) {
                this.utils.download(url, name)
            },

            //下拉删除、取消
            dropdownMenu(command){
                var that = this;
                if(command == 1) {
                    if(that.userList.length > 0){
                        that.utils.err("活动已有人报名，无法删除");
                    }
                    that.utils.confirm("确定删除活动?",function (){
                        that.openLoading("删除中...");
                        that.newApi.deleteActivity({id: that.signForm.id}).then(res => {
                            if (res.isSuccess === 1) {
                                that.utils.sus("活动删除成功");
                                //发送广播
                                that.$EventBus.$emit("pathJump", {isResh:1});
                                that.goBack();
                            }
                        })
                    })
                }else if(command == 2){
                    that.command = true;
                }else if(command == 3){
                    that.showEdit = true;
                }
            },

            //取消活动
            cancelActivity(){
                var that = this;

                if(that.remarks == ''){
                    that.command = true;
                    that.utils.err("请填写取消理由");
                    return false;
                }
                that.command = false;
                that.isRefund = 1;
                that.openLoading("取消中...");
                that.newApi.cancelActivity({id: that.signForm.id,remarks:that.remarks}).then(res => {
                    if (res.isSuccess === 1) {
                        let msg = res.data;
                        if(that.signForm.isCharge == 1){
                            msg = "活动取消成功人员退款行进中，请稍后刷新页面查看！";
                        }
                        that.utils.alert(msg,function (){
                            window.location.reload();
                        })
                    }
                })
            },

            // 关闭编辑活动弹窗
            closeEvent() {
                var that = this;
                that.showEdit = false;
                that.getEventHis()
                that.getEventInfo()
            },
        }
    }
</script>

<style scoped>
    .sign-view .right-top {
        width: 854px;
        background-color: #fff;
        display: flex;
        padding: 8px 20px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }

    .sign-view .back-image {
        width:100%;
        height:420px;
        background-repeat:no-repeat;
        background-size:100%;
        background-position:center center;
    }

    .sign-view .back-foot {
        width: 834px;
        height: 100px;
        padding: 20px 30px;
        position: absolute;
        top: 401px;
        background: rgba(0,0,0,0.39);
    }

    .sign-view .back-title {
        font-size: 26px;
        font-weight: 600;
        color: #FFFFFF;
        margin-bottom: 20px;
    }

    .sign-view .dialog-title {
        height: 30px;
        padding: 10px 0;
        font-weight: 600;
        margin: 0 auto;
        font-size: 20px;
    }

    .sign-view .message-body {
        min-height: 200px;
        padding: 20px;
    }

    .sign-view .back-btn {
        width: 108px;
        height: 24px;
        margin-right: 10px;
        border-radius: 5px;
        background-color: #E4E6EB;
        padding: 4px 0;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }

    .sign-view .send-btn {
        width: 48px;
        height: 20px;
        margin-right: 10px;
        border-radius: 5px;
        background-color: #E4E6EB;
        padding: 6px 0;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }

    .sign-view .content {
        font-size: 16px;
    }

    .sign-view .content-title {
        color: #999999;
        line-height: 36px;
    }

    .sign-view .title{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        line-height: 26px;
        font-size: 15px;
    }

    .sign-view .title::-webkit-scrollbar {
        display: none;
    }

    .sign-view .file-box {
        height: 32px;
        padding: 8px 10px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }

    .sign-view .event-form .top {
        text-align: center;
        position: relative;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }

    .message-divider .el-divider--horizontal {
        margin: 0;
    }

    .content>>>img {
         width: 100%;
     }

    .sign-view  .title-form {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .sign-view  .list-title {
        width: 232px;
        height: 36px;
        font-size: 15px;
        line-height: 36px;
        font-weight: 600;
        margin-bottom: 10px;
        background-color: #F5F5F5;
        border: 1px #D4D6D9 solid;
    }

    .sign-view  .list-content {
        width: 308px;
        height: 64px;
        border: 1px #CED0D4 solid;
        margin: 0 10px 10px 0;
    }

    .sign-view  .list-btn {
        width: 78px;
        height: 24px;
        line-height: 24px;
        margin: auto 10px;
        border-radius: 5px;
        text-align: center;
    }

    /deep/ .event-form .el-dialog__header {
        padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
        padding: 0;
    }
</style>
