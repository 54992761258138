import { render, staticRenderFns } from "./SpaceHead.vue?vue&type=template&id=0a6eb004&scoped=true&"
import script from "./SpaceHead.vue?vue&type=script&lang=js&"
export * from "./SpaceHead.vue?vue&type=script&lang=js&"
import style0 from "./SpaceHead.vue?vue&type=style&index=0&id=0a6eb004&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a6eb004",
  null
  
)

export default component.exports