<template>
    <div class="circle-notice">
        <div v-if="applyData.length !== 0" style="width: 100%; overflow: hidden; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-bottom: 10px">
            <div style="font-size: 18px; font-weight: 600; margin: 20px 0 18px 30px">好友申请</div>
            <div>
                <el-carousel height="120px" arrow="always" indicator-position="none" :autoplay="false" style="margin-bottom: 20px;">
                    <el-carousel-item v-for="(items, indexs) in applyData" :key="indexs" style="padding: 0 43px;">
                        <div style="display: flex; margin-top: 4px">
                            <div v-for="(item, index) in items" :key="index" style="width: 246px; min-height: 84px; background-color: #F3F3F3; margin-left: 10px; border-radius: 5px; padding: 12px;">
                                <div :class="item.status === 0 ? 'no-look' : 'is-look'"></div>
                                <div style="display: flex;">
                                    <img :src="item.friendInfo.headIco" style="width: 72px; height: 72px; border-radius: 50%; margin-right: 10px">
                                    <div style="width: 156px">
                                        <div style="font-size: 15px; margin-bottom: 6px; display: -webkit-box;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                                            <span style="color: #444444; font-weight: 600">{{item.friendInfo.nickName}}</span>
                                            <span style="color: #666666">向你发送了加好友申请。</span>
                                        </div>
                                        <div style="font-size: 13px; color: #2970FF; margin-bottom: 8px">{{item.time}}</div>
                                        <div>
                                            <el-button style="width: 73px;height: 29px;background: #2970FF;border-radius: 5px 5px 5px 5px; color: #FFFFFF; line-height: 8px" @click="showGroups(item.id)">确认</el-button>
                                            <el-button style="width: 73px;height: 29px;background: #D8DADF;border-radius: 5px 5px 5px 5px; line-height: 8px" @click="loseFriendApply(item.id)">拒绝</el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <el-dialog :visible.sync="isGroup" width="400px">
                    <div style="padding: 10px; font-size: 20px; font-weight: 600">
                        选择好友分组
                    </div>
                    <div v-for="(item, index) in groups" :key="index" style="font-size: 20px; margin: 20px">
                        <el-radio v-model="groupId" :label="item.id">{{item.name}}</el-radio>
                    </div>
                    <div style="display: flex; justify-content: end; margin-right: 20px">
                        <el-button class="cancel-btn" @click="cancelFriendApply">取消</el-button>
                        <el-button class="save-btn" @click="consentFriendApply">确定</el-button>
                    </div>
                </el-dialog>
            </div>
        </div>

        <div style="width: 100%; overflow: hidden; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-bottom: 10px" v-if="templateList.length > 0">
            <div style="font-size: 18px; font-weight: 600; margin: 20px 0 18px 30px">好友模板分享</div>
            <div style="padding: 0 20px 20px 30px; display: flex; flex-wrap: wrap">
                <div class="card-content" v-for="(item, index) in templateList" :key="index" style="width: 246px; min-height: 84px; background-color: #F3F3F3; margin-left: 10px; border-radius: 5px; padding: 12px;">
                    <div style="display: flex;">
                        <img :src="item.user.headIco" style="width: 72px; height: 72px; border-radius: 50%; margin-right: 10px">
                        <div style="width: 156px">
                            <div style="font-size: 15px; margin-bottom: 6px; display: -webkit-box;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                                <span style="color: #444444; font-weight: 600">{{item.user.nickName}}</span>
                                <span style="color: #666666">向你分享了模板。</span>
                            </div>
                            <div style="font-size: 13px; color: #2970FF; margin-bottom: 8px">{{item.creationTime}}</div>
                            <div>
                                <el-button style="width: 73px;height: 29px;background: #2970FF;border-radius: 5px 5px 5px 5px; color: #FFFFFF; line-height: 8px" @click="disposeTemplate(item.id, 1)">确认</el-button>
                                <el-button style="width: 73px;height: 29px;background: #D8DADF;border-radius: 5px 5px 5px 5px; line-height: 8px" @click="disposeTemplate(item.id, 0)">拒绝</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="width: 100%; overflow: hidden; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-bottom: 10px" v-if="applyTemplateList.length > 0">
            <div style="font-size: 18px; font-weight: 600; margin: 20px 0 18px 30px">好友模板申请</div>
            <div style="padding: 0 20px 20px 30px; display: flex; flex-wrap: wrap">
                <div class="card-content" v-for="(item, index) in applyTemplateList" :key="index" style="width: 246px; min-height: 84px; background-color: #F3F3F3; margin-left: 10px; border-radius: 5px; padding: 12px;">
                    <div style="display: flex;">
                        <img :src="item.user.headIco" style="width: 72px; height: 72px; border-radius: 50%; margin-right: 10px">
                        <div style="width: 156px">
                            <div style="font-size: 15px; margin-bottom: 6px; display: -webkit-box;overflow: hidden;text-overflow: ellipsis;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                                <span style="color: #444444; font-weight: 600">{{item.user.nickName}}</span>
                                <span style="color: #666666">向你申请模板。</span>
                            </div>
                            <div style="font-size: 13px; color: #2970FF; margin-bottom: 8px">{{item.creationTime}}</div>
                            <div>
                                <el-button style="width: 73px;height: 29px;background: #2970FF;border-radius: 5px 5px 5px 5px; color: #FFFFFF; line-height: 8px" @click="disposeTemplate(item.id, 1)">确认</el-button>
                                <el-button style="width: 73px;height: 29px;background: #D8DADF;border-radius: 5px 5px 5px 5px; line-height: 8px" @click="disposeTemplate(item.id, 0)">拒绝</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="width: 100%; display: flex; justify-content: space-between" v-if="pageType === 'home'">
            <div class="notice-body">
                <div class="notice-content">
                    <div class="notice-title">
                        <div>@我的动态</div>
                        <div style="display: flex">
                            <div class="hand op" style="color: #999999; font-size: 14px; margin-right: 20px" @click="allReading(8)">一键已读</div>
                            <div v-if="toAtList.length > 5" class="hand op" style="color: #999999; font-size: 14px" @click="showMoreList(toAtList)">查看更多</div>
                        </div>
                    </div>
                    <div class="notice-message" v-if="toAtList.length === 0">
                        <NullBox data="此通知消息"></NullBox>
                    </div>
                    <div class="notice-message" v-else>
                        <div class="notice-info" v-for="(item, index) in toAtList" :key="index">
                            <div style="display: flex; margin-right: 20px">
                                <img :src="item.user.headIco" style="width: 54px; height: 54px; border-radius: 50%; margin-right: 10px">
                                <div style="display: flex">
                                    <div style="margin-top: 6px">
                                        <span style="font-size: 17px; font-weight: 600; margin-right: 10px; vertical-align: top">{{item.user.nickName}}</span>
                                        <img v-if="item.user.v" src="../../assets/imgs/circle/vip.png" style="width: 18px; height: 18px; vertical-align: middle;"/>
                                        <div style="color: #999999; margin-top: 6px; font-size: 12px">{{item.updateTime}}</div>
                                    </div>
                                </div>
                            </div>
                            <div style="vertical-align: middle; margin-top: 16px; font-size: 16px">
                                <span>{{item.msg}}，</span>
                                <span class="hand op" style="color: #2970FF;" @click="toDetail(item)">点击查看详情</span>
                                <span style="font-size: 14px; color: #FFA500; font-weight: 600" v-if="item.isRead == 1">（未读）</span>
                                <span style="font-size: 14px; color: #999999;" v-else>（已读）</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="notice-content">
                    <div class="notice-title">
                        <div>好友通知</div>
                        <div style="display: flex">
                            <div class="hand op" style="color: #999999; font-size: 14px; margin-right: 20px" @click="friendReading">一键已读</div>
                            <div v-if="friendList.length > 5" class="hand op" style="color: #999999; font-size: 14px" @click="showMoreList(friendList)">查看更多</div>
                        </div>
                    </div>
                    <div class="notice-message" v-if="friendList.length === 0">
                        <NullBox data="此通知消息"></NullBox>
                    </div>
                    <div class="notice-message" v-else>
                        <div class="notice-info" v-for="(item, index) in friendList" :key="index">
                            <div style="display: flex; margin-right: 20px">
                                <img :src="item.user.headIco" style="width: 54px; height: 54px; border-radius: 50%; margin-right: 10px">
                                <div style="display: flex">
                                    <div style="margin-top: 6px">
                                        <span style="font-size: 17px; font-weight: 600; margin-right: 10px; vertical-align: top">{{item.user.nickName}}</span>
                                        <img v-if="item.user.v" src="../../assets/imgs/circle/vip.png" style="width: 18px; height: 18px; vertical-align: middle;"/>
                                        <div style="color: #999999; margin-top: 6px; font-size: 12px">{{item.updateTime}}</div>
                                    </div>
                                </div>
                            </div>
                            <div style="vertical-align: middle; margin-top: 16px; font-size: 16px">
                                <span>{{item.msg}}</span>
                                <span style="font-size: 14px; color: #FFA500;font-weight: 600" v-if="item.isRead == 1">（未读）</span>
                                <span style="font-size: 14px; color: #999999;" v-else>（已读）</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="notice-content">
                    <div class="notice-title">
                        <div>被转发的动态</div>
                        <div style="display: flex">
                            <div class="hand op" style="color: #999999; font-size: 14px; margin-right: 20px" @click="allReading(5)">一键已读</div>
                            <div v-if="transpondList.length > 5" class="hand op" style="color: #999999; font-size: 14px" @click="showMoreList(transpondList)">查看更多</div>
                        </div>
                    </div>
                    <div class="notice-message" v-if="transpondList.length === 0">
                        <NullBox data="此通知消息"></NullBox>
                    </div>
                    <div class="notice-message" v-else>
                        <div class="notice-info" v-for="(item, index) in transpondList" :key="index">
                            <div style="display: flex; margin-right: 20px">
                                <img :src="item.user.headIco" style="width: 54px; height: 54px; border-radius: 50%; margin-right: 10px">
                                <div style="display: flex">
                                    <div style="margin-top: 6px">
                                        <span style="font-size: 17px; font-weight: 600; margin-right: 10px; vertical-align: top">{{item.user.nickName}}</span>
                                        <img v-if="item.user.v" src="../../assets/imgs/circle/vip.png" style="width: 18px; height: 18px; vertical-align: middle;"/>
                                        <div style="color: #999999; margin-top: 6px; font-size: 12px">{{item.updateTime}}</div>
                                    </div>
                                </div>
                            </div>
                            <div style="vertical-align: middle; margin-top: 16px; font-size: 16px">
                                <span>{{item.msg}}，</span>
                                <span class="hand op" style="color: #2970FF" @click="toDetail(item)">点击查看详情</span>
                                <span style="font-size: 14px; color: #FFA500;font-weight: 600" v-if="item.isRead == 1">（未读）</span>
                                <span style="font-size: 14px; color: #999999;" v-else>（已读）</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="notice-content">
                    <div class="notice-title">
                        <div>收到的评论</div>
                        <div style="display: flex">
                            <div class="hand op" style="color: #999999; font-size: 14px; margin-right: 20px" @click="allReading(6)">一键已读</div>
                            <div v-if="commentList.length > 5" class="hand op" style="color: #999999; font-size: 14px" @click="showMoreList(commentList)">查看更多</div>
                        </div>
                    </div>
                    <div class="notice-message" v-if="commentList.length === 0">
                        <NullBox data="此通知消息"></NullBox>
                    </div>
                    <div class="notice-message" v-else>
                        <div class="notice-info" v-for="(item, index) in commentList" :key="index">
                            <div style="display: flex; margin-right: 20px">
                                <img :src="item.user.headIco" style="width: 54px; height: 54px; border-radius: 50%; margin-right: 10px">
                                <div style="display: flex">
                                    <div style="margin-top: 6px">
                                        <span style="font-size: 17px; font-weight: 600; margin-right: 10px; vertical-align: top">{{item.user.nickName}}</span>
                                        <img v-if="item.user.v" src="../../assets/imgs/circle/vip.png" style="width: 18px; height: 18px; vertical-align: middle;"/>
                                        <div style="color: #999999; margin-top: 6px; font-size: 12px">{{item.updateTime}}</div>
                                    </div>
                                </div>
                            </div>
                            <div style="vertical-align: middle; margin-top: 16px; font-size: 16px">
                                <span>{{item.msg}}，</span>
                                <span class="hand op" style="color: #2970FF" @click="toDetail(item)">点击查看详情</span>
                                <span style="font-size: 14px; color: #FFA500;font-weight: 600" v-if="item.isRead == 1">（未读）</span>
                                <span style="font-size: 14px; color: #999999;" v-else>（已读）</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="notice-content">
                    <div class="notice-title">
                        <div>活动报名动态</div>
                        <div style="display: flex">
                            <div class="hand op" style="color: #999999; font-size: 14px; margin-right: 20px" @click="allReading(18)">一键已读</div>
                            <div v-if="activityList.length > 5" class="hand op" style="color: #999999; font-size: 14px" @click="showMoreList(activityList)">查看更多</div>
                        </div>
                    </div>
                    <div class="notice-message" v-if="activityList.length === 0">
                        <NullBox data="此通知消息"></NullBox>
                    </div>
                    <div class="notice-message" v-else>
                        <div class="notice-info" v-for="(item, index) in activityList" :key="index">
<!--                            <div style="display: flex; margin-right: 20px">-->
<!--                                <img :src="item.user.headIco" style="width: 54px; height: 54px; border-radius: 50%; margin-right: 10px">-->
<!--                                <div style="display: flex">-->
<!--                                    <div style="margin-top: 6px">-->
<!--                                        <span style="font-size: 17px; font-weight: 600; margin-right: 10px; vertical-align: top">{{item.user.nickName}}</span>-->
<!--                                        <img v-if="item.user.v" src="../../assets/imgs/circle/vip.png" style="width: 18px; height: 18px; vertical-align: middle;"/>-->
<!--                                        <div style="color: #999999; margin-top: 6px; font-size: 12px">{{item.updateTime}}</div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </div>-->
                            <div style="vertical-align: middle; margin-top: 16px; font-size: 16px">
                                <span>{{item.msg}}，</span>
                                <span class="hand op" style="color: #2970FF" @click="toDetail(item)">点击查看详情</span>
                                <span style="font-size: 14px; color: #FFA500;font-weight: 600" v-if="item.isRead == 1">（未读）</span>
                                <span style="font-size: 14px; color: #999999;" v-else>（已读）</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="notice-body">
                <div class="notice-content">
                    <div class="notice-title">
                        <div>收到的点赞</div>
                        <div style="display: flex">
                            <div class="hand op" style="color: #999999; font-size: 14px; margin-right: 20px" @click="allReading(4)">一键已读</div>
                            <div v-if="likeList.length > 5" class="hand op" style="color: #999999; font-size: 14px" @click="showMoreList(likeList)">查看更多</div>
                        </div>
                    </div>
                    <div class="notice-message" v-if="likeList.length === 0">
                        <NullBox data="此通知消息"></NullBox>
                    </div>
                    <div class="notice-message" v-else>
                        <div class="notice-info" v-for="(item, index) in likeList" :key="index">
                            <div style="display: flex; margin-right: 20px">
                                <img :src="item.user.headIco" style="width: 54px; height: 54px; border-radius: 50%; margin-right: 10px">
                                <div style="display: flex">
                                    <div style="margin-top: 6px">
                                        <span style="font-size: 17px; font-weight: 600; margin-right: 10px; vertical-align: top">{{item.user.nickName}}</span>
                                        <img v-if="item.user.v" src="../../assets/imgs/circle/vip.png" style="width: 18px; height: 18px; vertical-align: middle;"/>
                                        <div style="color: #999999; margin-top: 6px; font-size: 12px">{{item.updateTime}}</div>
                                    </div>
                                </div>
                            </div>
                            <div style="vertical-align: middle; margin-top: 16px; font-size: 16px">
                                <span>{{item.msg}}，</span>
                                <span class="hand op" style="color: #2970FF" @click="toDetail(item)">点击查看详情</span>
                                <span style="font-size: 14px; color: #FFA500;font-weight: 600" v-if="item.isRead == 1">（未读）</span>
                                <span style="font-size: 14px; color: #999999;" v-else>（已读）</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="notice-content">
                    <div class="notice-title">
                        <div>收到的关注</div>
                        <div style="display: flex">
                            <div v-if="collectList.length > 5" class="hand op" style="color: #999999; font-size: 14px" @click="showMoreList(collectList)">查看更多</div>
                        </div>
                    </div>
                    <div class="notice-message" v-if="collectList.length === 0">
                        <NullBox data="此通知消息"></NullBox>
                    </div>
                    <div class="notice-message" v-else>
                        <div class="notice-info" v-for="(item, index) in collectList" :key="index">
                            <div style="display: flex; margin-right: 20px">
                                <img :src="item.user.headIco" style="width: 54px; height: 54px; border-radius: 50%; margin-right: 10px">
                                <div style="display: flex">
                                    <div style="margin-top: 6px">
                                        <span style="font-size: 17px; font-weight: 600; margin-right: 10px; vertical-align: top">{{item.user.nickName}}</span>
                                        <img v-if="item.user.v" src="../../assets/imgs/circle/vip.png" style="width: 18px; height: 18px; vertical-align: middle;"/>
                                        <div style="color: #999999; margin-top: 6px; font-size: 12px">{{item.updateTime}}</div>
                                    </div>
                                </div>
                            </div>
                            <div style="vertical-align: middle; margin-top: 16px; font-size: 16px">
                                <span>{{item.msg}}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="notice-content">
                    <div class="notice-title">
                        <div>收到的收藏</div>
                        <div style="display: flex">
                            <div class="hand op" style="color: #999999; font-size: 14px; margin-right: 20px" @click="allReading(10)">一键已读</div>
                            <div v-if="needList.length > 5" class="hand op" style="color: #999999; font-size: 14px" @click="showMoreList(needList)">查看更多</div>
                        </div>
                    </div>
                    <div class="notice-message" v-if="needList.length === 0">
                        <NullBox data="此通知消息"></NullBox>
                    </div>
                    <div class="notice-message" v-else>
                        <div class="notice-info" v-for="(item, index) in needList" :key="index">
                            <div style="display: flex; margin-right: 20px">
                                <img :src="item.user.headIco" style="width: 54px; height: 54px; border-radius: 50%; margin-right: 10px">
                                <div style="display: flex">
                                    <div style="margin-top: 6px">
                                        <span style="font-size: 17px; font-weight: 600; margin-right: 10px; vertical-align: top">{{item.user.nickName}}</span>
                                        <img v-if="item.user.v" src="../../assets/imgs/circle/vip.png" style="width: 18px; height: 18px; vertical-align: middle;"/>
                                        <div style="color: #999999; margin-top: 6px; font-size: 12px">{{item.updateTime}}</div>
                                    </div>
                                </div>
                            </div>
                            <div style="vertical-align: middle; margin-top: 16px; font-size: 16px">
                                <span>{{item.msg}}，</span>
                                <span class="hand op" style="color: #2970FF" @click="toDetail(item)">点击查看详情</span>
                                <span style="font-size: 14px; color: #FFA500;font-weight: 600" v-if="item.isRead == 1">（未读）</span>
                                <span style="font-size: 14px; color: #999999;" v-else>（已读）</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="notice-content">
                    <div class="notice-title">
                        <div>环境页内容更新动态</div>
                        <div style="display: flex">
                            <div class="hand op" style="color: #999999; font-size: 14px; margin-right: 20px" @click="allReading()">一键已读</div>
                            <div v-if="environmentList.length > 5" class="hand op" style="color: #999999; font-size: 14px" @click="showMoreList(environmentList)">查看更多</div>
                        </div>
                    </div>
                    <div class="notice-message" v-if="environmentList.length === 0">
                        <NullBox data="此通知消息"></NullBox>
                    </div>
                    <div class="notice-message" v-else>
                        <div class="notice-info" v-for="(item, index) in environmentList" :key="index">
                            <div style="display: flex; margin-right: 20px">
                                <div style="width: 44px; height: 44px; background-color: #707070; border-radius: 5px; margin-right: 10px; margin-top: 4px"></div>
                                <div class="title-update">
                                    新疆新土地城
                                    乡规划设计院
                                </div>
                            </div>
                            <div style="vertical-align: middle; margin-top: 16px; font-size: 16px">
                                <span>您关注的环境页更新了新的内容，</span>
                                <span class="hand op" style="color: #2970FF" @click="toDetail(item)">点击查看详情</span>
                                <span style="font-size: 14px; color: #FFA500;font-weight: 600" v-if="item.isRead == 1">（未读）</span>
                                <span style="font-size: 14px; color: #999999;" v-else>（已读）</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="show-more">
                <el-dialog :visible.sync="showMore" :title="title">
                    <div class="notice-message inner-container">
                        <div class="notice-info" v-for="(item, index) in moreList" :key="index">
                            <div style="display: flex; margin-right: 20px">
                                <img :src="item.user.headIco" style="width: 54px; height: 54px; border-radius: 50%; margin-right: 10px">
                                <div style="display: flex">
                                    <div style="margin-top: 6px">
                                        <span style="font-size: 17px; font-weight: 600; margin-right: 10px; vertical-align: top">{{item.user.nickName}}</span>
                                        <img v-if="item.user.v" src="../../assets/imgs/circle/vip.png" style="width: 18px; height: 18px; vertical-align: middle;"/>
                                        <div style="color: #999999; margin-top: 6px; font-size: 12px">{{item.updateTime}}</div>
                                    </div>
                                </div>
                            </div>
                            <div style="vertical-align: middle; margin-top: 16px; font-size: 16px">
                                <span>{{item.msg}}，</span>
                                <span v-if="item.type !== 7" class="hand op" style="color: #2970FF" @click="toDetail(item)">点击查看详情</span>
                                <span style="font-size: 14px; color: #FFA500;font-weight: 600" v-if="item.isRead == 1">（未读）</span>
                                <span style="font-size: 14px; color: #999999;" v-else>（已读）</span>
                            </div>
                        </div>
                    </div>
                </el-dialog>
            </div>

        </div>

        <div class="activity-details" v-if="pageType === 'details'">
            <div style="width: 100%; overflow: hidden; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
                <div style="display: flex; width: 1252px; height: 58px; background-color: #F7F7F7;">
                    <div class="hand op sc" style="font-weight: 500; font-size: 18px; padding: 17px 0; margin-left: 52px" @click="onNoticeHome"><i class="el-icon-arrow-left" style="font-weight: 600"></i>返回</div>
                </div>
                <div class="Dynamic">
                    <Dynamic :data="dynamicData"></Dynamic>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Dynamic from "./components/Dynamic";
    import NullBox from "./components/NullBox";
    export default {
        name: "notice",
        components: {NullBox, Dynamic},
        data() {
            return {
                indexTags: 'all',
                testData: [1,2,3,4,5,6,7],
                pageType: 'home',
                applyData: [],
                sendContent: '',
                checked: '',
                testCollection: false,

                // 处理好友
                groupId: 0,
                friendId: '',
                isGroup: false,
                groups: [],

                // 所有类型的消息列表
                toAtList: [],
                commentList: [],
                environmentList: [],
                likeList: [],
                collectList: [],
                transpondList: [],
                expertList: [],
                friendList: [],
                needList: [],
                systemList: [],
                activityList: [],

                showMore: false,
                moreList: [],
                title: '',

                dynamicData: {},

                templateList: [],

                applyTemplateList: [],

            }
        },

        mounted() {
            this.getAddFriendMessage();
            this.getFriendGroups();
            this.getNotices();
            // this.getExpertList();
            this.getNoticeTemplate()

        },

        methods: {
            // 处理模板分享信息
            disposeTemplate(id, status) {
                this.newApi.handleDiyTemplate({id: id, status: status}).then(res => {
                    if (res.isSuccess === 1) {
                        this.getNoticeTemplate();
                        this.utils.sus(res.data)
                    }
                })
            },

            // 一键已读
            allReading(type) {
                this.newApi.isReadType({type: type}).then(res => {
                    if (res.isSuccess == 1) {
                        this.getNotices();
                        this.utils.sus(res.data);
						this.newApi.getNoticeNoReadNum().then((Notice)=>{
							this.$addStorageEvent('noticeCount', Notice.data);
              this.$parent.updateCount()
						});
                    }
                })
            },

            friendReading() {
              let typeList = [2,3]
              for (const type of typeList) {
                this.newApi.isReadType({type: type}).then(res => {
                  if (res.isSuccess == 1) {
                    this.getNotices();
                    this.utils.sus(res.data);
                    this.newApi.getNoticeNoReadNum().then((Notice)=>{
                      this.$addStorageEvent('noticeCount', Notice.data);
                      this.$parent.updateCount()
                    });
                  }
                })
              }
            },

            // 查看专家订单
            toExpert(orderNO) {
                this.utils.a('/expert/order/' + orderNO)
            },

            // 展示更多
            showMoreList(list) {
                this.showMore = true;
                switch (list[0].type)
                {
                    case 8:
                        this.title = '@我的动态';
                        this.moreList = list;
                        break;
                    case 2:
                        this.title = '好友申请通知';
                        this.moreList = list;
                        break;
                    case 4:
                        this.title = '收到的点赞';
                        this.moreList = list;
                        break;
                    case 5:
                        this.title = '被转发的动态';
                        this.moreList = list;
                        break;
                    case 6:
                        this.title = '收到的转发';
                        this.moreList = list;
                        break;
                    case 7:
                        this.title = '收到的关注';
                        this.moreList = list;
                        break;
                    case 10:
                        this.title = '收到的收藏';
                        this.moreList = list;
                        break;
                }
            },

            // 获取分享模板通知
            getNoticeTemplate() {
                this.newApi.getNoticeList({type: 12}).then(res =>{
                    this.templateList = res.data
                });
                this.newApi.getNoticeList({type: 11}).then(res =>{
                    this.applyTemplateList = res.data
                });
            },

            // 获取通知列表
            getNotices() {
                // 获取邀请好友操作信息
                this.newApi.getNoticeList({type: 2}).then(res =>{
                    if (res.isSuccess === 1) {
                        this.friendList = res.data
                    }
                });

                // 后台系统通知
                // this.newApi.getNoticeList({type: 0}).then(res =>{
                //     this.systemList = res.data
                // });

                // 点赞
                this.newApi.getNoticeList({type: 4}).then(res =>{
                    this.likeList = res.data
                });

                // 转发
                this.newApi.getNoticeList({type: 5}).then(res =>{
                    this.transpondList = res.data
                });

                // 评论
                this.newApi.getNoticeList({type: 6}).then(res =>{
                    this.commentList = res.data
                });

                // 关注
                this.newApi.getNoticeList({type: 7}).then(res =>{
                    this.collectList = res.data
                });

                // 被@的
                this.newApi.getNoticeList({type: 8}).then(res =>{
                    this.toAtList = res.data
                });

                // 收藏
                this.newApi.getNoticeList({type: 10}).then(res =>{
                    this.needList = res.data
                });

                //朋友圈活动
                this.newApi.getNoticeList({type: 18}).then(res =>{
                    this.activityList = res.data
                });
            },

            // 获取专家订单
            // getExpertList() {
            //     this.newApi.getNoticeList({type: 9}).then(res =>{
            //         for (let i = 0; i < res.data.length; i += 4) {
            //             this.expertList.push(res.data.slice(i, i + 4))
            //         }
            //     })
            // },

            // 通过id已读
            idReading(id) {
                this.newApi.isReadId({id: id}).then(res => {
                    if (res.isSuccess == 1) {
						this.$addStorageEvent('noticeCount', String(parseInt(sessionStorage.getItem("noticeCount"))-1));
                        this.getNotices()
                      this.$parent.updateCount()
                    }
                })
            },

            // 点击进入详情
            toDetail(item) {
              if (item.bgbType == 10 && item.type == 18) {
                this.$router.push({path: item.url});
              } else {
                if(item.bgbType == 1) {
                  this.utils.b('/circle/dynamicInfo/' + item.url)
                }
                if (item.bgbType == 2) {
                  this.utils.b('/content/info/' + item.url + "/1");
                }
                if (item.bgbType == 6) {
                  this.utils.b('/content/info/' + item.url + "/2");
                }
                if (item.bgbType == 3) {
                  this.utils.b('/circle/imageInfo/' + item.url);
                }
                if (item.bgbType == 4) {
                  this.utils.b('/circle/videoInfo/' + item.url);
                }
                if (item.bgbType == 5) {
                  this.utils.b('/circle/enclosureInfo/' + item.url);
                }
                if (item.bgbType == 7 || item.bgbType == 8) {
                  this.utils.b('/content/picture/' + item.url);
                }
              }

                // if (item.bgbType > 8) {
                //   this.utils.msg('无详情页')
                // }
                this.idReading(item.id)
            },

            // 显示选择分组
            showGroups(id) {
                this.friendId = id;
                this.isGroup = true
            },

            // 获取加好友通知消息
            getAddFriendMessage() {
                this.newApi.getAddFriendList({}).then(res =>{
                    this.applyData = [];
                    for (let i = 0; i < res.data.length; i += 4) {
                        this.applyData.push(res.data.slice(i, i + 4))
                    }
                })
            },

            // 获取好友分组
            getFriendGroups() {
                this.newApi.getMyFriendGroupList({type: 1}).then(res =>{
                    this.groups = res.data
                })
            },

            // 同意好友申请
            consentFriendApply() {
                this.newApi.processFriendRequest({id: this.friendId, status: 1, groupId: this.groupId}).then(res =>{
                    if (res.isSuccess) {
                        this.friendId = "";
                        this.groupId = 0;
                        this.getAddFriendMessage();
                        this.$parent.getFriendGroups();
                        this.utils.sus(res.data)
                    } else {
                        this.groupId = 0;
                        this.friendId = "";
                        this.utils.err(res.data);
                        this.getAddFriendMessage()
                    }
                });
                this.isGroup = false
            },

            // 取消好友分组
            cancelFriendApply() {
                this.groupId = 0;
                this.friendId = "";
                this.isGroup = false
            },

            // 忽略好友申请
            loseFriendApply(id) {
                this.newApi.processFriendRequest({id: id, status: 2}).then(res =>{
                    if (res.isSuccess) {
                        this.getAddFriendMessage();
                        this.utils.sus(res.data)
                    } else {
                        this.utils.err(res.data)
                    }
                });
            },

            // 进入通知详情页
            onNoticeDetails() {
                this.pageType = 'details'
            },

            //点击返回通知主页
            onNoticeHome() {
                this.pageType = 'home'
            },

            // 点击展示全部内容
            showAllContent() {
                this.showAll = ! this.showAll
            },

            // 点击切换收藏
            checkoutCollection() {
                this.testCollection = !this.testCollection
            },

            // 选择按热度排序
            selectHotTag() {
                this.selectHot = true;
                this.selectTime = false
            },

            // 选择按时间排序
            selectTimeTag() {
                this.selectTime = true;
                this.selectHot = false
            },
        },
    }
</script>

<style scoped>
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
    }

    .notice-body {
        width: 600px
    }

    .notice-content {
        width: 596px;
        max-height: 460px;
        margin-bottom: 10px;
        overflow: hidden;
    }

    .notice-title {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        font-size: 18px;
        height: 24px;
        padding: 18px 20px;
        background-color: #fff;
        margin-bottom: 2px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .notice-message {
        padding: 20px 20px;
        background-color: white;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)
    }

    .title-update {
        margin-top: 6px;
        font-weight: 600;
        width: 90px;
        line-height: 20px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .notice-info {
        display: flex;
        width: 97%;
        margin-bottom: 10px;
        padding: 6px 10px;
        background-color: #F3F3F3;
        border-radius: 5px 5px 5px 5px;
    }


    /deep/ .el-carousel__arrow {
        width: 34px;
        min-height: 110px;
        border-radius: 3px;
        background-color: #E8E8E8;
    }

    /deep/ .el-carousel__arrow {
        z-index: 3;
    }


    .save-btn {
        width: 68px;
        height: 38px;
        color: #fff;
        background-color: #1C7EFF;
        border-radius: 5px;
    }

    .cancel-btn {
        width: 68px;
        height: 38px;
        margin-right: 18px;
        background-color: #E4E6EB;
        border-radius: 5px;
    }

    .show-more .inner-container {
        max-height: 800px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .show-more .inner-container::-webkit-scrollbar {
        display: none;
    }

    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .sc:active{
        transform:scale(0.97);
    }

    .normalTag {
        margin-right: 38px;
    }

    .selectTag {
        margin-right: 38px;
        color: #FFA500;
    }

    .el-divider--horizontal {

    }

    .Dynamic .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
    }
    .Dynamic .head .user{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .Dynamic .head .user .userIco{
        width: 60px;
        height: 60px;
        background-color: #707070 ;
        border-radius: 50%;
        margin-right: 12px;
    }
    .Dynamic .head .user .major .userName{
        font-weight: 600;
        color: #444444;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items:flex-end;
    }
    .Dynamic .head .user .major .userMajor{
        font-size: 13px;
        color: #999999;
        margin-top: 5px;
        margin-right: 10px;

    }
    .Dynamic .substance{
        padding: 0 20px 20px 20px;
        margin-top: 20px;
        line-height: 26px;
        font-size: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .Dynamic .substance .contentType{
        color: #031F88;
        height: 16px;
        font-weight: 600;
        margin-right: 10px;
    }
    .Dynamic .substance .t{
        color: #555555;
    }
</style>
