<template>
  <div class="follow">
    <div style="display: flex; flex-wrap: wrap">
      <div @click="openInfo(item.uuid, item.userType)" style="display: flex; justify-content: center;" class="list-content hand op" v-for="(item, index) in subsList.list" :key="index">
        <img class="head-ico" style="width: 40px; height: 40px; margin: auto 6px;" :src="item.headIco">
        <div style="margin: auto 0; width: 70%">
          <div style="font-size: 16px; font-weight: 600; margin-bottom: 2px">
            {{item.nickName}}
          </div>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 20px;text-align: center;">
      <el-pagination
          background
          @current-change="getFollowList"
          :current-page.sync="page"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="subsList.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>

export default {
  name: "Follow",
  components: {},
  data() {
    return {
      subsList: {},
      page: 1,
      pageSize: 18
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  props: {
    uuid: {
      Object: String,
      default() {
        return ''
      }
    },
    type: {
      Object: Number,
      default() {
        return 1
      }
    },
  },
  created: function () {

  },
  mounted: function () {
    this.getFollowList()
  },
  methods: {
    // 获取关注列表
    getFollowList() {
      if (this.type === 1) {
        this.newApi.getFollowList({page: this.page, pageSize: this.pageSize, uuid: this.uuid}).then(res => {
          if (res.isSuccess === 1) {
            this.subsList = res.data
          }
        })
      }
      if (this.type === 2) {
        this.newApi.getFansList({page: this.page, pageSize: this.pageSize, uuid: this.uuid}).then(res => {
          if (res.isSuccess === 1) {
            this.subsList = res.data
          }
        })
      }
    },

    // 点击打开用户信息
    openInfo(uuid, userType) {
      if (!this.utils.toAuth(1)) {
        return false
      }
      if (userType === 1) {
        this.utils.a('/circle/mySpace/' + uuid)
      }
      if (userType === 2 || userType === 3) {
        this.utils.a('/circle/organSpace/' + uuid)
      }
    },
  }
}
</script>

<style scoped>
.list-content {
  width: 308px;
  height: 64px;
  border: 1px #CED0D4 solid;
  margin: 0 10px 10px 0;
}

.list-btn {
  width: 78px;
  height: 24px;
  line-height: 24px;
  margin: auto 10px;
  border-radius: 5px;
  text-align: center;
}

.head-ico {
  width: 56px;
  height: 56px;
  margin-right: 10px;
  border-radius: 50%;
}
</style>
