<template>
	<div>
		<div style="width: 100%;height: 90%;">
			<vueCropper
				ref="cropper"
				style="height: 100%;overflow: hidden;"
				:img="FileInfo.imgUrl"
				:outputSize="option.size"
				:outputType="option.outputType"
				:info="true"
				:guides="false"
				:high='option.high'
				:fixed="false"
				:full="option.full"
				:canMove="option.canMove"
				:canMoveBox="option.canMoveBox"
				:original="option.original"
				:autoCrop="option.autoCrop"
				:autoCropWidth="option.autoCropWidth"
				:autoCropHeight="option.autoCropHeight"
				:fixedBox="option.fixedBox"
				:infoTrue="option.infoTrue"
				:fixedNumber="option.fixedNumber"
				:maxImgSize="option.maxImgSize"
				:enlarge="option.enlarge"
				:mode="option.mode"
				@realTime="realTime"
				>
			</vueCropper>
		</div>
		<div style="width: 100%;height: 10%;margin-top: 20px;">
			<el-button type="success" @click.stop="rotateLeft">↺ 左旋转</el-button>
			<el-button type="success" @click.stop="rotateRight">↻ 右旋转</el-button>
			<el-button type="danger" icon="el-icon-zoom-in" @click.stop="changeScale(1)">放大</el-button>
			<el-button type="danger" icon="el-icon-zoom-out" @click.stop="changeScale(-1)">缩小</el-button>
			<el-button type="info" @click="showPreview=!showPreview"><span v-if="showPreview">关闭</span><span v-if="!showPreview">开启</span>实时预览</el-button>
			<el-button type="info" @click="showImage">效果预览</el-button>
			<el-button type="primary" @click.stop="download">保存到本地</el-button>
			<el-button type="primary" @click.stop="uploadImage">上传图片</el-button>
<!--			<el-button type="primary" @click.stop="getCropData">获取base64数据</el-button>-->
<!--			<el-button type="primary" @click.stop="getCropBlob">获取Blob数据</el-button>-->
		</div>
		<div class="show-preview" v-if="showPreview">
			<div :style="previews.div" class="preview">
				<img :src="previews.url" :style="previews.img">
			</div>
		</div>
		<el-image
			ref="image"
			v-if="showImagePreview"
			style="display:none;"
			:src="showImagePreviewUrl"
			:preview-src-list="[showImagePreviewUrl]">
		</el-image>
	</div>
</template>
<script>
import { VueCropper }  from 'vue-cropper'

export default {
	name: "imageCropper",
	components: {
		VueCropper
	},
	props: {
		FileInfo: {
			type: Object,
			required: false,
			default: () => {
				return {}
			}
		},
		width: {
			type: Number,
			required: true,
		},
		height: {
			type: Number,
			required: true,
		}
	},
	data() {
		return {
			// 裁剪组件基础配置option，更多属性或更多具体说明参考官方文档
			option: {
				info: false, // 裁剪框的大小信息
				outputSize: 0.8, // 裁剪生成图片的质量
				outputType: 'jpeg', // 裁剪生成图片的格式(jpg(jpg 需要传入jpeg))
				canScale: false, // 图片是否允许滚轮缩放(这个属性貌似没得用，不管设置true，false都可以滚轮缩放)
				autoCrop: true, // 是否默认生成截图框
				autoCropWidth: this.width, // 默认生成截图框宽度
				autoCropHeight:this.height, // 默认生成截图框高度
				fixedBox: true, // 固定截图框大小 不允许改变
				fixed: false, // 是否开启截图框宽高固定比例
				fixedNumber: [2.6, 1], // 截图框的宽高比例(这是比例，按两个值的比值大小进行截图框的宽高的设置，[1,1]和[100,100]是一样的)
				full: true, // 是否输出原图比例的截图
				canMove: false, // 上传图片是否可以移动
				canMoveBox: true, // 截图框能否拖动
				original: false, // 上传图片按照原始比例渲染
				centerBox: false, // 截图框是否被限制在图片里面
				infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
				maxImgSize: 1500,    //限制图片最大宽度和高度
				enlarge: 1,          //图片根据截图框输出比例倍数
				mode: 'cover'  //图片默认渲染方式
			},
			// base64Data: {
			// 	dataURL: '', // 用url方式表示的base64图片数据
			// 	type: 'image/jpeg' //文件类型
			// },
			previews: {}, // 预览图片数据
			showPreview:false, // 是否显示实时预览,
			showImagePreviewUrl:'', // 图片预览列表
			showImagePreview:false // 是否显示图片预览
		}
	},
	created() {
	
	},
	mounted() {
	
	},
	beforeDestroy() {
		this.showImagePreview=false;
	},
	methods: {
		changeScale (num) {
			num = num || 1
			this.$refs.cropper.changeScale(num)
		},
		//最终效果预览
		showImage(){
			this.$refs.cropper.getCropBlob(data => {
				this.showImagePreview = true;
				this.showImagePreviewUrl = URL.createObjectURL(data);
				this.$refs.image.clickHandler();
			})
		},
		//实时预览函数
		realTime (data) {
			this.previews = data
		},
		/**
		 * 向左边旋转90度
		 */
		rotateLeft() {
			this.$refs.cropper.rotateLeft() // 只能固定向左边旋转90度，不接受设定旋转角度
		},
		/**
		 * 向右边旋转90度
		 */
		rotateRight() {
			this.$refs.cropper.rotateRight() // 只能固定向右边旋转90度，不接受设定旋转角度
		},
		
		/**
		 * 上传图片
		 */
		uploadImage(){
			this.$refs.cropper.getCropBlob(data => {
				const href = URL.createObjectURL(data);
				const file = new window.File([data], this.FileInfo.fileInfo.name, { type: data.type })
				const imageInfo = {}
				imageInfo.imgUrl = href
				imageInfo.fileInfo = file
				this.$emit("update:imageInfo", imageInfo)
			})
		},
		
		/**
		 * 下载图片到本地
		 */
		download(){
			this.$refs.cropper.getCropBlob(data => {
				// 将data是个blob数据
				const link = document.createElement('a');
				link.href = URL.createObjectURL(data);
				link.download = this.FileInfo.fileInfo.name;
				link.click()
			})
		},
		
		/**
		 * 将 base64数据 直接转换为 file对象
		 */
		// dataUrlToFile: function (dataUrl, fileName) {
		// 	let arr = dataUrl.split(','),
		// 		mime = arr[0].match(/:(.*?);/)[1],
		// 		bstr = atob(arr[1]),
		// 		n = bstr.length,
		// 		u8arr = new Uint8Array(n)
		// 	while (n--) {
		// 		u8arr[n] = bstr.charCodeAt(n)
		// 	}
		// 	return new File([u8arr], fileName, { type: mime })
		// },
		//
		/**
		 * base64 转Blob
		 */
		// base64ToBlob(base64Data) {
		// 	let arr = base64Data.dataURL.split(',')
		// 	let mime = arr[0].match(/:(.*?);/)[1] || base64Data.type
		// 	// 去掉url的头，并转化为byte
		// 	let bytes = window.atob(arr[1])
		// 	// 处理异常,将ascii码小于0的转换为大于0
		// 	let ab = new ArrayBuffer(bytes.length)
		// 	// 生成视图（直接针对内存）：8位无符号整数，长度1个字节
		// 	let ia = new Uint8Array(ab)
		// 	for (let i = 0; i < bytes.length; i++) {
		// 		ia[i] = bytes.charCodeAt(i)
		// 	}
		// 	return new Blob([ab], {
		// 		type: mime
		// 	})
		// },
		
		/**
		 * 获取截图的 base64 数据
		 */
		// getCropData() {
		// 	this.$refs.cropper.getCropData(data => {
		// 		// do something
		// 		// 由于服务端文件上传接口统一接收file对象，从而 base64编码 数据在提交前需要将编码数据转为file对象
		// 		// 将 base64编码数据 转成 file对象，有两种方式
		// 		// 1、直接用 new File()方法 把 base64数据 转成 file对象
		// 		let file = this.dataUrlToFile(data, this.FileInfo.fileInfo.name)
		//
		// 		// 2、先将 base64编码数据 转成 blob数据，然后用file对象内置方法，将blob数据转成file对象
		// 		this.base64Data.dataURL = data
		// 		let blobData = this.base64ToBlob(this.base64Data)
		// 		const file = new window.File([blobData], this.FileInfo.fileInfo.name, { type: data.type })
		//
		// 		// 获取图片数据后，将数据上传给父组件(imgUrl:图片预览链接地址，fileData:截取的图片数据)
		// 		// 由于 base64 链接数据，img标签src属性可以直接使用，故此处不做处理
		// 		// this.$emit('emitPar', { imgUrl: data, fileData: file })
		// 	})
		// },
		
		/**
		 * 获取截图的 blob 数据
		 */
		// getCropBlob() {
		// 	this.$refs.cropper.getCropBlob(data => {
		// 		// do something
		// 		// 由于服务端文件上传接口统一接收file对象，从而数据在提交前需要将blob数据转为file对象，转换方法如下：
		// 		const file = new window.File([data], this.FileInfo.fileInfo.name, { type: data.type })
		// 		// 获取图片数据后，将数据上传给父组件(imgUrl:图片本地预览 blob数据地址，fileData:截取的图片数据 转换成的file对象)
		// 		// this.$emit('emitPar', { imgUrl: URL.createObjectURL(data), fileData: file })
		// 	})
		// }
	}
}
</script>
<style>
.show-preview {
	flex: 1;
	-webkit-flex: 1;
	display: flex;
	display: -webkit-flex;
	justify-content: center;
	margin-top: 30px;
}
.preview{
	overflow: hidden;
	border:1px solid #67c23a;
	
}
</style>
