<template>
    <div style="padding:20px 20px 20px 30px; min-height: 800px">
        <div class="title-box">
            <div style="font-size: 16px">我创建的活动{{myEventList.myCreat.length}}个</div>
        </div>
        <div style="display: flex;flex-wrap: wrap" class="event-box" v-if="myEventList.myCreat.length > 0">
            <div style="width: 270px; height: 310px ;border-radius: 8px 8px 8px 8px; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-bottom: 10px; margin-right: 10px" v-for="(item, index) in myEventList.myCreat" :key="index">
                <img :src="item.cover"  @click="toEvent(item.id)" style="width: 100%; height: 130px; background-color: #FFFFFF; border-radius: 8px 8px 0 0; margin-bottom: 14px" class="hand op sc">
                <div style="width: 270px; margin-left: 10px; font-size: 16px;">
                    <div style="margin-bottom: 6px">
                        <span style="color: #999999">活动时间：</span>
                        <span style="color: #2970FF">{{item.activityTime}}</span>
                    </div>
                    <div style="width: 90%; margin-bottom: 6px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                        <span style="color: #999999">活动名称：</span>
                        <span style="font-size: 16px">{{item.title}}</span>
                    </div>
                    <div style="width: 90%; margin-bottom: 6px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                        <span style="color: #999999">活动地点：</span>
                        <span style="font-size: 16px">{{item.location}}</span>
                    </div>
                    <div v-if="item.status == 2">
                        <span style="color: #999999">活动状态：</span>
                        <span v-if="Date.now() > Date.parse(item.endTime)" style="color: red;">活动结束 {{item.signNum}}人报名</span>
                        <span v-if="Date.now() < Date.parse(item.endTime)">正在报名 {{item.signNum}}人报名</span>
                    </div>
                    <div v-if="item.status == 3">
                        <span style="color: #999999">活动状态：</span>
                        <span style="color: red;">活动已取消</span>
                    </div>
                </div>
                <div style="display: flex ;justify-content: center">
                    <div class="hand op" style="width: 182px; height: 19px; background-color: #E4E6EB; border-radius: 5px 5px 5px 5px; padding: 7px 0; text-align: center; margin: 14px 10px">
                        <div style="margin-right: 6px" @click="toEvent(item.id)">查看详情</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="title-box" style="margin-top: 30px">
            <div style="font-size: 16px">我参加的活动{{myEventList.mySign.length}}个</div>
        </div>
        <div style="display: flex; flex-wrap: wrap" class="event-box" v-if="myEventList.mySign.length > 0">
            <div style="width: 270px; height: 310px ;border-radius: 8px 8px 8px 8px; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-bottom: 10px; margin-right: 10px" v-for="(item, index) in myEventList.mySign" :key="index">
                <img :src="item.cover"  @click="toEvent(item.id)" style="width: 100%; height: 130px; background-color: #FFFFFF; border-radius: 8px 8px 0 0; margin-bottom: 14px" class="hand op sc">
                <div style="width: 270px; margin-left: 10px; font-size: 16px;">
                    <div style="margin-bottom: 6px">
                        <span style="color: #999999">活动时间：</span>
                        <span style="color: #2970FF">{{item.activityTime}}</span>
                    </div>
                    <div style="width: 90%; margin-bottom: 6px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                        <span style="color: #999999">活动名称：</span>
                        <span style="font-size: 16px">{{item.title}}</span>
                    </div>
                    <div style="width: 90%; margin-bottom: 6px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                        <span style="color: #999999">活动地点：</span>
                        <span style="font-size: 16px">{{item.location}}</span>
                    </div>
                    <div v-if="item.status == 2">
                        <span style="color: #999999">活动状态：</span>
                        <span v-if="Date.now() > Date.parse(item.endTime)" style="color: red;">活动结束 {{item.signNum}}人报名</span>
                        <span v-if="Date.now() < Date.parse(item.endTime)">正在报名 {{item.signNum}}人报名</span>
                    </div>
                    <div v-if="item.status == 3">
                        <span style="color: #999999">活动状态：</span>
                        <span style="color: red;">活动已取消</span>
                    </div>
                </div>
                <div style="display: flex; justify-content: center">
                    <div class="hand op" style="width: 182px; height: 19px; background-color: #E4E6EB; border-radius: 5px 5px 5px 5px; padding: 7px 0; text-align: center; margin: 14px 10px">
                        <div style="margin-right: 6px" @click="toEvent(item.id)">查看详情</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "allView",
        data() {
            return {}
        },

        props: {
            myEventList: {
                type: Object,
                default() {
                    return {}
                }
            }
        },

        mounted() {

        },

        methods: {
            // 进入事件详情
            toEvent(id) {
                this.$parent.toEvents(id)
            },
        }
    }
</script>

<style scoped>
    .title-box {
        font-size: 18px;
        line-height: 30px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .event-body {
        width: 210px;
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .event-body .event-title {
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 600;
    }

    .event-body .active {
        width: 210px;
    }

    .event-body .event-content {
        min-height: 120px;
        padding: 10px 20px 10px 20px;
        line-height: 30px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-body .event-info {
        min-height: 120px;
        padding: 20px;
        line-height: 20px;
        background-color: #FFFFFF;
        border-radius: 0 0 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
    }

    .event-body .event-content .image-ico {
        width: 100%;
        height: 112px;
    }

    .event-body .event-content .image-btn {
        width: 110px;
        height: 28px;
        margin-top: 6px;
        margin-right: 14px;
        text-align: center;
        line-height: 28px;
        font-weight: 600;
        border-radius: 5px;
        background-color: #E4E6EB;
    }

    .event-body .event-content .send-btn {
        width: 44px;
        height: 24px;
        margin-top: 6px;
        line-height: 28px;
        padding: 2px;
        margin-right: 10px;
        border-radius: 5px;
        background-color: #E4E6EB;
        font-weight: 600;
        text-align: center;
    }
</style>
