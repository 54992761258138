<template>
    <div class="friend-head" :style="{'background-image': `url(${userInfo.bgImage})`, 'background-repeat':'no-repeat',  'background-size': '100% 220px', 'height': '120px'}">
        <div style="padding: 60px 20px 0 10px">
            <div style="display: flex; justify-content: space-between">
                <div style="display: flex">
                    <img :src="userInfo.headIco + '-200x200'" class="head-ico">
                    <div style="width: 78%;">
                        <div style="display: flex; margin: 62px 0 10px 0">
                            <span style="font-size: 20px; margin-right: 10px; font-weight: 600">{{userInfo.nickName}}</span>
                            <span v-show="userInfo.userType == 3" style="width: 90px; height: 18px; background-color: #F5CF0E; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px; margin-right: 10px;">企业子账号</span>
                            <span v-show="userInfo.userType == 2 && userInfo.organizationType == 1" style="width: 76px; height: 18px; background-color: #1479FF; color: #FFFFFF;text-align: center; margin-top: 6px;  border-radius: 5px; padding: 2px 6px;margin-right: 10px;">企业账号</span>
                            <span v-show="userInfo.userType == 2 && userInfo.organizationType == 2" style="width: 76px; height: 18px; background-color: #1479FF; color: #FFFFFF;text-align: center; margin-top: 6px; border-radius: 5px; padding: 2px 6px;margin-right: 10px;">机关账号</span>
                            <span v-show="userInfo.userType == 2 && userInfo.organizationType == 3" style="width: 76px; height: 18px; background-color: #1479FF; color: #FFFFFF;text-align: center; margin-top: 6px; border-radius: 5px; padding: 2px 6px;margin-right: 10px;">社团账号</span>
                            <span v-show="userInfo.userType == 2 && userInfo.organizationType == 4" style="width: 76px; height: 18px; background-color: #1479FF; color: #FFFFFF;text-align: center; margin-top: 6px; border-radius: 5px; padding: 2px 6px;margin-right: 10px;">工作室账号</span>
                            <img v-show="userInfo.authenticationStatus >= 1 && userInfo.userType == 1" src="../../../assets/imgs/circle/realName.png" style="margin-right: 10px; margin-top: 4px; width: 60px; height: 22px;"/>
                            <img v-show="userInfo.authenticationStatus >= 1 && userInfo.userType > 1" src="../../../assets/imgs/circle/certified.png" style="margin-right: 10px; margin-top: 4px; width: 60px; height: 22px;"/>
                            <img v-show="userInfo.authenticationStatus >= 2" src="../../../assets/imgs/circle/newP.png" style="margin-right: 10px;margin-top: 4px; width: 50px; height: 22px;"/>
                            <img v-show="userInfo.expertAuthStatus === 2" src="../../../assets/imgs/circle/newS.png" style="margin-right: 10px;margin-top: 4px; width: 50px; height: 22px;"/>
                            <img v-show="userInfo.v == 1" src="../../../assets/imgs/circle/vipBig.png" style="margin-right: 10px; width: 22px;margin-top: 4px; height: 22px;"/>
                        </div>
                        <div style="font-size: 16px">
                            <span style="color: #999999; margin-right: 20px" v-if="userInfo.address.cityName">{{userInfo.address.cityName[1]}}</span>
                            <span style="color: #999999; margin-right: 10px"> | </span>
                            <span style="color: #1479FF; margin-right: 10px" class="hand op" @click="getFollowList">关注</span>
                            <span style="margin-right: 10px">{{userInfo.myFollowNum}}</span>
                            <span style="color: #999999; margin-right: 10px"> | </span>
                            <span style="color: #1479FF; margin-right: 10px" class="hand op" @click="getFansList">粉丝</span>
                            <span style="margin-right: 10px">{{userInfo.fansNum}}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div style="display: flex; justify-content: end; min-height: 40px">
                        <div>
                            <el-popover
                                placement="bottom-start"
                                width="338px"
                                trigger="hover">
                                <div style="width: 338px" v-if="userInfo.userType === 1">
                                    <div style="display: flex; justify-content: space-between">
                                        <div style="font-size: 20px; font-weight: 600; margin-bottom: 10px; color: #031F88">个人资料</div>
                                    </div>
                                    <div>{{spaceInfo.userSpaceInfo.personalSignature}}</div>
                                    <el-divider></el-divider>
                                  <div class="left-info" v-if="spaceInfo.personalData">
                                    <div v-if="spaceInfo.personalData.schoolInfo">
                                      <div style="margin-bottom: 14px;" v-if="spaceInfo.personalData.schoolInfo.length > 0">
                                        <span class="info-tag">毕业于：</span>
                                        <span>{{spaceInfo.personalData.schoolInfo[spaceInfo.personalData.schoolInfo.length-1].schoolName}} {{spaceInfo.personalData.schoolInfo[spaceInfo.personalData.schoolInfo.length-1].major}}专业 {{spaceInfo.personalData.schoolInfo[spaceInfo.personalData.schoolInfo.length-1].degree}}学位</span>
                                      </div>

                                    </div>
                                    <div style="margin-bottom: 14px" v-if="JSON.stringify(spaceInfo.userSpaceInfo.address) !== '{}'">
                                      <span class="info-tag">所在地： </span>
                                      <span style="color: #2970FF">{{spaceInfo.userSpaceInfo.address.cityName[0]}} / {{spaceInfo.userSpaceInfo.address.cityName[1]}}</span>
                                    </div>
                                    <div style="margin-bottom: 14px" v-if="spaceInfo.personalData.skills">
                                      <span class="info-tag">业务技能： </span>
                                      <span>{{spaceInfo.personalData.skills}}</span>
                                    </div>
                                    <div v-if="spaceInfo.personalData.workInfo">
                                      <div style="margin-bottom: 14px" v-if="spaceInfo.personalData.workInfo.length > 0">
                                        <span class="info-tag">工作经历： </span>
                                        <span v-for="(item, index) in spaceInfo.personalData.workInfo" :key="index" style="margin-right: 6px; color: #2970FF; font-size: 16px;">{{item.companyName}}（{{item.position}}）</span>
                                      </div>
                                    </div>

                                    <div v-if="spaceInfo.personalData.projectInfo">
                                      <div style="margin-bottom: 14px" v-if="spaceInfo.personalData.projectInfo.length > 0">
                                        <span class="info-tag">项目经历： </span>
                                        <span v-for="(item, index) in spaceInfo.personalData.projectInfo" :key="index" style="margin-right: 6px; color: #2970FF; font-size: 16px;">{{item.name}}</span>
                                      </div>
                                    </div>

                                    <div v-if="spaceInfo.personalData.honorInfo">
                                      <div style="margin-bottom: 14px" v-if="spaceInfo.personalData.honorInfo.length > 0">
                                        <span class="info-tag">获得荣誉： </span>
                                        <span v-for="(item, index) in spaceInfo.personalData.honorInfo" :key="index" style="margin-right: 6px;color: #2970FF; font-size: 16px; line-height: 28px">{{item.name}}</span>
                                      </div>
                                    </div>

                                    <!--                            <div style="margin-bottom: 14px" v-if="spaceInfo.personalData.professionalTitleInfo.length !== 0">-->
                                    <!--                                <span class="info-tag">职业证书： </span>-->
                                    <!--                                <span v-if="majorInfo.professionalTitleInfo[0].isOvert == '1'">{{majorInfo.professionalTitleInfo[0].name}}</span>-->
                                    <!--                            </div>-->
                                    <!--                            <div style="margin-bottom: 14px" v-if="majorInfo.NVQCartInfo.length !== 0">-->
                                    <!--                                <span class="info-tag">资格证书： </span>-->
                                    <!--                                <span>{{majorInfo.NVQCartInfo[0].name}}</span>-->
                                    <!--                            </div>-->
                                    <div v-if="spaceInfo.personalData.otherInfo">
                                      <div style="margin-bottom: 14px" v-if="spaceInfo.personalData.otherInfo.length > 0">
                                        <span class="info-tag">其他证书： </span>
                                        <span v-for="item in spaceInfo.personalData.otherInfo">{{item.name}}</span>
                                      </div>
                                    </div>
                                    <div style="margin-bottom: 14px" v-if="spaceInfo.userSpaceInfo.label.length !== 0">
                                      <span class="info-tag">个人标签：</span>
                                      <div>
                                        <el-tag v-for="(item, index) in spaceInfo.userSpaceInfo.label" :key="index" effect="plain" size="medium" style="border-radius: 20px; margin-right: 12px; margin-top: 10px; font-size: 14px; color: #2970FF">{{ item }}</el-tag>
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                              <div style="width: 338px" v-else>
                                <div style="display: flex; justify-content: space-between">
                                  <div style="font-size: 20px; font-weight: 600; margin-bottom: 10px; color: #031F88">机构资料</div>
                                </div>
                                <div>{{spaceInfo.userSpaceInfo.personalSignature}}</div>
                                <el-divider></el-divider>
                                <div class="left-info">
                                  <div style="margin-bottom: 14px" v-if="spaceInfo.institutionData.Business">
                                    <span class="info-tag">经营范围： </span>
                                    <span>{{spaceInfo.institutionData.Business}}</span>
                                  </div>
                                  <div style="margin-bottom: 14px" v-if="JSON.stringify(spaceInfo.userSpaceInfo.address) !== '{}'">
                                    <span class="info-tag">所在地： </span>
                                    <span style="color: #2970FF">{{spaceInfo.userSpaceInfo.address.cityName[0]}} / {{spaceInfo.userSpaceInfo.address.cityName[1]}}</span>
                                  </div>
                                  <div style="margin-bottom: 14px" v-if="spaceInfo.userSpaceInfo.label.length !== 0">
                                    <span class="info-tag">机构标签：</span>
                                    <div>
                                      <el-tag v-for="(item, index) in spaceInfo.userSpaceInfo.label" :key="index" effect="plain" size="medium" style="border-radius: 20px; margin-right: 12px; margin-top: 10px; font-size: 14px; color: #2970FF">{{ item }}</el-tag>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                <div class="friend-btn" style="line-height: 22px; margin-right: 20px" slot="reference">查看详细资料<i class="el-icon-caret-bottom" style="margin-left: 2px"></i></div>
                            </el-popover>
                        </div>
                        <div class="hand sc cover-btn" style="background-color: #FF9800; color: #ffffff; border: none" @click="openInfo(userInfo.uuid, userInfo.userType)">
                            <img src="../../../assets/imgs/circle/go.png" style="width: 16px; height: 14px; margin-right: 8px; vertical-align: middle"/>
                            <span style=" vertical-align: middle">进入{{userInfo.userType === 1 ? '个人' : '机构'}}空间</span>
                        </div>
                    </div>
                    <div style="display: flex; margin-top: 40px">
                        <div>
                            <div v-if="(userInfo.isFans === 0 && userInfo.isFriend === -2) || (userInfo.isFans === 0 && userInfo.isFriend === 4) || (userInfo.isFans === 0 && userInfo.isFriend === -1) || (userInfo.isFans === 0 && userInfo.isFriend === 2)" style="display: flex; margin-right: 10px">
                                <div class="hand sc pay-btn" @click="toPayFriends">
                                    <i class="el-icon-plus" style="vertical-align: middle; font-weight: 600; margin-right: 6px"></i>
                                    <span style=" vertical-align: middle">关注</span>
                                </div>
                                <div class="hand sc friend-btn" @click="toAddFriends">
                                    <img src="../../../assets/imgs/circle/addFriends.png" style="width: 22px; height: 22px; margin-right: 6px; vertical-align: middle"/>
                                    <span style=" vertical-align: middle">加为好友</span>
                                </div>
                            </div>

                            <div v-if="(userInfo.isFans === 1 && userInfo.isFriend === 0) || (userInfo.isFans === 1 && userInfo.isFriend === 3)" style="display: flex; margin-right: 10px">
                                <div class="cel-btn">
                                    <span style=" vertical-align: middle">已关注</span>
                                </div>
                                <div class="friend-msg">
                                    <span style=" vertical-align: middle">待对方通过好友申请</span>
                                </div>
                            </div>

                            <div v-if="userInfo.isFans === 1 && userInfo.isFriend === 1" style="display: flex; margin-right: 10px">
                                <div class="cel-btn">
                                    <span style=" vertical-align: middle">已关注</span>
                                </div>
                                <div class="hand sc friend-btn" @click="toDelFriends">
                                    <img src="../../../assets/imgs/circle/addFriends.png" style="width: 22px; height: 22px; margin-right: 6px; vertical-align: middle"/>
                                    <span style=" vertical-align: middle">解除好友</span>
                                </div>
                            </div>

                            <div v-if="(userInfo.isFans === 1 && userInfo.isFriend === -1) || (userInfo.isFans === 1 && userInfo.isFriend === 2)" style="display: flex; margin-right: 10px">
                                <div class="hand sc cel-btn" @click="toPayFriends">
                                    <span style=" vertical-align: middle">取消关注</span>
                                </div>
                                <div class="friend-msg"  style="color: #cc0000;">
                                    <span style=" vertical-align: middle">已解除好友关系</span>
                                </div>
                            </div>

                            <div v-if="userInfo.isFans === 1 && userInfo.isFriend === 4" style="display: flex; margin-right: 10px">
                                <div class="hand sc cel-btn" @click="toPayFriends">
                                    <span style=" vertical-align: middle">取消关注</span>
                                </div>
                                <div class="friend-msg"  style="color: #cc0000;">
                                    <span style=" vertical-align: middle">对方拒绝了好友申请</span>
                                </div>
                            </div>

                            <div v-if="userInfo.isFans === 1 && userInfo.isFriend === -2" style="display: flex; margin-right: 10px">
                                <div class="hand sc cel-btn" @click="toPayFriends">
                                    <span style=" vertical-align: middle">取消关注</span>
                                </div>
                                <div class="hand sc friend-btn" @click="toAddFriends">
                                    <img src="../../../assets/imgs/circle/addFriends.png" style="width: 22px; height: 22px; margin-right: 6px; vertical-align: middle"/>
                                    <span style=" vertical-align: middle">加为好友</span>
                                </div>
                            </div>
                        </div>


                        <div class="hand sc private-btn" @click="toMessages">
                            <img src="../../../assets/imgs/circle/email.png" style="width: 20px; height: 20px; margin-right: 6px; vertical-align: middle"/>
                            <span style=" vertical-align: middle">私信</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>

      <!--关注-->
      <el-dialog class="show-message" :visible.sync="showFollow" width="1000px">
        <div style="display: flex;">
          <div class="dialog-title">关注列表</div>
        </div>
        <div class="message-divider">
          <el-divider></el-divider>
        </div>
        <div class="message-body">
          <Follow :uuid="this.userInfo.uuid" :type="1"></Follow>
        </div>
      </el-dialog>

      <!--粉丝-->
      <el-dialog class="show-message" :visible.sync="showFans" width="1000px">
        <div style="display: flex;">
          <div class="dialog-title">粉丝列表</div>
        </div>
        <div class="message-divider">
          <el-divider></el-divider>
        </div>
        <div class="message-body">
          <Follow :uuid="this.userInfo.uuid" :type="2"></Follow>
        </div>
      </el-dialog>
    </div>
</template>

<script>

import ToMessage from "./ToMessage";
import Follow from "@/pages/circle/components/Follow.vue";
export default {
    name: "FriendHead",
    components: {Follow, ToMessage},
    props: {
        uuid: {
            type: String,
            default() {
                return ""
            }
        }
    },
    data() {
        return {
          showFollow: false,
          showFans: false,
            userInfo: {
              address: {}
            },
          spaceInfo: {
            messageAndReply: {
              MessageAndReplyList: []
            },
            institutionData: {},
            personalData: {},
            shopSpaceInfo: {},
            spaceSize: {},
            userSpaceInfo: {
              address: {},
              label: []
            },
            visitingCard: {}
          },
          isFriend: false,

          industryShow: [],
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.getUser(this.uuid);
    },
    methods: {
      // 获取关注列表
      getFollowList() {
        this.showFollow = true
      },

      // 获取粉丝列表
      getFansList() {
        this.showFans = true
      },

        openInfo(uuid, userType) {
            if (userType === 1) {
                this.$router.push({ path: '/circle/mySpace/' + uuid})
            }
            if (userType === 2 || userType === 3) {
                this.$router.push({ path: '/circle/organSpace/' + uuid})
            }
        },

        // 关注&取消关注
        toPayFriends() {
            this.newApi.followAccount({uuid: this.userInfo.uuid}).then(res => {
                if (res.isSuccess == 1) {
                    this.utils.sus(res.data);
                    this.getUser(this.userInfo.uuid)
                }
            });
        },

        // 加好友
        toAddFriends() {
          if (this.user.authenticationStatus !== 2 && this.user.userType === 1) {
            store.commit('setShowAuthPopup', 1);
            return false;
          } else {
            this.newApi.addFriends({uuid: this.userInfo.uuid}).then(res => {
              if (res.isSuccess == 1) {
                this.utils.sus(res.data);
                this.getUser(this.userInfo.uuid)
              }
            })
          }
        },

        // 解除好友
        toDelFriends() {
            this.newApi.delFriends({uuid: this.userInfo.uuid}).then(res =>{
                if (res.isSuccess == 1) {
                    this.utils.sus(res.data);
                    this.getUser(this.userInfo.uuid)
                }
            })
        },

        // 私信
        toMessages() {
          const routeData = this.$router.resolve({name: 'News', query: {uuid: this.userInfo.uuid}})
          window.open(routeData.href, '_blank');
        },


        // 获取用户信息
        getUser(uuid) {
          this.newApi.getSpaceInfo({uuid: uuid}).then(res =>{
            if (res.isSuccess === 1) {
              this.spaceInfo = res.data;
              this.userInfo = res.data.userSpaceInfo
              this.userInfo.isFans = res.data.isFans
              this.userInfo.isFriend = res.data.isFriend
            }
          })
        }
    }
}
</script>

<style scoped>
.friend-head{
    width: 100%;
    height: 100%;
}

.hand{
    cursor:pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cover-btn {
    width: 110px;
    height: 17px;
    background-color: #fff;
    padding: 8px 10px;
    line-height: 8px;
    border-radius: 5px;
    border: 1px solid;
    text-align: center;
}

.pay-btn {
    width: 64px;
    height: 16px;
    background-color: #fff;
    color: #FF9800;
    padding: 8px 10px;
    border-radius: 5px;
    line-height: 6px;
    margin-right: 10px;
    border: 1px #FF9800 solid;
    text-align: center;
}

.cel-btn {
    width: 64px;
    height: 16px;
    background-color: #fff;
    color: #FF9800;
    padding: 8px 10px;
    border-radius: 5px;
    line-height: 14px;
    margin-right: 10px;
    border: 1px #FF9800 solid;
    text-align: center;
}

.private-btn {
    width: 64px;
    height: 18px;
    background-color: #E4E6EB;
    padding: 8px 10px;
    border-radius: 5px;
    line-height: 6px;
    text-align: center;
}
.friend-btn {
    width: 104px;
    height: 22px;
    background-color: #1479FF;
    color: #ffffff;
    padding: 6px 10px;
    border-radius: 5px;
    line-height: 0;
    text-align: center;
}

.friend-msg {
    width: 104px;
    color: #1479FF;
    line-height: 15px;
    border-radius: 5px;
    text-align: center;
}

.head-ico {
    width: 102px;
    height: 102px;
    border-radius: 50%;
    border: 6px solid #fff;
    margin-right: 14px
}

.op:active{
    opacity:0.6;
    -webkit-transition:all .1s;
    transition:all .1s;opacity:.6;
}

.op{
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .5s;
    transition: all .5s;
}
.sc:active{
    transform:scale(0.97);
}

.dialog-title {
    height: 30px;
    padding: 10px 0;
    font-weight: 600;
    margin: 0 auto;
    font-size: 20px;
}


.friend-head .message-body {
  padding: 20px;
}

.friend-head .message-divider .el-divider--horizontal {
  margin: 0;
}

.info-tag {
    width: 88px;
    font-weight: 600;
    display: inline-block;
}

/deep/ .show-message .el-dialog__header{
    padding: 0;
}

/deep/ .show-message .el-dialog__body{
    padding: 0;
}

/*下划线*/
.show-message .el-divider--horizontal {
    margin: 0;
}

/deep/ .show-message .el-input__inner {
    height: 38px;
    line-height: 38px;
    background-color: #F0F2F5;
    border-radius: 30px;
    border: none;
}

/deep/ .edit-account .el-dialog__header{
    padding: 0;
}
</style>
