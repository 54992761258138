<template>
    <div class="circle-recommend" ref="recommend">
        <div style="width: 100%; overflow: hidden; margin-bottom: 2px; background-color: white; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
            <div class="tab border_bottom up" @click="changeTags('hotArticle')">
                <label>
                    <div class="head_white hand op">
                        <div :class="indexTags === 'hotArticle' ? 'head_white_word_act': 'head_white_word'">热门文章</div>
                        <div class="head_white_line" v-if="indexTags ==='hotArticle'"></div>
                    </div>
                </label>
            </div>
            <div class="tab border_bottom up" @click="changeTags('recommendV')">
                <label>
                    <div class="head_white hand op">
                        <div :class="indexTags === 'recommendV' ? 'head_white_word_act': 'head_white_word'">推荐大V</div>
                        <div class="head_white_line"  v-if="indexTags === 'recommendV'"></div>
                    </div>
                </label>
            </div>
            <div class="tab border_bottom up" @click="changeTags('recommendF')">
                <label >
                    <div class="head_white hand op">
                        <div :class="indexTags === 'recommendF' ? 'head_white_word_act': 'head_white_word'">推荐好友</div>
                        <div class="head_white_line"  v-if="indexTags === 'recommendF'"></div>
                    </div>
                </label>
            </div>
<!--            <div class="tab border_bottom up" @click="indexTags = 'recommendC'">-->
<!--                <label >-->
<!--                    <div class="head_white hand op">-->
<!--                        <div :class="indexTags === 'recommendC' ? 'head_white_word_act': 'head_white_word'">推荐环境页</div>-->
<!--                        <div class="head_white_line" v-if="indexTags === 'recommendC'"></div>-->
<!--                    </div>-->
<!--                </label>-->
<!--            </div>-->
            <div class="tab border_bottom up" @click="changeTags('recommendS')">
                <label >
                    <div class="head_white hand op">
                        <div :class="indexTags === 'recommendS' ? 'head_white_word_act': 'head_white_word'">推荐店铺</div>
                        <div class="head_white_line" v-if="indexTags === 'recommendS'"></div>
                    </div>
                </label>
            </div>
        </div>
        <div style="width: 100%; overflow: hidden; margin-bottom: 10px; background-color: white">
            <div v-if="indexTags === 'hotArticle'" style="width: 100%;">
              <div v-if="hotArticleList.length > 0">
                <div v-for="(item, index) in hotArticleList" :key="index" @click="toArticle(item)" class="hand op">
                  <div style="display: flex; padding: 20px 20px">
                    <div>
                      <img style="width: 160px; height: 94px; margin-right: 10px" :src="item.cover"/>
                    </div>
                    <div style="width: 80%">
                      <div class="re-title" style="display: flex">
                        <div style="height: 24px; line-height: 24px; font-size: 18px; font-weight: 600; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{item.title}}</div>
                      </div>
                      <div class="re-content">
                        <div v-html="utils.toText(item.content)" style="color: #989898; margin-top: 10px;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;"></div>
                        <div style="color: #989898; margin-top: 8px; font-size: 13px">
                          <span>作者：{{item.user.nickName}}</span>
                          <span style="margin-left: 30px;">文章类型：暂无字段</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <el-divider></el-divider>
                </div>
              </div>
                <div v-else>
                  <NullBox data="热门文章"></NullBox>
                </div>
            </div>

            <div v-if="indexTags === 'recommendV'" style="width: 100%">
                <div class="re-body"  v-if="hotVList.length > 0" style="display: flex; flex-wrap: wrap; padding: 30px 20px 30px 30px">
                    <div class="re-box" v-for="(item, index) in hotVList" :key="index">
                      <div style="display: flex" class="hand op" @click="openInfo(item.uuid)">
                        <div style="margin-right: 12px">
                          <img :src="item.headIco" style="width: 50px; height: 50px; border-radius: 50%"/>
                        </div>
                        <div class="major">
                          <div class="userName">
                            <div>{{item.nickName}}</div>
                          </div>
                          <div>
                            <img v-if="item.v === 1" src="../../assets/imgs/circle/newV.png" style="width: 20px;height: 20px;"/>
                            <img v-if="item.majorAuthStatus === 2" src="../../assets/imgs/circle/newP.png" style="width: 48px;height: 22px;margin-left: 10px;"/>
                            <img v-if="item.expertAuthStatus === 2" src="../../assets/imgs/circle/newS.png" style="width: 48px;height: 22px;margin-left: 10px;"/>
                          </div>
                        </div>
                      </div>
                      <el-divider></el-divider>
                      <div style="display: flex; font-size: 16px; justify-content: space-between">
                        <div style="text-align: center">
                          <div>{{item.fansNum}}</div>
                          <div>关注</div>
                        </div>
                        <div style="text-align: center">
                          <div>{{item.publishArticleNum}}</div>
                          <div>文章</div>
                        </div>
                        <div style="text-align: center">
                          <div>{{item.publishArticleUpNum}}</div>
                          <div>阅读量</div>
                        </div>
                      </div>
                      <el-divider></el-divider>
                      <div style="text-align: center">
                        <el-button style="width: 108px; height: 38px; border-radius: 20px; color: #2970FF; border: 1px solid #2970FF" @click="toPayFriends(item.uuid)">+ 关注</el-button>
                      </div>
                    </div>
                </div>
              <div v-else>
                <NullBox style="text-align: center" data="推荐大V"></NullBox>
              </div>
            </div>

            <div v-if="indexTags === 'recommendF'" style="width: 100%">
                <RecommendFriend></RecommendFriend>
            </div>

            <div v-if="indexTags === 'recommendC'" style="width: 100%">
                <div class="re-body" style="display: flex; flex-wrap: wrap; padding: 16px 0 16px 20px">
                    <div class="rec-box" v-for="item in testData" :key="item" :value="item">
                        <div style="width: 272px; height: 152px">
                            <img src="../../assets/imgs/circle/circleTest3.png" style="width: 272px"/>
                            <div style="padding: 10px 16px">
                                <div style="font-size: 16px; width: 100%; margin-bottom: 6px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">新疆新土地城乡规划设计院</div>
                                <div style="color: #999999; font-size: 14px; margin-bottom: 16px"><span style="margin-right: 20px">53关注</span><span>5523文章</span></div>
                                <el-button style="width: 240px; height: 32px; font-weight: 500; background: #E7F3FF; border-radius: 5px; color: #2970FF; line-height: 0;">关注收藏</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="indexTags === 'recommendS'" style="width: 100%">
                <div class="re-body" style="display: flex; flex-wrap: wrap; padding: 16px 0 16px 20px">
                    <div class="rec-box" v-for="(item, index) in hotShopList" :key="index">
                        <div style="width: 272px; height: 152px">
                            <img class="hand op" :src="item.backgroundImg.img_url" @click="$router.push({path:'/shop/shopCode',query:{id:item.id}})" style="width: 100%; height: 100%"/>
                            <div style="padding: 4px 16px">
                                <div style="height: 66px;" class="hand op" @click="$router.push({path:'/shop/shopCode',query:{id:item.id}})">
                                    <div style="font-size: 16px; width: 100%; margin-bottom: 6px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{item.name}}</div>
                                    <div style="color: #999999; font-size: 14px; margin-bottom: 16px" v-if="item.city.cityName">{{item.city.cityName[0]}}{{item.city.cityName[1]}}{{item.city.cityName[2]}} {{item.city.address}}</div>
                                </div>

                                <el-button @click="followShop(item.id)" style="width: 240px; height: 32px; font-weight: 500; background: #E7F3FF; border-radius: 5px; color: #2970FF; line-height: 0;">关注收藏</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import RecommendFriend from "./components/recommend/recommendFriend";
    import NullBox from "@/pages/circle/components/NullBox.vue";
    export default {
        name: "recommend",
        components: {NullBox, RecommendFriend},
        computed: {
            user() {
                return this.$store.state.user
            }
        },
        data() {
            return {
                testData: [1,2,3,4,5,6,7,8,9],
                // 标签
                indexTags: 'hotArticle',

                hotArticleList: [],  // 文章
                hotVList: [], // 大V
                hotShopList: [], // 店铺

                page: 1,
                more: 1,
                pageSize: 10,
                loading: false,
            }
        },

        updated() {
            var that = this;
            window.onscroll = function(){
                that.topLong = window.scrollTop;
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                if(scrollHeight - scrollTop - windowHeight <= 0){
                    if (that.more == 1 && that.loading === false) {
                        if (that.indexTags == 'hotArticle') {
                            that.getHotArticle();
                        }
                        if (that.indexTags == 'recommendV') {
                            that.getHotV();
                        }
                        if (that.indexTags == 'recommendS') {
                            that.getHotShop();
                        }
                    }else{
                        console.log("已加载完");
                    }
                }
            }
        },

        mounted() {
            this.toTop();
            this.getHotArticle();
        },

        methods: {
          // 回到顶部
          toTop() {
            document.documentElement.scrollTop = 0
            document.body.scrollTop = 0
          },
          // 点击进入个人空间
          openInfo(uuid) {
            if (!this.utils.toAuth(1)) {
              return false
            }
            this.utils.a("/circle/mySpace/" + uuid)
          },

            // 切换菜单
            changeTags(e) {
                this.indexTags = e;
                this.page = 1;
                this.more = 1;
                this.hotArticleList = [];
                this.friendList = [];
                this.hotVList = [];
                this.hotShopList = []
                if (this.indexTags == 'hotArticle') {
                    this.getHotArticle();
                }
                if (this.indexTags == 'recommendV') {
                    this.getHotV();
                }
                if (this.indexTags == 'recommendS') {
                    this.getHotShop();
                }
            },

            // 获取热门文章
            getHotArticle() {
                this.loading = true;
                this.newApi.circleRecommend({page: this.page, pageSize: this.pageSize}).then(res => {
                  this.loading = false;
                    for (const item of res.data) {
                        this.hotArticleList.push(item)
                    }
                    if (res.data.length < 10) {
                        this.more = 0;
                    } else {
                        this.page ++;
                    }
                })
            },

            // 获取热门文章
            getHotV() {
                this.loading = true;
                this.newApi.getRecommendBigV({page: this.page, pageSize: this.pageSize}).then(res => {
                    this.loading = false;
                    for (const item of res.data) {
                        this.hotVList.push(item)
                    }
                    if (res.data.length < 10) {
                        this.more = 0;
                    } else {
                        this.page ++;
                    }
                })
            },

            // 获取热门文章
            getHotShop() {
                this.loading = true;
                this.newApi.getCircleRecommendShop({page: this.page, pageSize: 20}).then(res => {
                    this.loading = false;
                    for (const item of res.data) {
                        this.hotShopList.push(item)
                    }
                    if (res.data.length < 20) {
                        this.more = 0;
                    } else {
                        this.page ++;
                    }
                })
            },

            // 热门文章跳转
            toArticle(item) {
                if (item.bgbType == 2) {
                    this.utils.b('/content/info/' + item.id + '/1');
                }
                if (item.bgbType == 6) {
                    this.utils.b('/content/info/' + item.id + '/2');
                }
            },

            // 点击关注
            toPayFriends(e) {
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false
              }
                    that.newApi.followAccount({uuid: e}).then(res => {
                        if (res.isSuccess == 1) {
                            that.utils.sus(res.data);
                            that.hotVList = [];
                            that.page = 1;
                            that.more = 1;
                            that.changeTags('recommendV')
                        }
                    });
            },

            // 关注、取消关注店铺
            followShop(id) {
                this.newApi.collectShop({shopId: id}).then((res)=>{
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.hotShopList = [];
                        this.page = 1;
                        this.more = 1;
                        this.changeTags('recommendS')
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .inner-container {
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .inner-container::-webkit-scrollbar {
        display: none;
    }

    .tab {
        text-align: center;
        margin: auto 1%;
        float: left;
        text-align: center;
        cursor:pointer;
    }
    .up {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.1s;
    }
    .head_white {
        height: 26px;
        padding: 10px 0;
        width: 100px
    }
    .head_white_word {
        width: 100px;
        height: 26px;
        text-align: center;
        line-height:26px;
        color: #999999;
        font-size: 15px;
        font-weight: 600;
        float: left;
    }
    .head_white_word_act {
        width: 100px;
        height: 26px;
        text-align: center;
        line-height:26px;
        color: #2970FF;
        font-size: 15px;
        font-weight: 600;
        float: left;
    }

    .re-box {
        width: 230px;
        height: 220px;
        padding: 20px;
        margin: 0 10px 10px 0;
        border: 1px solid #DCDCDC;
        border-radius: 5px 5px 5px 5px;
    }

    .ref-box {
        width: 230px;
        height: 100px;
        border: 1px solid #DCDCDC;
        padding: 20px;
        margin: 0 10px 10px 0;
        border-radius: 5px 5px 5px 5px;
    }

    .rec-box {
        display: flex;
        width: 272px;
        height: 272px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        margin: 0 20px 20px 0;
        border-radius: 5px 5px 5px 5px;
    }

    .re-box .userName{
        font-weight: 600;
        color: #444444;
        font-size: 16px;
        margin-bottom: 2px;
    }

    .circle-recommend .sub_type {
        float: left;
        margin: 10px 16px 0 0;
        padding: 4px 10px;
        border-radius: 5px;
        border: 1px solid #BCD2FF;
    }

    .circle-recommend .sub_type.active {
        color: #FFFFFF;
        background: #FFA500;
        border: 1px solid #FFA500;
        margin: 10px 16px 0 0;
    }

    .circle-recommend .event-form .top {
        text-align: center;
        position: relative;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }

    .circle-recommend .el-divider--horizontal {
        margin: 20px 0;
    }

    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .sc:active{
        transform:scale(0.97);
    }

    /deep/ .event-form .el-dialog__header {
        padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
        padding: 0;
    }

    /*下划线*/
    .el-divider--horizontal {
        margin: 0;
    }

    /*mini按钮*/
    .el-button--mini {
        padding: 4px 14px;
    }
</style>
