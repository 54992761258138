<template>
    <div class="release">
        <div class="input_box fade" v-loading="loading">
            <div v-if="user.realNameStatus !== 2 || !is_login" style="position:absolute;z-index:10;background:rgb(0,0,0,0.2);width:100%;min-height:270px;">
                <div style="overflow:hidden;position:absolute;width: 100%;">
                    <div style="height:30px;line-height:30px;text-align:center;color:#FFF; margin-top:110px;" >
                        实名认证才可发布<el-link type="primary" style="font-weight: 600;" @click="realNameAuth">点击实名认证</el-link>
                    </div>
                </div>
            </div>
            <el-form label-width="100px" label-position="left" v-else>
                <div class="input_box_textarea" style="padding: 0 12px">
                    <div style="display: flex; margin-top: 10px">
                        <div style="margin-right: 20px" v-html="topicName"></div>
                        <el-checkbox v-model="reply_type" :true-label="2" :false-label="1">禁止评论</el-checkbox>
                        <el-checkbox v-if="common_type == 4" v-model="isReprinted" :true-label="2" :false-label="1">禁止转发</el-checkbox>
                    </div>

                    <div class="input_box_title" style="width:98%;">
                        <el-input
                                type="textarea"
                                :rows="8"
                                :maxlength="5000"
                                :placeholder="placeholder"
                                v-model="content"
                                :show-word-limit="true"
                                :minlength="10"
                                @input="content_input"
                                style="margin-top: 10px; display: inline-block">
                        </el-input>
                    </div>

                    <!--艾特好友-->
                    <div class="input_box_textarea" style="margin-top:10px; width: 96%;"  v-if="at_open">
                        <div class="at" style="height: 50px;background:  #FAFAFA;padding: 10px;">
                            <el-tag type="info" style="color:#031F88;margin:2.5px 5px;" v-for="(item,index) in at_user_list" :key="index" size="mini" closable @close="removeAt(index)"> @{{item.friendInfo.nickName}}</el-tag>
                        </div>
                        <!--                 <el-select v-model="at_user_index" filterable :remote-method="get_all_user_list" loading-text="加载中" :remote="true" placeholder="输入或选择用户" @change="add_at_user">
                                            <el-option v-for="(item,index) in all_user_list" :key="index" :label="item.nick_name" :value="index">
                                            </el-option>
                                        </el-select> -->
                        <div style="display:flex; justify-content: space-between; overflow: hidden;height: 40px;line-height: 40px;background:  #FAFAFA;padding:  0 10px; border-top:1px dashed #ccc;">
                            <div style="float: left;">您可以@以下好友</div>
                            <i @click="set_at_open" class="el-icon-caret-top hand" style="font-size: 18px; font-weight: 600; margin-top: 10px"></i>
                        </div>
                        <div style="overflow:auto;margin-top: 10px;">
                            <div v-for="(item,index) in all_user_list" :key="index">
                                <div class="at_user hand op sc " :class="{'active':item.check}" @click="set_at_user(item.id,item.friendInfo.nickName,index)" >
                                    <div style="float: left; width: 30px;height:30px;margin-top: 5px; ">
                                        <img :src="item.friendInfo.headIco + '-80x80'" style="width:100%;height:100%;" />
                                    </div>
                                    <div style="float: left;line-height: 40px;margin: 0 5px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width:50px;">{{item.friendInfo.nickName}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 发布选项展示 -->
                    <div class="input_box_btn_list" style="font-size: 16px; overflow: hidden">
                        <div class="input_box_btn op sc hand" @click="set_upload_img_open" style="position: relative; margin-right: 12px">
                            <div class="input_box_btn_icon" style="margin-top: 4px"><img src="@/assets/imgs/circle/picture.png"/></div>
                            <div class="input_box_btn_name" >图片</div>
                            <el-badge :value="imgs.length" style="position:absolute; z-index:10; left:45px;bottom:5px;" v-if="imgs.length > 0"></el-badge >
                        </div>

                        <div class="input_box_btn op sc hand" @click="set_visibleVideos"  style="position: relative; margin-right: 12px">
                            <div class="input_box_btn_icon" style="margin-top: 4px"><img src="@/assets/imgs/circle/video.png"/></div>
                            <div class="input_box_btn_name" >视频</div>
                            <el-badge :value="videos.length" style="position:absolute; z-index:10; left:45px;bottom:5px;" v-if="videos.length > 0"></el-badge >
                        </div>

                        <div class="input_box_btn op sc hand" @click="set_at_open" v-if="common_type == 4" style=" margin-right: 12px">
                            <div class="input_box_btn_icon" style="margin-top: 4px"><img src="@/assets/imgs/circle/at.png"/></div>
                            <div class="input_box_btn_name">AT</div>
                        </div>

                        <div class="input_box_btn op sc hand" style=" margin-right: 12px" @click="toSetMessage">
                            <el-dropdown :disabled="!(imgs.length == 0 && videos.length == 0 && files.length ==0)" trigger="click" @command="handleCommand" style="height:40px;line-height: 40px;" placement="bottom-start">
                                <div>
                                    <div class="input_box_btn_icon" style="margin-top: 4px"><img src="@/assets/imgs/circle/link.png"/></div>
                                    <div class="input_box_btn_name" style="font-size: 16px; color: #000000">链接</div>
                                    <el-badge value="1" style="position:absolute; z-index:10; left:45px;bottom:5px;" v-if="linkConent"></el-badge >
                                </div>
                                <el-dropdown-menu slot="dropdown">
                                    <div style="overflow: hidden; width: 320px;">
                                        <el-input v-model="linkConent" placeholder="请输入链接地址" style="width: 260px; margin: 0 10px"></el-input>
                                    </div>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div v-if="at_user_list.length == 0" class="input_box_btn op sc hand" @click="toAddNotes" style=" margin-right: 12px">
                            <div class="input_box_btn_icon" style="margin-top: 4px"><img src="@/assets/imgs/circle/notes.png"/></div>
                            <div class="input_box_btn_name">新建笔记</div>
                        </div>

                        <div class="input_box_btn op sc hand" @click="set_visibleFiles" style="position: relative; margin-right: 8px">
                            <div class="input_box_btn_icon" style="margin-top: 4px"><img src="@/assets/imgs/circle/files.png"/></div>
                            <div class="input_box_btn_name" style="">附件</div>
                            <el-badge :value="files.length" style="position:absolute; z-index:10; left:45px;bottom:5px;" v-if="files.length > 0"></el-badge >
                        </div>

                        <div v-if="common_type == 4 && topicOpen == true" class="input_box_btn op sc hand" style="position: relative; margin-right: 12px">
                            <el-dropdown trigger="click"  @command="handleCommand" style="height:40px;line-height: 40px;">
                                <div >
                                    <div class="input_box_btn_icon" style="margin-top: 2px;"><svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" viewBox="0 0 448 512" width="20" height="18" style="fill: rgb(90, 111, 230);"><path d="M440.667 182.109l7.143-40c1.313-7.355-4.342-14.109-11.813-14.109h-74.81l14.623-81.891C377.123 38.754 371.468 32 363.997 32h-40.632a12 12 0 0 0-11.813 9.891L296.175 128H197.54l14.623-81.891C213.477 38.754 207.822 32 200.35 32h-40.632a12 12 0 0 0-11.813 9.891L132.528 128H53.432a12 12 0 0 0-11.813 9.891l-7.143 40C33.163 185.246 38.818 192 46.289 192h74.81L98.242 320H19.146a12 12 0 0 0-11.813 9.891l-7.143 40C-1.123 377.246 4.532 384 12.003 384h74.81L72.19 465.891C70.877 473.246 76.532 480 84.003 480h40.632a12 12 0 0 0 11.813-9.891L151.826 384h98.634l-14.623 81.891C234.523 473.246 240.178 480 247.65 480h40.632a12 12 0 0 0 11.813-9.891L315.472 384h79.096a12 12 0 0 0 11.813-9.891l7.143-40c1.313-7.355-4.342-14.109-11.813-14.109h-74.81l22.857-128h79.096a12 12 0 0 0 11.813-9.891zM261.889 320h-98.634l22.857-128h98.634l-22.857 128z"></path></svg></div>
                                    <div class="input_box_btn_name" style="font-size: 16px;color: #000000;">话题</div>
                                </div>
                                <el-dropdown-menu slot="dropdown" >
                                    <div style="overflow: hidden; width: 270px;">
                                        <div style="overflow-y: scroll;width: 280px;height:400px;">
                                            <el-input v-model="topic_like" style="width:90%; padding: 0 5%;" placeholder="请选择或搜索所属话题" @input="search_topic"></el-input>
                                            <el-dropdown-item  :command="item" v-for="item in top_topic_list " :key= "item.id">
                                                <div style="overflow: hidden;">
                                                    <div style="float: left;">#{{item.name}}#</div>
                                                    <div style="float: right;">{{item.clickcount}}</div>
                                                </div>
                                            </el-dropdown-item>
                                        </div>
                                    </div>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>

                        <div style="display: flex; margin-bottom: 10px; float: right; overflow:hidden; margin-right: 14px">
                            <el-dropdown @command="change_common_type" style="margin-right: 10px; font-size: 15px; font-weight: 600">
                                <div>{{common_type_name[common_type]}}<i v-if="at_open == false && at_user_list.length <= 0" class="el-icon-arrow-down" style="font-weight: 600; margin: 0 10px"></i></div>
                                <el-dropdown-menu slot="dropdown" v-if="at_open == false && at_user_list.length <= 0">
                                  <el-dropdown-item command="4"><i class="el-icon-lock" style="color: #6654D9" ></i>全部</el-dropdown-item>
                                    <el-dropdown-item command="3"><i class="el-icon-bangzhu" style="color:#E51C23"></i>仅粉丝</el-dropdown-item>
                                  <el-dropdown-item command="2"><i class="el-icon-user" style="color: #FF9800;" ></i>仅好友</el-dropdown-item>
                                  <el-dropdown-item command="1"><i class="el-icon-view" style="color: #5A6FE6;"></i>仅自己</el-dropdown-item>

                                </el-dropdown-menu>
                            </el-dropdown>
                            <div class="hand op sc" style="float: left;">
                                <el-button type="primary" @click="add" style="width: 80px; height: 32px; background-color: #649DFD;border-color:#649DFD; font: 15px Microsoft YaHei; line-height: 1px">发布{{status}}</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>

        <!--发布知识仓库格式图片-->
        <div v-if="menuImagesShow == true">
            <menu-image :images="imgs" :is-show="menuImagesShow"  @custom-event="imageCustomEvent" :on-close="closeImageModule"></menu-image>
        </div>

        <!--发布知识仓库格式视频-->
        <el-dialog :close-on-click-modal="false" width="482px" :visible.sync="menuVideoShow" :show-close=false class="upload_att">
            <menu-video :video="videos"  @custom-event="closeViodeModule"></menu-video>
        </el-dialog>

        <!--发布知识仓库格式附件-->
        <el-dialog :close-on-click-modal="false" width="730px" :visible.sync="menuEnclosureShow"  :show-close=false >
            <menu-enclosure :data="files"  @custom-event="closeEnclosureModule"></menu-enclosure>
        </el-dialog>
    </div>
</template>

<script>
    import menuImage from "./menuModule/menuImage";
    import menuVideo from "./menuModule/menuVideo";
    import menuEnclosure from "./menuModule/menuEnclosure";
    export default {
        name: "Release",
        components: {
            menuImage,
            menuVideo,
            menuEnclosure
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },
        props:{
            placeholder:{
                type:String,
                default() {
                    return "记录每时每刻的灵感与思考"
                }
            },
            topicOpen:{
                type:Boolean,
                default() {
                    return true
                }
            },
            paramsTopicId:{
                type:String,
                default() {
                    return "0"
                }
            },

        },
        data() {
            return {
                loading:false,
                imgs:[],
                videos:[],
                files:[],
                topic_like: "",
                content: "",
                common_type_name:{
                    "1":"仅自己",
                    "2":"仅好友",
                    "3":"仅粉丝",
                    "4":"全部"
                },
                common_type:"4",
                reply_type:1,
                isReprinted:1,
                status:"",
                at_user_list: [],
                all_user_list:[],

                at_open: false,
                topic_str:"",
                linkConent: '',
                top_topic_list:[],
                topicName:"",
                topicId:parseInt(this.paramsTopicId),
                menuImagesShow : false,
                menuVideoShow:false,
                menuEnclosureShow:false,
            }
        },
        mounted() {
          if (this.is_login) {
            this.getFriendGroups(); //获取好友
            this.getTopTopicList();  //话题列表
          }
        },
        methods: {
          // 认证
          toAddNotes() {
            if (!this.utils.toAuth(1)) {
              return false
            }
            this.utils.a('/content/add/' + 0)
          },

            // 设置链接禁用提示
            toSetMessage() {
              if (!(this.imgs.length == 0 && this.videos.length == 0 && this.files.length ==0)) {
                this.utils.msg('此类型禁止添加链接')
              }
            },

            //关闭添加图片组件
            closeImageModule(){
                this.menuImagesShow = false;
            },
            //接受图片组件的值
            imageCustomEvent(data){
                console.log(data.images);
                this.imgs = data.images;
            },
            //关闭添加视频组件
            closeViodeModule(data) {
                console.log(data.videos);
                this.menuVideoShow = false;
                this.videos = data.videos;
            },
            //关闭添加附件组件
            closeEnclosureModule(data){
                console.log(data.files);
                this.menuEnclosureShow = false;
                this.files = data.files;
            },

            realNameAuth(){
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false
              }
                this.$router.push({path: '/auth'});
            },
            //获取话题列表
            getTopTopicList:function(){
                var that = this;
                that.newApi.getTopTopicList({}).then((ret)=>{
                    if(ret.isSuccess == 1){
                        that.top_topic_list = ret.data;
                    }
                }).catch((err) => {
                    console.log(err);
                })
            },

            //话题搜索及显示
            search_topic(){
                var that = this;
                if (that.topic_like !="") {
                    let param = {};
                    param.like = that.topic_like;
                    that.newApi.getTopTopicList(param).then((ret)=>{
                        if(ret.isSuccess == 1){
                            that.top_topic_list = ret.data;
                        }
                    }).catch((err) => {
                        console.log(err);
                    })
                }else{
                    that.getTopTopicList();
                }
            },

            handleCommand(obj) {
                this.topicName = "#" + obj.name + "#";
                this.topicId = obj.id;
            },

            // 点上传图片
            set_upload_img_open() {
              if (this.linkConent) {
                this.utils.msg('图片类型禁止携带链接')
                return false;
              }
              if(this.videos.length > 0 || this.files.length > 0){
                this.utils.err("图片、视频、文件只能上传一种");
                return false;
              }
              this.menuImagesShow = true;
            },
            // 点上传视频
            set_visibleVideos(){
              if (this.linkConent) {
                this.utils.msg('视频类型禁止携带链接')
                return false;
              }
              if(this.imgs.length > 0 || this.files.length > 0){
                this.utils.err("图片、视频、文件只能上传一种");
                return false;
              }
              this.menuVideoShow = true;
            },
            // 点击上传附件
            set_visibleFiles() {
              if (this.linkConent) {
                this.utils.msg('附件类型禁止携带链接')
                return false;
              }
              if(this.imgs.length > 0 || this.videos.length > 0){
                this.utils.err("图片、视频、文件只能上传一种");
                return false;
              }
              this.menuEnclosureShow = true;
            },

            // 艾特好友展示
            set_at_open() {
                this.at_open = !this.at_open;
                this.common_type = "4";

            },

            // 获取好友
            getFriendGroups() {
                this.all_user_list = [];
                this.newApi.getMyFriendGroupList({}).then(res =>{
                    for (const item of res.data) {
                        for (const friends of item.friendArr) {
                            this.all_user_list.push(friends)
                        }
                    }
                    for (var i = 0; i < this.all_user_list.length; i++) {
                        this.all_user_list[i]['num'] = i
                    }
                })
            },

            // 移除好友
            removeAt(index) {
                var aindex = this.at_user_list[index].num ;
                this.all_user_list[aindex].check = false;
                this.at_user_list.splice(index,1);
            },

            // 配置用户
            set_at_user(id,nickName,index){
                if (this.all_user_list[index].check) {
                    this.all_user_list[index].check = false;
                    for (var i = 0; i < this.at_user_list.length; i++) {
                        if (this.at_user_list[i].num ==index) {
                            this.at_user_list.splice(i,1);
                        }
                    }
                }else{
                    this.all_user_list[index].check = true;
                    this.at_user_list.push(this.all_user_list[index]);
                }
            },

            // 富文本输入
            content_input(e) {
                var string = e;
                string = string.replace(/<p>/g, "").replace(/<\/p>/g, "");
                string = string.charAt(string.length - 1);
                if (string == "@") {
                    this.at_open = true;
                    this.common_type = "4";
                }
            },

            // 发布权限
            change_common_type(command) {
                this.common_type = command;
                //只有全部才有话题
                if(this.common_type != 4){
                    this.topicName = "";
                    this.topicId = 0;
                    this.isReprinted = 2
                } else {
                  this.isReprinted = 1
                }

            },

            // 发布
            add() {
                if (!this.utils.toAuth(1)) {
                  return false
                }
                var params = {};
                var that = this;
                params.topicId = that.topicId;
                params.content = that.content;

                // 如果输入的文本只包含空格和回车，则返回
                if (/^\s*$/.test(that.content)) {
                    that.utils.err("请填写动态文字描述");
                    return false;
                }

                params.auth = this.common_type;
                if(that.imgs.length == 0 && that.videos.length == 0 && that.files.length == 0 && that.content != ""){
                    params.type = 1;
                }
                if(that.imgs.length > 0){
                    params.type = 3;
                }else if(that.videos.length > 0){
                    params.type = 4;
                }else if(that.files.length > 0){
                    params.type = 5;
                }
                params.isComment = that.reply_type;
                params.isReprinted = that.isReprinted;
                params.operationType = 1;
                that.loading = true;

                that.inspirationAddOrSave(params);

            },

            inspirationAddOrSave(params){
                var that = this;
                let friends = [];
                for (const at of that.at_user_list) {
                    friends.push(at.friendInfo.uuid)
                }
                params.AT = JSON.stringify(friends);
                params.link = that.linkConent;
                params.images = JSON.stringify(that.imgs);
                params.videos = JSON.stringify(that.videos);
                params.enclosure = JSON.stringify(that.files);
                this.newApi.inspirationAddOrSave(params).then((res) => {
                    if (res.isSuccess == 1) {
                        that.utils.sus(res.data);
                        that.loading = false;
                        that.topicName = "";
                        that.content = "";
                        that.imgs = [];
                        that.videos = [];
                        that.files = [];
                        that.at_user_list = [];
                        that.all_user_list = [];
                        that.linkConent = "";
                        that.reply_type = "1";
                        that.isReprinted = "1";
                        that.common_type = "4";
                        that.at_open = false;
                        that.getFriendGroups();
                        this.$EventBus.$emit("changeData", {changeData: 1});
                    }
                })
            }

        }
    }

</script>

<style scoped>

    /deep/ .el-dialog__header{
        padding: 0;
    }

    /deep/ .el-dialog__body{
        padding: 0;
    }

    /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
        color: #fff;
        background-color: #031F88;
    }

    /deep/ .el-tabs__item {
        width: 199px;
        text-align: center;
        border-bottom: #031F88 1px solid !important;
    }

    .input_box_btn{
        float:left; overflow:hidden;padding-right: 10px;
    }
    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;transition:all .1s;opacity:.6;
    }
    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .sc:active{
        transform:scale(0.97);
    }
    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        /*margin-top: 5px;*/
        /*margin-right: 7px;*/
    }
    .input_box_btn_icon{
        float:left; font-size:20px;line-height:38px; margin-right:5px;
    }
    .input_box_btn_name{
        float:left;
    }
    .at_user{
        float: left;margin: 2.5px 5px;width: 90px;height: 40px;overflow: hidden;padding: 0px 5px;
    }
    .at_user.active{
        float: left;margin: 2.5px 5px;width: 90px;height: 40px;background: #C7E0F4;overflow: hidden;padding: 0px 5px;
    }
    .input_box{
        background:#FFF;
        position: relative;
        margin-bottom: 10px;
        min-height: 260px;
    }
    .input_box.active{
        background:#FFF; position: relative;box-shadow: 1px 1px 5px rgba(0,0,0,.3);
    }
    .input_box_title{
        margin:0 0 10px 0;
    }
    .input_box_textarea{
        width:99%;
    }
    .fade{
        animation:fadenum 0.3s infinite;
        animation-iteration-count:1;
    }
    .input_box_btn_list{
        overflow:hidden; height:40px;line-height:40px;
    }
    /deep/ .el-popper {
        z-index: 9 !important;
    }

    /deep/ .el-textarea__inner {
        min-height: 200px !important;
        background: #F0F0F5;
    }
    /*.el-dropdown-menu{*/
    /*    position: absolute !important;*/
    /*    left: 800px !important;*/
    /*    top: 309px !important;*/
    /*}*/

    textarea {
        background-color: #8a8a8a !important;
    }
    .file_style {
        display: flex;justify-content: center;margin-top:2px;
    }
    .format {
        height: 16px;color: #999999;font-size: 14px;
        line-height: 0;
    }
    .folder {
        margin-left: 47px;
    }
    .content_type_folder {
        display: flex;
    }
    .el-input-group {
        height: 20px;
    }
   /deep/ .el-input-group__prepend{
        color: #555555 !important;
        background-color: #FFFFFF;
    }
    /deep/ .dragger-file {
      width: 600px !important;
    }
   /deep/  .dragger-file .upload-demo .el-upload-dragger {
     width: 600px !important;
   }
   /deep/ .el-dialog__body{
       padding: 0px;
   }

   /* .background {
    background: #EBEBEB;
    border-radius: 0px 0px 0px 0px;
    width:100%;
    display:flex;
   } */

</style>
