<template>
    <div class="circle-activity" style="display: flex;">
        <div style="width: 900px">
            <div class="activity-home" v-if="pageType === 'home'">
                <div style="width: 100%; overflow: hidden; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
                    <div style="display: flex; justify-content: space-between; width: 100%; height: 46px;">
                        <div style="font-size: 18px; font-weight: 600; padding: 8px 0 0 34px">我创建的活动</div>
                        <div style="display: flex">
                          <div @click="upShowActivity" class="hand op" style="border-radius: 5px; width: 100px; height: 30px; margin:8px 30px; background-color: #1B74E4; text-align: center; line-height: 30px; color: #FFFFFF">+ 创建活动</div>
                          <div @click="toAllEvent" class="hand op sc" style="color: #2970FF; font-weight: 500; font-size: 14px; margin-right: 20px; padding: 12px 0">我的活动<i class="el-icon-arrow-right" style="font-weight: 600"></i></div>
                        </div>
                    </div>
                    <el-divider></el-divider>
                    <div v-if="myEventList.myCreat.length === 0">
                        <NullBox data="我的活动事件"></NullBox>
                    </div>
                    <div v-else>
                        <div v-for="(item, index) in myEventList.myCreat.slice(0, 3)" :key="index">
                            <div style="display: flex; justify-content: space-between; height: 126px; padding: 16px 34px">
                                <div style="display: flex">
                                    <img style="width: 178px; height: 118px; background: #FFFFFF; border-radius: 8px;" :src="item.cover">
                                    <div style="margin-left: 10px; font-size: 16px">
                                        <div style="margin-bottom: 8px">
                                            <span style="color: #999999">活动时间：</span>
                                            <span style="color: #2970FF">{{item.activityTime}}</span>
                                        </div>
                                        <div style="margin-bottom: 8px">
                                            <span style="color: #999999">活动名称：</span>
                                            <span style="font-size: 16px">{{item.title}}</span>
                                        </div>
                                        <div style="margin-bottom: 8px">
                                            <span style="color: #999999">活动地点：</span>
                                            <span style="font-size: 16px">{{item.location}}</span>
                                        </div>
                                        <div v-if="item.status == 2">
                                            <span style="color: #999999">活动状态：</span>
                                            <span v-if="Date.now() > Date.parse(item.endTime)" style="color: red;">活动结束 {{item.signNum}}人报名</span>
                                            <span v-if="Date.now() < Date.parse(item.endTime)">正在报名 {{item.signNum}}人报名</span>
                                        </div>
                                        <div v-if="item.status == 3">
                                            <span style="color: #999999">活动状态：</span>
                                            <span style="color: red;">活动已取消</span>
                                        </div>
                                    </div>
                                </div>
                                <div @click="toEvents(item.id)" class="hand op" style="margin: 5% 10px; width: 100px; height: 20px; border-radius: 5px; background-color: #E4E6EB; padding: 6px 0; text-align: center">查看详情 ></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="width: 100%; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-top: 10px">
                    <div style="display: flex; justify-content: space-between; width: 100%; height: 46px; line-height: 46px;">
                        <div style="width: 80%; font-size: 18px; font-weight: 600; padding: 0 0 0 34px">发现活动</div>
                    </div>
                    <el-divider></el-divider>
                    <div v-if="otherEventList.length === 0">
                        <NullBox data="发现的活动事件"></NullBox>
                    </div>
                    <div v-else style=" padding: 16px 20px 16px 30px">
<!--                        <div style="display: flex; margin-bottom: 17px">-->
<!--                            <el-button style="border-radius: 20px 20px 20px 20px; background-color: #E4E6EB; margin-right: 18px; line-height: 8px; font-size: 16px; width: 70px; height: 30px">热门</el-button>-->
<!--                            <el-button style="border-radius: 20px 20px 20px 20px; background-color: #E4E6EB; margin-right: 18px; line-height: 8px; font-size: 16px; width: 70px; height: 30px">本周</el-button>-->
<!--                            <el-button style="border-radius: 20px 20px 20px 20px; background-color: #E4E6EB; margin-right: 18px; line-height: 8px; font-size: 16px; width: 70px; height: 30px">课程</el-button>-->
<!--                            <el-button style="border-radius: 20px 20px 20px 20px; background-color: #E4E6EB; margin-right: 18px;  line-height: 8px; font-size: 16px; width: 70px; height: 30px">好友</el-button>-->
<!--                        </div>-->
                        <div style="display: flex; flex-wrap: wrap">
                            <div style="width: 270px; height: 310px ;border-radius: 8px 8px 8px 8px; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-bottom: 10px; margin-right: 10px" v-for="(item, index) in otherEventList" :key="index">
                                <img :src="item.cover" style="width: 100%; height: 130px; background-color: #FFFFFF; border-radius: 8px 8px 0 0; margin-bottom: 14px" class="hand op sc">
                                <div style="width: 270px; margin-left: 10px; font-size: 16px;">
                                    <div style="margin-bottom: 6px">
                                        <span style="color: #999999">活动时间：</span>
                                        <span style="color: #2970FF">{{item.activityTime}}</span>
                                    </div>
                                    <div style="width: 90%; margin-bottom: 6px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                        <span style="color: #999999">活动名称：</span>
                                        <span style="font-size: 16px">{{item.title}}</span>
                                    </div>
                                    <div style="width: 90%; margin-bottom: 6px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">
                                        <span style="color: #999999">活动地点：</span>
                                        <span style="font-size: 16px">{{item.location}}</span>
                                    </div>
                                    <div >
                                        <span style="color: #999999">活动状态：</span>
                                        <span v-if="Date.now() > Date.parse(item.endTime)" style="color: red;">活动结束 {{item.signNum}}人报名</span>
                                        <span v-if="Date.now() < Date.parse(item.endTime)">正在报名 {{item.signNum}}人报名</span>
                                    </div>

                                </div>
                                <div style="display: flex; justify-content: center">
                                    <div class="hand op" style="width: 182px; height: 19px; background-color: #E4E6EB; border-radius: 5px 5px 5px 5px; padding: 7px 0; text-align: center; margin: 14px 10px">
                                        <div style="margin-right: 6px" @click="toEvents(item.id)">查看详情</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="activity-details" v-if="pageType === 'details'">
                <div style="width: 100%; overflow: hidden; background: #FFFFFF; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
                    <div style="display: flex; width: 100%; height: 46px;">
                        <div class="hand op sc" style="font-weight: 600; font-size: 18px; padding: 8px 0; margin-left: 22px" @click="onHome"><i class="el-icon-arrow-left" style="font-weight: 600"></i>返回</div>
                        <div style="font-size: 20px; font-weight: 600; padding: 8px 0 0 34px; margin-left: 30%">当前活动</div>
                    </div>
                    <el-divider></el-divider>
                    <AllView :myEventList="myEventList"></AllView>
                </div>
            </div>

            <div class="activity-details" v-if="pageType === 'activeInfo'">
                <SignView :key="activeId" :activeId="activeId"></SignView>
            </div>
        </div>
        <div style="width: 300px; overflow: hidden; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600); margin-left: 10px;">
            <RoomEvent></RoomEvent>
        </div>


        <!--报名弹窗-->
        <el-dialog :close-on-click-modal="false" :visible.sync="upShow" width="980px" :show-close="false" class="event-form" v-if="upShow == true">
            <div class="top">活动报名
                <img src="../../assets/imgs/circle/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="upShow = false">
            </div>
            <div style="padding: 20px 40px">
                <SignForm @closeEvent="closeEvent"></SignForm>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import AllView from "./event/allView";
    import SignView from "@/components/common/signUp/signView.vue";
    import SignForm from "@/components/common/signUp/SignForm.vue";
    import RoomEvent from "./components/hot/RoomEvents";
    import NullBox from "./components/NullBox";
    export default {
        name: "activity",
        components: {NullBox, RoomEvent, SignForm, SignView, AllView},
        data() {
            return {
                pageType: 'home',

                testData: [1,2,3],
                testInfo: [1,2,3,4,5,6],
                // 标签
                indexTags: 'hotTopic',

                interest: '',
                visibility: '',

                myEventList: {
                    myCreat: []
                },

                otherEventList: [],

                activeId: 0,
                upShow: false,
            }
        },
        computed: {
          user() {
            return this.$store.state.user
          },
          is_login() {
            return this.$store.state.is_login
          }
        },

      created() {
          if (this.$route.query.activeId) {
            this.pageType = 'activeInfo';
              this.activeId = parseInt(this.$route.query.activeId);
          }
      },

      mounted() {
            this.getMyEventList();
            this.getOtherEventList()
        },

        methods: {
            // 创建活动报名弹窗
            upShowActivity() {
              var that = this;
              if(that.user.userType == 3){
                  that.utils.err("子账号暂无发布权限");
                  return false;
              }
              if (!this.utils.toAuth(1)) {
                return false
              }
              this.upShow = true
            },

            // 查看room事件详情
            toEvents(id) {
                if (id) {
                    this.pageType = 'activeInfo';
                    this.activeId = id;
                } else {
                    this.pageType = 'home'
                }
            },

            // 关闭事件弹窗
            closeEvent() {
                this.pageType = 'home';
                this.upShow = false;
                this.getMyEventList();
                setTimeout(() => {this.getOtherEventList();}, 1000)
            },

            // 刷新事件
            refEvent() {
                this.getMyEventList();

            },

            // 获取我的报名事件
            getMyEventList() {
                this.newApi.getMyEnrollEventList().then(res => {
                    this.myEventList = res.data
                })
            },

            // 获取推荐活动
            getOtherEventList() {
                this.newApi.getRoomEnrollEventList({roomId: -1, status: 2, type: 1}).then(res => {
                    this.otherEventList = res.data
                })
            },

            toAllEvent() {
                this.pageType = 'details'
            },

            // 点击返回活动主页
            onHome() {
                this.pageType = 'home'
            },

        }
    }
</script>

<style scoped>
    /*下划线*/
    .el-divider--horizontal {
        margin: 0;
    }

    .el-radio-group {
        font-size: 14px;
    }

    .circle-activity .content>>>img {
        width: 100%;
    }

    .event-form .top {
        text-align: center;
        position: relative;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }

    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .sc:active{
        transform:scale(0.97);
    }

    /deep/.event-form .el-dialog__header {
        padding: 0;
    }

    /deep/.event-form .el-dialog__body {
        padding: 0;
    }

</style>
