<template>
    <div class="hot-article">
        <div style="width: calc(100% - 20px);padding:0 10px;background: #FFFFFF;border-radius: 0;">
            <div class="module_title">
                <div class="industry_title_text">热门文章</div>
                <div style="color: #999999; padding-top: 18px" class="hand op sc" @click="moreData('recommend')">MORE ></div>
            </div>
            <div style="padding: 10px 10px" v-if="hotArticleList.length !== 0">
                <div style="display: flex; margin-bottom: 18px" class="hand op" v-for="(item, index) in hotArticleList" :key="index" @click="toArticle(item)">
                    <div>
                        <div style="width: 160px; font-size: 14px;font-weight: 600; margin-bottom: 7px;text-overflow:ellipsis;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;">{{item.title}}</div>
                        <div style="width: 160px; color: #999999; font-size: 13px;">{{item.createTime }}</div>
                    </div>
                    <div class="testPic" style="margin-left: 6px"><img style="width: 90px; height: 65px;" :src="item.cover"/></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "HotArticle",
    components: {},
    data() {
        return {
            hotArticleList: [],
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.getHotArticle(); // 热门文章
    },
    methods: {
        // 获取热门文章
        getHotArticle() {
            this.newApi.circleRecommend({page: 1, pageSize: 5}).then(res => {
                this.hotArticleList = res.data;
            })
        },

        // 热门文章跳转
        toArticle(item) {
            if (item.bgbType == 2) {
                this.utils.b('/content/info/' + item.id + '/1');
            }
            if (item.bgbType == 6) {
                this.utils.b('/content/info/' + item.id + '/2');
            }
        },

        // 点击更多
        moreData(name) {
            if (!this.utils.toAuth(1)) {
              return false
            }
            this.$parent.toMore(name)
        },
    }
}
</script>

<style scoped>
.hot-article .module_title{
    width:100%;height:50px;display: flex; justify-content: space-between;
}
.hot-article .industry_title_text{
    line-height: 50px;margin-left: 10px;color:#031F88;font-size: 18px;font-weight: bold;
}
</style>
