<template>
    <div class="recommend-friend">
        <div class="v-title" style="display: flex; justify-content: space-between; padding: 20px" v-if="type === 0">
            <div>
                <div style="font-weight: 600; font-size: 16px">添加专业领域将会为您精准推荐相关领域好友<span style="color: #999999; font-size: 14px">（没有选择专业将进行默认推荐）</span></div>
                <div style="display: flex; flex-wrap: wrap; margin-top: 10px">
                    <el-tag v-for="(item, index) in selectList" :key="index" style="margin-right: 20px; margin-bottom: 10px; color: #2970FF; border-color: #2970FF; font-size: 14px" color="#fff" size="small">{{item.name}}</el-tag>
                </div>
            </div>
            <el-button @click="toChangeMajor" style="color: #fff; background-color: #2970FF;width: 112px; height: 32px;line-height: 0" size="small" round>添加专业领域</el-button>
        </div>
        <el-divider v-if="type === 0"></el-divider>
        <div class="re-body" style="display: flex; flex-wrap: wrap; padding: 16px 10px 16px 32px">
            <div class="ref-box" v-for="(item, index) in friendList" :key="index">
                <div style="display: flex;" class="hand op" @click="openInfo(item.uuid)">
                    <div style="margin-right: 12px">
                        <img :src="item.headIco" style="width: 50px; height: 50px; border-radius: 50%"/>
                    </div>
                    <div class="major" style="margin-top: 2px">
                        <div class="userName" style="display: flex">
                            <div style="max-width: 120px;text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{item.nickName}}</div>
                            <img v-if="item.isVip === 1" src="../../../../assets/imgs/circle/newVip.png" style="width: 18px;height: 16px;margin-left: 4px;"/>
                            <img v-if="item.v === 1" src="../../../../assets/imgs/circle/newV.png" style="width: 16px;height: 16px;margin-left: 4px;"/>
                        </div>
                        <div style="max-width: 180px; color: #AAAAAA;text-overflow: ellipsis; white-space: nowrap; overflow: hidden; margin-top: 4px" v-if="JSON.parse(item.address).cityName">{{JSON.parse(item.address).cityName[1]}}</div>
                    </div>
                </div>
                <div style="text-align: center; margin-top: 16px">
                    <el-button v-if="item.isFans === 0" style="width: 188px; height: 38px; background: #E7F3FF; border-radius: 5px; color: #2970FF; line-height: 0;" @click="toPayFriends(item.uuid)">+ 关注</el-button>
                    <el-button v-if="item.isFans === 1" style="width: 188px; height: 38px; background: #E7F3FF; border-radius: 5px; color: #2970FF; line-height: 0;" @click="toAddFriends(item.uuid)">加为好友</el-button>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="showTags" width="43%" :show-close="false" class="event-form">
            <div class="top">
                添加专业领域
                <img src="../../../../assets/imgs/circle/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showTags = false">
            </div>
            <div style="padding: 10px 30px 30px 30px; position: relative">
              <div style="position: absolute; right: 30px; top: 10px"><el-checkbox @change="toAllChange" v-model="allCheck">全选</el-checkbox></div>
                <div style="width: 760px;">
                    <div class="sub_types">
                        <div style="margin: 12px 20px" v-for="(sub,subindex) in typeList" :key="subindex">
                            <div style="color: #2970FF; font-weight: 600">{{sub.label}}</div>
                            <div style="overflow:auto;">
                                <div v-for="(tag,tagIndex) in sub.children" :key="tagIndex">
                                    <div class="sub_type hand op sc" :class="{'active':tag.check}"
                                         @click="set_type_id(tag.value,tag.label,subindex,tagIndex)">
                                        {{ tag.label }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 20px;">
                        <div style="width: 80%; display: flex; justify-content: center">
                            <div style="margin: 10px 20px 0 0">当前已选择<span style="color: #2970FF">{{selectList.length}}</span>个标签</div>
                            <el-button style="width: 188px; height: 38px; background: #2970FF; border-radius: 5px; color: #FFFFFF; line-height: 0;" @click="addTags">确认添加</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: "recommendFriend",
    components: {},
    data() {
        return {
            showTags: false,
            typeList: [],
            selectList: [],

            friendList: [],
            page: 1,
            more: 1,
            pageSize: 30,
            loading: false,

          allCheck:false,
        }
    },
    props: {
        type: {
            type: Number,
            default() {
                return 0
            },
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    updated() {
        var that = this;
        window.onscroll = function(){
            var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            //变量windowHeight是可视区的高度
            var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            //变量scrollHeight是滚动条的总高度
            var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            if(scrollHeight - scrollTop - windowHeight <= 0){
                if (that.more == 1 && that.loading === false) {
                    that.getRecommendFriendList();
                }else{
                    console.log("已加载完");
                }
            }
        }
    },
    mounted: function () {
        this.getRecommendFriendList();
        this.getFriendRecommendList()
    },
    methods: {
        // 点击打开用户信息
        openInfo(uuid) {
          if (!this.utils.toAuth(1)) {
            return false
          }
          this.$router.push({ path: '/circle/mySpace/' + uuid})
        },

        // 获取推荐
        getFriendRecommendList() {
          this.newApi.getUserRecommendType({type: 'friend'}).then(res => {
            this.selectList = res.data;
            this.getTypeList();
          })
        },

        set_type_id: function (id, name, subIndex, tagIndex) {
            var type_options = this.typeList;
            if (type_options[subIndex].children[tagIndex].check == null) {
                type_options[subIndex].children[tagIndex].check = true;
            } else {
                type_options[subIndex].children[tagIndex].check = !type_options[subIndex].children[tagIndex].check;
            }
            this.typeList = type_options;
            this.$set(this.typeList, subIndex, type_options[subIndex]);
            this.check_types_ok()
        },

        toAllChange(e) {
            if (e === true) {
              for (const i of this.typeList) {
                for (const k of i.children) {
                  k.check = true
                }
              }
            } else {
              for (const i of this.typeList) {
                for (const k of i.children) {
                  k.check = false
                }
              }
            }
            this.check_types_ok()
        },

        check_types_ok: function () {
            var typeOptions = this.typeList;
            var industry = [];
            for (const i of typeOptions) {
                for (const k of i.children) {
                    if (k.check) {
                        var obj = {
                            id: k.value,
                            name: k.label
                        };
                        industry.push(obj);
                    }
                }
            }
            this.selectList = industry;
        },

        // 专业领域
        toChangeMajor() {
          var that = this;
          if (!this.utils.toAuth(1)) {
            return false
          }
          if(this.user.authenticationStatus !== 2 ){
            store.commit('setShowAuthPopup', 1);
            return false;
          } else {
            this.showTags = true
          }
        },

        // 回显
        checkNow() {
          var typeOptions = this.typeList;
          for (const i of typeOptions) {
            for (const k of i.children) {
                if (this.selectList.findIndex(item => item.typeId == k.value) !== -1) {
                  k.check = true
                }
            }
          }
        },

        // 获取通用标签列表
        getTypeList: function () {
            var that = this;
            this.newApi.getTypeList({
                lv: 2
            }).then(res => {
                for (const item of res.data) {
                    item.num = 0
                }
                that.typeList = res.data;
                that.checkNow()
            })
        },

        addTags() {
          let params = {};
          params.type = 'friend';
          params.filed = JSON.stringify(this.selectList);
          this.newApi.updateUserRecommendType(params).then(res => {
            if (res.isSuccess === 1) {
              this.showTags = false;
              this.friendList = [];
              this.page = 1;
              this.more = 1;
              this.utils.sus(res.data);
              this.getRecommendFriendList()
            }
          })
        },

        // 获取推荐好友列表
        getRecommendFriendList() {
            this.loading = true;
            this.newApi.getRecommendFriends({page: this.page, pageSize: this.pageSize}).then(res =>{
                this.loading = false;
                if (res.isSuccess == 1) {
                    for (const item of res.data) {
                        this.friendList.push(item)
                    }
                    if (res.data.length < 30) {
                        this.more = 0;
                    } else {
                        this.page ++;
                    }
                    this.loading = false;
                }
            })
        },

        // 点击关注
        toPayFriends(e) {
            var that = this;
          if (!this.utils.toAuth(1)) {
            return false
          }
                that.newApi.followAccount({uuid: e}).then(res => {
                    if (res.isSuccess == 1) {
                        that.utils.sus(res.data);
                        that.friendList = [];
                        that.page = 1;
                        that.more = 1;
                        that.getRecommendFriendList()
                    }
                });

        },

        // 点击加好友
        toAddFriends(id) {
          if(this.user.authenticationStatus !== 2 ){
            store.commit('setShowAuthPopup', 1);
            return false;
          }else {
            this.newApi.addFriends({uuid: id}).then(res => {
              if (res.isSuccess == 1) {
                this.utils.sus(res.data);
                this.friendList = [];
                this.page = 1;
                this.more = 1;
                this.getRecommendFriendList()
              }
            })
          }
        },
    }
}
</script>

<style scoped>
.ref-box {
    width: 230px;
    height: 100px;
    border: 1px solid #DCDCDC;
    padding: 20px;
    margin: 0 10px 10px 0;
    border-radius: 5px 5px 5px 5px;
}

.recommend-friend .sub_type {
    float: left;
    margin: 10px 16px 0 0;
    padding: 4px 10px;
    border-radius: 5px;
    border: 1px solid #BCD2FF;
}

.recommend-friend .sub_type.active {
    color: #FFFFFF;
    background: #FFA500;
    border: 1px solid #FFA500;
    margin: 10px 16px 0 0;
}

.recommend-friend .event-form .top {
    text-align: center;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    color: #444444;
    border: 1px solid #CECFD0;
    height: 60px;
    box-sizing: border-box;
    line-height: 60px;
}

.recommend-friend .el-divider--horizontal {
    margin: 20px 0;
}

.hand{
    cursor:pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.op:active{
    opacity:0.6;
    -webkit-transition:all .1s;transition:all .1s;opacity:.6;
}

.op{
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .5s;
    transition: all .5s;
}
.sc:active{
    transform:scale(0.97);
}

/deep/ .event-form .el-dialog__header {
    padding: 0;
}

/deep/ .event-form .el-dialog__body {
    padding: 0;
}

/*下划线*/
.el-divider--horizontal {
    margin: 0;
}

/*mini按钮*/
.el-button--mini {
    padding: 4px 14px;
}
</style>
