<template>
  <div class="space-head">
		<div style="height: 375px;width: 100%;">
			<div style="height: 244px;width: 100%;overflow:hidden;" :style="{'background-image': `url(${userInfo.bgImage})`, 'background-repeat':'no-repeat',  'background-size': '100%'}">
				<div style="color: #fff; padding: 26px 20px; font-size: 22px">
        <div v-if="type !== 'circle'" class= "hand op" style="width: 90px; padding: 2px 0; border-radius: 5px; text-align: center; background-color: rgb(0, 0, 0, 0.3)" @click="toCircleIndex">
          <img src="../../../assets/imgs/circle/back.png" style="width: 26px; height: 26px; margin-right: 6px; margin-bottom: 2px; vertical-align: middle"/>
          <span>返回</span>
        </div>
        <div v-else>
          <div style="width: 30px; height: 30px;"></div>
        </div>
      </div>
			</div>
			<div style="height: 131px;width: calc(100% - 40px) ;margin: -50px 20px 0 20px">
				<div v-if="user.uuid != userInfo.uuid">
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex; width: 70%">
            <img :src="userInfo.headIco + '-200x200'" class="head-ico">
            <div style="width: 78%;">
              <div style="display: flex; margin: 68px 0 12px 0">
                <span style="font-size: 26px; margin-right: 26px; font-weight: 600" :style="{color: userInfo.isVip == 1 ? '#FC4956' : ''}">{{userInfo.nickName}}</span>
                <span v-show="userInfo.userType == 3" style="width: 90px; height: 22px; background-color: #F5CF0E; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px; margin-right: 20px;">企业子账号</span>
                <span v-show="userInfo.userType == 2 && userInfo.organizationType == 1" style="width: 76px; height: 22px; background-color: #1479FF; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">企业账号</span>
                <span v-show="userInfo.userType == 2 && userInfo.organizationType == 2" style="width: 76px; height: 22px; background-color: #1479FF; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">机关账号</span>
                <span v-show="userInfo.userType == 2 && userInfo.organizationType == 3" style="width: 76px; height: 22px; background-color: #1479FF; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">社团账号</span>
                <span v-show="userInfo.userType == 2 && userInfo.organizationType == 4" style="width: 80px; height: 22px; background-color: #1479FF; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">工作室账号</span>
                <img v-show="userInfo.isVip == 1" src="../../../assets/imgs/circle/vipB.png" style="margin-right: 16px; margin-top: 7px; width: 24px; height: 24px;"/>
                <img v-show="userInfo.v == 1" src="../../../assets/imgs/circle/vipBig.png" style="margin-right: 16px; margin-top: 8px; width: 24px; height: 24px;"/>
                <img v-show="userInfo.realNameStatus == 2 && userInfo.userType == 1" src="../../../assets/imgs/circle/realName.png" style="margin-right: 16px; margin-top: 6px; width: 70px; height: 27px;"/>
                <img v-show="userInfo.realNameStatus == 2 && userInfo.userType > 1" src="../../../assets/imgs/circle/certified.png" style="margin-right: 16px; margin-top: 6px; width: 70px; height: 27px;"/>
                <img v-show="userInfo.authenticationStatus >= 2" src="../../../assets/imgs/circle/bigP.png" style="margin-right: 16px; margin-top: 6px; width: 70px; height: 27px;"/>
                <img v-show="userInfo.expertAuthStatus === 2" src="../../../assets/imgs/circle/bigS.png" style="margin-right: 16px; margin-top: 6px; width: 70px; height: 27px;"/>
              </div>
              <div style="font-size: 16px">
                <span style="color: #999999; margin-right: 20px" v-if="userInfo.address.cityName">{{userInfo.address.cityName[1]}}</span>
                <span style="color: #999999; margin-right: 20px"> | </span>
                <span style="color: #999999; margin-right: 20px" v-if="userInfo.userType == 2">{{nameInfo.Name}}官方账号</span>
                <span style="color: #999999; margin-right: 20px" v-if="userInfo.userType == 3">{{nameInfo.Name}}官方子账号</span>
<!--                <span style="color: #999999; margin-right: 24px" v-else>{{userInfo.major}}</span>-->
                <span style="color: #999999; margin-right: 20px" v-if="userInfo.userType > 1"> | </span>
                <span style="color: #1479FF; margin-right: 20px" class="hand op" @click="getFollowList">关注</span>
                <span style="margin-right: 20px">{{userInfo.myFollowNum}}</span>
                <span style="color: #999999; margin-right: 20px"> | </span>
                <span style="color: #1479FF; margin-right: 20px" class="hand op" @click="getFansList">粉丝</span>
                <span style="margin-right: 20px">{{userInfo.fansNum}}</span>
              </div>
            </div>
          </div>
          <div>
            <div style="display: flex; justify-content: end; min-height: 40px">
              <div v-if="type === 'circle'" class="hand sc cover-btn" style="background-color: #FF9800; color: #ffffff; border: none" @click="openInfo(userInfo.uuid, userInfo.userType)">
                <img src="../../../assets/imgs/circle/go.png" style="width: 16px; height: 14px; margin-right: 8px; vertical-align: middle"/>
                <span style=" vertical-align: middle">进入个人空间</span>
              </div>
            </div>

            <div style="display: flex; margin-top: 50px">
              <div v-if="(user.uuid === userInfo.fid || user.fid === userInfo.uuid || (user.userType === 3 && userInfo.userType === 3 && user.fid === userInfo.fid)) === false">
                <div v-if="(userInfo.isFans === 0 && userInfo.isFriend === -2) || (userInfo.isFans === 0 && userInfo.isFriend === 4) || (userInfo.isFans === 0 && userInfo.isFriend === -1) || (userInfo.isFans === 0 && userInfo.isFriend === 2)" style="display: flex; margin-right: 10px">
                  <div class="hand sc pay-btn" @click="toPayFriends">
                    <i class="el-icon-plus" style="vertical-align: middle; font-weight: 600; margin-right: 6px"></i>
                    <span style=" vertical-align: middle">关注</span>
                  </div>
                  <div class="hand sc friend-btn" @click="toAddFriends">
                    <img src="../../../assets/imgs/circle/addFriends.png" style="width: 22px; height: 22px; margin-right: 6px; vertical-align: middle"/>
                    <span style=" vertical-align: middle">加为好友</span>
                  </div>
                </div>

                <div v-if="(userInfo.isFans === 1 && userInfo.isFriend === 0) || (userInfo.isFans === 1 && userInfo.isFriend === 3)" style="display: flex; margin-right: 10px">
                  <div class="cel-btn">
                    <span style=" vertical-align: middle">已关注</span>
                  </div>
                  <div class="friend-msg">
                    <span style=" vertical-align: middle">待对方通过好友申请</span>
                  </div>
                </div>

                <div v-if="userInfo.isFans === 1 && userInfo.isFriend === 1" style="display: flex; margin-right: 10px">
                  <div class="cel-btn">
                    <span style=" vertical-align: middle">已关注</span>
                  </div>
                  <div class="hand sc friend-btn" @click="toDelFriends">
                    <img src="../../../assets/imgs/circle/addFriends.png" style="width: 22px; height: 22px; margin-right: 6px; vertical-align: middle"/>
                    <span style=" vertical-align: middle">解除好友</span>
                  </div>
                </div>

                <div v-if="(userInfo.isFans === 1 && userInfo.isFriend === -1) || (userInfo.isFans === 1 && userInfo.isFriend === 2)" style="display: flex; margin-right: 10px">
                  <div class="hand sc cel-btn" @click="toPayFriends">
                    <span style=" vertical-align: middle">取消关注</span>
                  </div>
                  <div class="friend-msg"  style="color: #cc0000;">
                    <span style=" vertical-align: middle">已解除好友关系</span>
                  </div>
                </div>

                <div v-if="userInfo.isFans === 1 && userInfo.isFriend === 4" style="display: flex; margin-right: 10px">
                  <div class="hand sc cel-btn" @click="toPayFriends">
                    <span style=" vertical-align: middle">取消关注</span>
                  </div>
                  <div class="friend-msg"  style="color: #cc0000;">
                    <span style=" vertical-align: middle">对方拒绝了好友申请</span>
                  </div>
                </div>

                <div v-if="userInfo.isFans === 1 && userInfo.isFriend === -2" style="display: flex; margin-right: 10px">
                  <div class="hand sc cel-btn" @click="toPayFriends">
                    <span style=" vertical-align: middle">取消关注</span>
                  </div>
                  <div class="hand sc friend-btn" @click="toAddFriends">
                    <img src="../../../assets/imgs/circle/addFriends.png" style="width: 22px; height: 22px; margin-right: 6px; vertical-align: middle"/>
                    <span style=" vertical-align: middle">加为好友</span>
                  </div>
                </div>
              </div>

              <div class="hand sc private-btn" @click="toMessages">
                <img src="../../../assets/imgs/circle/email.png" style="width: 20px; height: 20px; margin-right: 6px; vertical-align: middle"/>
                <span style=" vertical-align: middle">私信</span>
              </div>

            </div>
          </div>
        </div>
      </div>

				<div v-else>
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex;width: 70%">
            <div style="position: relative; width: 138px; height: 138px; margin-right: 18px">
              <img :src="userInfo.headIco+ '-200x200'" class="head-ico">
								<cropb-button  :width="200" :height="200" title="上传头像" clickType="img" style="position: relative; bottom: 50px; left: 95px;" class=" com-edt"  @update:fileInfo="saveImg"></cropb-button>
              </div>
            <div style="width: 78%;">
              <div style="display: flex; margin: 68px 0 12px 0">
                <span style="font-size: 26px; margin-right: 26px; font-weight: 600" :style="{color: userInfo.isVip == 1 ? '#FC4956' : ''}">{{userInfo.nickName}}</span>
                <span v-show="userInfo.userType == 3" style="width: 90px; height: 22px; background-color: #F5CF0E; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">企业子账号</span>
                <span v-show="userInfo.userType == 2 && userInfo.organizationType == 1" style="width: 76px; height: 22px; background-color: #1479FF; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">企业账号</span>
                <span v-show="userInfo.userType == 2 && userInfo.organizationType == 2" style="width: 76px; height: 22px; background-color: #1479FF; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">机关账号</span>
                <span v-show="userInfo.userType == 2 && userInfo.organizationType == 3" style="width: 76px; height: 22px; background-color: #1479FF; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">社团账号</span>
                <span v-show="userInfo.userType == 2 && userInfo.organizationType == 4" style="width: 76px; height: 22px; background-color: #1479FF; color: #FFFFFF;text-align: center; border-radius: 5px; margin-top: 6px; padding: 2px 6px;margin-right: 20px;">个体账号</span>
                <img v-show="userInfo.isVip == 1" src="../../../assets/imgs/circle/vipB.png" style="margin-right: 16px; margin-top: 7px; width: 24px; height: 24px;"/>
                <img v-show="userInfo.V == 1" src="../../../assets/imgs/circle/vipBig.png" style="margin-right: 16px; margin-top: 8px; width: 24px; height: 24px;"/>
                <img v-show="userInfo.realNameStatus == 2 && userInfo.userType == 1" src="../../../assets/imgs/circle/realName.png" style="margin-right: 16px; margin-top: 6px; width: 70px; height: 27px;"/>
                <img v-show="userInfo.realNameStatus == 2 && userInfo.userType > 1" src="../../../assets/imgs/circle/certified.png" style="margin-right: 16px; margin-top: 6px; width: 70px; height: 27px;"/>
                <img v-show="userInfo.authenticationStatus >= 2" src="../../../assets/imgs/circle/bigP.png" style="margin-right: 16px; margin-top: 6px; width: 70px; height: 27px;"/>
                <img v-show="userInfo.expertAuthStatus === 2" src="../../../assets/imgs/circle/bigS.png" style="margin-right: 16px; margin-top: 6px; width: 70px; height: 27px;"/>

              </div>
              <div style="font-size: 16px">
                <span style="color: #999999; margin-right: 20px" v-if="userInfo.address.cityName">{{userInfo.address.cityName[1]}}</span>
                <span style="color: #999999; margin-right: 20px"> | </span>
                <span style="color: #999999; margin-right: 20px" v-if="userInfo.userType == 2">{{nameInfo.Name}}官方账号</span>
                <span style="color: #999999; margin-right: 20px" v-if="userInfo.userType == 3">{{nameInfo.Name}}官方子账号</span>
<!--                <span style="color: #999999; margin-right: 24px" v-else>{{userInfo.major}}</span>-->
                <span style="color: #999999; margin-right: 20px" v-if="userInfo.userType > 1"> | </span>
                <span style="color: #1479FF; margin-right: 20px" class="hand op" @click="getFollowList">关注</span>
                <span style="margin-right: 20px" >{{userInfo.myFollowNum}}</span>
                <span style="color: #999999; margin-right: 20px"> | </span>
                <span style="color: #1479FF; margin-right: 20px" class="hand op" @click="getFansList">粉丝</span>
                <span style="margin-right: 20px">{{userInfo.fansNum}}</span>
              </div>
            </div>
          </div>
          <div>
            <div style="display: flex; justify-content: end">
              <div class="hand sc cover-btn" style="margin-right: 10px" v-if="userInfo.userType === 3" @click="toEditProfile">
                <!--                      <img src="../../../assets/imgs/circle/camera.png" style="width: 16px; height: 14px; margin-right: 8px; vertical-align: middle"/>-->
                <span style="vertical-align: middle">修改密码</span>
              </div>
								<div style="margin-right: 10px">
									<cropb-button @update:fileInfo="updateBackground" text="编辑封面" :width="1500" :height="244"></cropb-button>
              </div>
								<div class="hand sc" @click="updateInfo = true">
									<el-button icon="el-icon-edit" type="primary" size="mini" round>编辑信息</el-button>
                  </div>
              </div>


            <div style="display: flex; justify-content: end" v-if="user.userType === 3">
              <div v-if="userInfo.subInfo.isAdmin === 0" class="edit-btn" style="height: 22px; color: #FFFFFF; background-color: #999999; line-height: 18px">
                <span style="vertical-align: middle">机构普通成员</span>
              </div>
              <div v-if="userInfo.subInfo.isAdmin === 1" class="edit-btn" style="height: 22px; color: #FFFFFF; background-color: #2970FF; line-height: 18px">
                <span style="vertical-align: middle">机构管理员</span>
              </div>
            </div>

            <div style="display: flex; justify-content: end" v-if="user.userType === 2">
              <div class="hand sc edit-btn" style="height: 22px; width: 100px; color: #FFFFFF; background-color: #031F88; line-height: 18px; margin-right: 8px" @click="toChildSet">
                <i class="el-icon-s-tools" style="font-size: 18px; font-weight: 600; vertical-align: middle; margin-right: 4px"></i>
                <span style="vertical-align: middle">管理子账号</span>
              </div>
              <div class="hand sc edit-btn" style="height: 22px; color: #FFFFFF; background-color: #2970FF; line-height: 18px" @click="toArchives">
                <img src="@/assets/imgs/circle/iconTo.png" style="width: 20px; height: 18px; margin-right: 6px; display: inline-block; vertical-align: middle">
                <span style="vertical-align: middle">进入机构档案</span>
              </div>
            </div>
            <div style="display: flex; justify-content: end" v-if="user.userType === 1">
              <div class="hand sc edit-btn" style="height: 22px; color: #FFFFFF; background-color: #2970FF; line-height: 18px" @click="toArchives">
                <img src="@/assets/imgs/circle/iconTo.png" style="width: 20px; height: 18px; margin-right: 6px; display: inline-block; vertical-align: middle">
                <span style="vertical-align: middle">进入个人档案</span>
              </div>
            </div>

            <!--                    <div v-else>-->
            <!--                        <div class="hand sc edit-save" v-if="user.userType === 1 || user.organizationType === 4" @click="checkoutEditProfile">-->
            <!--                            <span style="line-height: 14px">完成</span>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <div style="margin-top: 20px; float: right" v-if="checkoutEdit === true" @click="toFinishPerson">
              <span style="font-size: 14px; font-weight: 600; margin-right: 10px">允许推荐给他人</span>
              <el-switch
                  v-model="userInfo.referFriends"
                  @change="changeRecommendJob"
                  :active-value="0"
                  :inactive-value="1">
              </el-switch>
            </div>
          </div>
        </div>
      </div>
			</div>



      <el-dialog :visible.sync="showSubEdit" width="398px" class="edit-account" :close-on-click-modal="false" :modal="false">
        <div class="form-title" style="text-align: center">
          修改子账号密码
        </div>
        <el-form :model="accountForm" :rules="accountRules" ref="accountForm">
          <div>
            <div class="form-title">
              原子账号密码
            </div>
            <div style="display: flex">
              <el-form-item prop="oldPwd">
                <el-input type="textarea"
                          resize="none"
                          :rows="1"
                          v-model="accountForm.oldPwd"
                          style="margin-bottom: 16px; width: 300px">
                </el-input>
              </el-form-item>
            </div>
          </div>

          <div>
            <div class="form-title">
              子账号密码
            </div>
            <div style="display: flex">
              <el-form-item prop="pwd">
                <el-input type="textarea"
                          resize="none"
                          :rows="1"
                          v-model="accountForm.pwd"
                          style="margin-bottom: 16px; width: 300px">
                </el-input>
              </el-form-item>
            </div>
          </div>

          <!--                <div>-->
          <!--                  <div class="form-title">-->
          <!--                    子账号头像-->
          <!--                  </div>-->
          <!--                    <el-upload ref="uploadHead" accept="image/*" class="upload-demo" :limit='1' action="" drag :http-request="uploadImg" :show-file-list="false" :on-remove="removeImg">-->
          <!--                        <img v-if="headImg" width="50%" :src="headImg"/>-->
          <!--                        <i class="el-icon-upload" v-if="!headImg"></i>-->
          <!--                        <div v-if="!headImg">将图片拖到此处，或<em>点击上传</em></div>-->
          <!--                    </el-upload>-->
          <!--                </div>-->
          <div style="display: flex; justify-content: end">
            <el-button class="save-btn" @click="saveSubInfo" style="width: 100px">修改密码</el-button>
          </div>
        </el-form>
      </el-dialog>

      <el-dialog :visible.sync="updateInfo" width="398px" class="edit-account" :close-on-click-modal="false" :modal="false">
        <div class="form-title" style="text-align: center">
          <span v-if="user.userType === 1">编辑个人信息</span>
          <span v-if="user.userType === 2">编辑机构信息</span>
          <span v-if="user.userType === 3">编辑账号信息</span>
        </div>
        <div>
          <EditInfo @closeInfo="closeInfo"></EditInfo>
        </div>
      </el-dialog>

      <!--关注-->
      <el-dialog class="show-message" :visible.sync="showFollow" width="1000px">
        <div style="display: flex;">
          <div class="dialog-title">关注列表</div>
        </div>
        <div class="message-divider">
          <el-divider></el-divider>
        </div>
        <div class="message-body">
          <Follow :uuid="this.userInfo.uuid" :type="1"></Follow>
        </div>
      </el-dialog>

      <!--粉丝-->
      <el-dialog class="show-message" :visible.sync="showFans" width="1000px">
        <div style="display: flex;">
          <div class="dialog-title">粉丝列表</div>
        </div>
        <div class="message-divider">
          <el-divider></el-divider>
        </div>
        <div class="message-body">
          <Follow :uuid="this.userInfo.uuid" :type="2"></Follow>
        </div>
      </el-dialog>
    </div>
  </div>

</template>

<script>
    import ToMessage from "./ToMessage";
    import EditInfo from "@/pages/circle/components/EditInfo.vue";
    import Follow from "@/pages/circle/components/Follow.vue";
    import CropbButton from "@/components/common/CropbButton.vue";
    export default {
        name: "SpaceHead",
        components: {CropbButton, Follow, EditInfo, ToMessage},
        props: {
            isOrgan: {
                type: Number,
                default() {
                    return 0
                }
            },

            type: {
                type: String
            }
        },
        data() {
            return {
                showFollow: false,
                showFans: false,
                showSubEdit: false,
                accountForm: {
                    pwd: '',
                    oldPwd: '',
                    nickName: '',
                },
                accountRules: {
                  oldPwd: [
                    { required: true, message: '请输入子账号密码', trigger: 'blur' },
                    { pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, message: '密码至少为8位字母和数字的组合', trigger: 'blur'},
                  ],
                  pwd: [
                    { required: true, message: '请输入子账号密码', trigger: 'blur' },
                    { pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, message: '密码至少为8位字母和数字的组合', trigger: 'blur'},
                  ],
                },

                checkoutEdit: true,
                headImg: '',

                updateInfo: false,

                userInfo: {
                  address: {}
                },

                nameInfo: {},

                isFriend: false,

                organAccountInfo: {},
            }
        },

        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },

        methods: {
            // 获取关注列表
            getFollowList() {
              this.showFollow = true
            },

            // 获取粉丝列表
            getFansList() {
              this.showFans = true
            },

            // 关闭
            closeInfo() {
              this.updateInfo = false
              this.getUser(this.$route.params.id)
            },

            // 管理子账号
            toChildSet() {
              this.$emit('checkoutEdit', false)
            },

            // 进入个人档案
            toArchives() {
              this.utils.a('/archives')
            },

           toFinishPerson() {
               let that = this;
               that.userInfo.referFriends = false
               that.utils.confirm('请先完善个人信息中的专业领域', function () {
                 that.utils.b('/archives')
               })
           },

            // 就业推荐
            changeRecommendJob(status) {
              this.newApi.setReferFriends({status: status}).then(res => {
                if (res.isSuccess === 1) {
                  this.utils.sus(res.data)
                }
              })
            },


            saveSubInfo() {
                var that = this;
                that.$refs.accountForm.validate((valid) => {
                  if (valid) {
                    that.newApi.saveMyPwd({pwd:that.accountForm.pwd, oldPwd: that.accountForm.oldPwd}).then(res =>{
                      if (res.isSuccess == 1) {
                        that.user.pwd = that.accountForm.pwd;
                        that.user.oldPwd = that.accountForm.oldPwd;
                        that.utils.sus(res.data);
                        that.showSubEdit = false;
                      }
                    });
                }
              })
            },

            openInfo(uuid, userType) {
                if (userType === 1) {
                    this.$router.push({ path: '/circle/mySpace/' + uuid})
                }
                if (userType === 2 || userType === 3) {
                    this.$router.push({ path: '/circle/organSpace/' + uuid})
                }
            },

            // 关注&取消关注
            toPayFriends() {
                this.newApi.followAccount({uuid: this.userInfo.uuid}).then(res => {
                    if (res.isSuccess == 1) {
                        this.utils.sus(res.data);
                        this.getUser(this.userInfo.uuid)
                    }
                });
            },

            // 加好友
            toAddFriends() {
              if (this.user.authenticationStatus !== 2 && this.user.userType === 1) {
                store.commit('setShowAuthPopup', 1);
                return false;
              } else {
                this.newApi.addFriends({uuid: this.userInfo.uuid}).then(res => {
                  if (res.isSuccess == 1) {
                    this.utils.sus(res.data);
                    this.getUser(this.userInfo.uuid)
                  }
                })
              }
            },

            // 解除好友
            toDelFriends() {
                this.newApi.delFriends({uuid: this.userInfo.uuid}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.utils.sus(res.data);
                        this.getUser(this.userInfo.uuid)
                    }
                })
            },

            // 私信
            toMessages() {
              const routeData = this.$router.resolve({name: 'News', query: {uuid: this.userInfo.uuid}})
              window.open(routeData.href, '_blank');
            },

            // 获取用户信息
            getUser(uuid) {
                this.newApi.getSpaceInfo({uuid: this.user.uuid === uuid ? '' : uuid}).then(res =>{
                    this.userInfo = res.data.userSpaceInfo
                    this.userInfo.isFans = res.data.isFans
                    this.userInfo.isFriend = res.data.isFriend
                    if (res.data.subInfo) {
                      this.userInfo.subInfo = res.data.subInfo
                    }
                    if (res.data.institutionData) {
                      this.nameInfo = res.data.institutionData
                    }

                })
            },

            // 点击返回朋友圈
            toCircleIndex() {
                this.$router.go(-1)
            },

            // 点击编辑个人资料
            toEditProfile() {
                this.showSubEdit = true;
                this.accountForm.nickName = this.user.nickName;
                this.headImg = this.user.headIco;
            },

            checkoutEditProfile() {
                this.checkoutEdit = ! this.checkoutEdit;
                this.$emit('checkoutEdit', true);
            },

            // 上传图片
            uploadImg(params) {
                var that = this;
                var file = params.file;
                const isLt5M = file.size / 1024 / 1024 < 8;
                if (isLt5M) {
                    if (['image/png', 'image/jpeg', 'image/pbmp', 'image/jpg'].indexOf(file.type) !== -1) {
	                    that.$refs['uploadHead'].clearFiles();
						this.crop(200,200,URL.createObjectURL(file),function(url){
                            that.headImg = url
                        })
                    } else {
                        this.$message.error('只能上传图片类型!')
                    }

                } else {
                    this.$message.error('上传文件大小不能超过 8MB!')
                }
            },
            // 移除文件
            removeImg() {
                this.headImg = ''
            },



            // 点击修改头像
            saveImg(info) {
	            const that = this;
	            that.utils.upload(info.fileInfo,function (ret){
		            console.log(ret)
		            that.userInfo.headIco = ret.url
		            if (ret) {
			            that.newApi.saveUserHeadIco({headIco: ret.url}).then(res => {

                        that.utils.sus(res.data);
                    })
		            }else {
			            that.utils.err('上传失败，请稍后再试！')
                }
	            })
            },
	        updateBackground(info){
		        const that = this;
		        that.utils.upload(info.fileInfo,function (ret){
			        that.userInfo.bgImage = ret.url
					if (ret){
						that.newApi.saveUserBgImage({bgImage:ret.url}).then(res =>{
                        that.utils.sus(res.data);
                    })
					}else {
						that.utils.err('上传失败，请稍后再试！')
                }
		        })

            },
        },
        created() {
          this.getUser(this.$route.params.id)
        },

        mounted() {
            this.$nextTick(function() {
                this.$on('checkoutHead', function() {
                    this.toEditProfile()
                });
            });
        }
    }
</script>

<style scoped>
    .space-head {
        width: 100%;
	      height: 100%;
        background-color: #FFFFFF;
    }

    .space-head .dialog-title {
      height: 30px;
      padding: 10px 0;
      font-weight: 600;
      margin: 0 auto;
      font-size: 20px;
    }

    .space-head .message-body {
      padding: 20px;
    }

    .space-head .message-divider .el-divider--horizontal {
      margin: 0;
    }


    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .space-head .cover-btn {
        width: 90px;
        font-size: 14px;
        background-color: #fff;
        padding: 4px 0;
        border-radius: 5px;
        border: 1px solid;
        text-align: center;
    }

    .space-head .edit-btn {
        width: 160px;
        height: 17px;
        font-size: 14px;
        background-color: #fff;
        padding: 8px 10px;
        border-radius: 5px;
        line-height: 6px;border: 1px solid;
        margin-top: 50px;
        text-align: center;
    }
    .pay-btn {
        width: 76px;
        height: 16px;
        background-color: #fff;
        color: #FF9800;
        padding: 8px 10px;
        border-radius: 5px;
        line-height: 6px;
        margin-right: 10px;
        border: 1px #FF9800 solid;
        text-align: center;
    }

    .cel-btn {
        width: 76px;
        height: 16px;
        background-color: #fff;
        color: #FF9800;
        padding: 8px 10px;
        border-radius: 5px;
        line-height: 14px;
        margin-right: 10px;
        border: 1px #FF9800 solid;
        text-align: center;
    }

    .private-btn {
        width: 64px;
        height: 18px;
        background-color: #E4E6EB;
        padding: 8px 10px;
        border-radius: 5px;
        margin-right: 10px;
        line-height: 6px;
        text-align: center;
    }
    .friend-btn {
        width: 104px;
        height: 22px;
        background-color: #1479FF;
        color: #ffffff;
        padding: 6px 10px;
        border-radius: 5px;
        line-height: 0;
        text-align: center;
    }

    .friend-msg {
        width: 104px;
        color: #1479FF;
        line-height: 15px;
        border-radius: 5px;
        text-align: center;
    }

    .edit-save {
        width: 130px;
        height: 16px;
        background-color: #FF9800;
        color: #fff;
        padding: 8px 10px;
        border-radius: 5px;
        line-height: 6px;border: 1px solid;
        margin-top: 50px;
        text-align: center;
    }

    .head-ico {
        width: 126px;
        height: 126px;
        border-radius: 50%;
        border: 6px solid #fff;
    }

    .head-edt {
        width: 134px;
        height: 126px;
        background-color: #8a8a8a;
        border-radius: 60%;
        border: 6px solid #fff;
        margin-right: 18px;
        position: relative;
        background-size: cover;
    }


    .com-edt {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        position: absolute;
        bottom: 10px;
        right: 0;
    }

    .save-btn {
        width: 50px;
        height: 30px;
        color: #fff;
        padding: 6px 10px;
        background-color: #1C7EFF;
        border-radius: 5px;
    }

    .cancel-btn {
        width: 50px;
        height: 30px;
        padding: 6px 10px;
        margin-top: 20px;
        background-color: #E4E6EB;
        border-radius: 5px;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;
        transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .sc:active{
        transform:scale(0.97);
    }

    .dialog-title {
        height: 30px;
        padding: 10px 0;
        font-weight: 600;
        font-size: 20px;
    }

    .form-title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
    }

    /deep/ .show-message .el-dialog__header{
        padding: 0;
    }

    /deep/ .show-message .el-dialog__body{
        padding: 0;
    }

    /*下划线*/
    .el-divider--horizontal {
        margin: 0;
    }

    /deep/ .show-message .el-input__inner {
        height: 38px;
        line-height: 38px;
        background-color: #F0F2F5;
        border-radius: 30px;
        border: none;
    }

    /deep/ .edit-account .el-dialog__header{
        padding: 0;
    }
    /deep/ .el-upload-dragger{
	    height: 100%;
	    //border: 0;
	    border-radius: 0;
    }
</style>
