<template>
  <div class="edit-info">
    <el-form :model="personInfo" :rules="personRules" ref="accountForm">
      <div>
        <div class="form-title">
          账号昵称
        </div>
        <div>
          <el-form-item prop="nickName">
            <el-input type="textarea"
                      resize="none"
                      :rows="1"
                      v-model="personInfo.nickName"
                      style="margin-bottom: 16px; width: 300px">
            </el-input>
          </el-form-item>
        </div>
      </div>

      <div>
        <div class="form-title">
          <span v-if="user.userType === 1">个性签名</span>
          <span v-if="user.userType === 2">机构简介</span>
          <span v-if="user.userType === 3">账号简介</span>
        </div>
        <div>
          <el-form-item prop="content">
            <el-input type="textarea"
                      resize="none"
                      :rows="5"
                      placeholder="填写个性签名"
                      v-model="personInfo.personalSignature"
                      maxlength="50"
                      :show-word-limit="true"
                      style="margin-bottom: 16px">
            </el-input>
          </el-form-item>
        </div>
      </div>

      <div>
        <div class="form-title">
          <span v-if="user.userType === 1">个人标签</span>
          <span v-if="user.userType === 2">机构标签</span>
          <span v-if="user.userType === 3">账号标签</span>
        </div>
        <div>
          <el-form-item prop="tags">
            <div v-if="myLabelEdit === false">
              <el-tag class="tags-style" v-for="(item, index) in userLabel" :key="index" effect="plain" size="medium">{{ item }}</el-tag>
              <img src="@/assets/imgs/circle/editorC.png" class="hand op updateTags" @click="myLabelEdit = true"/>
            </div>
            <div v-else>
              <div class="hand op" style="color: #1C7EFF; font-size: 18px; margin-bottom: 16px" @click="myLabelAdd = true">
                <i class="el-icon-circle-plus-outline" style="margin-right: 12px"></i>
                <span>添加标签</span>
              </div>
              <el-tag closable class="tags-style" v-for="(item, index) in userLabel" :key="index" effect="plain" size="medium" @close="tagsClose(index)">{{ item }}</el-tag>
              <el-input
                  class="input-new-tag"
                  v-if="myLabelAdd === true"
                  v-model="myLabelValue"
                  ref="saveTagInput"
                  size="small"
                  maxLength="20"
                  @blur="handleInputConfirm"
              >
              </el-input>
            </div>
          </el-form-item>
        </div>
      </div>
      <div style="display: flex; justify-content: center">
          <div class="btn hand op" @click="$emit('closeInfo')" style="background-color: #E4E6EB; margin-right: 20px">取消修改</div>
          <div class="btn hand op" @click="submitInfo" style="color: #FFFFFF; background-color: #2E73FF">保存修改</div>
      </div>
    </el-form>
  </div>
</template>


<script>

export default {
  name: 'EditInfo',
  computed: {
    user() {
      return this.$store.state.user;
    },
    is_login(){
      return this.$store.state.is_login
    },
    router(){
      return this.$store.state.router
    }
  },
  data() {
    return {
      personInfo: {
        nickName: '',
        personalSignature: ''
      },
      personRules: {},

      myLabelEdit: false,
      myLabelAdd: false,
      myLabelValue:'',
      userLabel: [],
    }
  },

  props: {
  },

  created() {
    this.getUserInfo()
  },

  mounted() {
  },

  methods: {
    // 提交信息
    submitInfo() {
      if (!this.personInfo.nickName) {
        this.utils.msg('请填写账号昵称')
        return false
      }
      let params = {}
      params.label = JSON.stringify(this.userLabel)
      params.personalSignature = this.personInfo.personalSignature
      params.nickName = this.personInfo.nickName
      this.newApi.saveUserBasicInfo(params).then(res => {
          if (res.isSuccess === 1) {
            this.utils.sus(res.data)
            this.$emit('closeInfo')
            window.location.reload();
          }
      })
    },

    // 获取个人信息
    getUserInfo() {
      this.newApi.getSpaceInfo({uuid: this.user.uuid}).then(res => {
          if (res.isSuccess === 1) {
            this.personInfo.nickName = res.data.userSpaceInfo.nickName
            this.personInfo.personalSignature = res.data.userSpaceInfo.personalSignature
            this.userLabel = res.data.userSpaceInfo.label
          }
      })
    },

    // 删除标签
    tagsClose(e) {
      this.userLabel.splice(e, 1)
    },

    // 新增标签
    handleInputConfirm() {
      let inputValue = this.myLabelValue;
      if (inputValue) {
        this.userLabel.push(inputValue);
      }
      this.myLabelValue = '';
      this.myLabelAdd = false
    },

    // 获取标签
    getLabel:function (uuid) {
      var that = this;
      that.newApi.getUserLabel({uuid: uuid}).then((res)=>{
        that.userLabel = res.data;
      })
    },
  },
}

</script>

<style scoped>
.edit-info {

}

.edit-info .btn {
  width: 108px;
  text-align: center;
  padding: 4px 0;
  border-radius: 5px;
}

.edit-info .form-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
}

.edit-info .tags-style {
  border-radius: 20px;
  margin-right: 18px;
  font-size: 14px;
  color: #2970FF;
  vertical-align: middle;
}

.edit-info .updateTags {
  width: 28px;
  height: 28px;
  margin-left: 30px;
  vertical-align: middle;
}

.edit-info .input-new-tag {
  width: 160px;
  vertical-align: bottom;
  display: inline-block;
}

</style>
