<template>
    <div style="width: 100%;height: 100%;overflow: hidden">
        <div v-for="(item,index) in moments" :key="index">
            <img :src="item.url" class="low">
        </div>
        <div style="display: flex">
            <div style="width: 100%;height: auto;overflow: hidden" >
                <div style="width: 50%; height: auto; overflow: hidden;float: left">
                    <div style="width: 100%; height: 100%;">
                        <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/moments/moments12.jpg" style="width: 100%; height: auto;">
                    </div>
                </div>
                <div class="old" style="width: 50%; height: 100%; background: #FFFFFF;float: left;padding-top: 50px;">
                    <Introductionlogin></Introductionlogin>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Introductionlogin from "./components/Introductionlogin";
export default {
    components: {
        Introductionlogin

    },

    computed: {

    },
    data () {
        return {
            moments:[
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/moments/moments1.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/moments/moments2.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/moments/moments3.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/moments/moments4.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/moments/moments5.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/moments/moments6.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/moments/moments7.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/moments/moments8.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/moments/moments9.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/moments/moments10.jpeg"},
                {'url':"https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/moments/moments11.jpeg"},
            ]
        }
    },
    mounted:function(){

    },
    created:function () {

    },
    methods:{

    }
}
</script>
<style scoped>
.low {
    width: 100%;
    height: auto;
    overflow: hidden;
}
.old {
    width: 50%;
    height: 100%;
    background: #FFFFFF;
}
</style>
