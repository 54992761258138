<template>
    <div class="hot-topic">
        <div style="width: calc(100% - 20px);padding:0 10px;background: #FFFFFF;border-radius: 0;">
            <div class="module_title" style="margin-bottom: 8px">
                <div class="industry_title_text">热门话题</div>
                <div style="color: #999999; padding-top: 16px" class="hand op sc" @click="toMore">MORE ></div>
            </div>
            <div style="width: calc(100% - 20px);padding:0 10px;background: #FFFFFF;border-radius: 8px;">
                <div class="hand op sc" style="width: 100%;height: 18px;line-height: 18px; margin-bottom: 20px;" v-for="(item,index) in top_topic_list" v-bind:key="index" @click="toContent(item)">
                    <div>
                        <div style="width: 18px; height: 18px; text-align: center; line-height: 18px; background-color: #AFB5C5; color: #FFFFFF; float: left; margin-right: 10px; border-radius: 2px">{{index + 1}}</div>
                        <div style="width: calc(80% - 28px);white-space: nowrap;overflow: hidden;text-overflow: ellipsis; line-height: 18px;float:left;">#{{ item.name }}#</div>
                        <div style="width: 20%;float:left;text-align: right;color: #a6a6a6">{{ item.clickcount }}</div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "HotTopic",
    components: {},
    data() {
        return {
            top_topic_list: [],
            pageSize: 10
        }
    },
    props: {
      size: {
        type: Number,
        default() {
          return 10
        }
      }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {
        this.pageSize = this.size
        this.getHotTopicList();   //热门话题
        this.$EventBus.$on("changeData", (data) => {
            this.getHotTopicList();
        });
    },
    methods: {
      // 查看详情
      toContent(item) {
        if (!this.utils.toAuth(0)) {
          return false
        }
        this.b('/topic/info/'+item.name)
      },

        // 更多话题
        toMore() {
          if (!this.utils.toAuth(1)) {
            return false
          }
          this.b('/topic/list')
        },

        // 热点推荐话题
        getHotTopicList(){
            var that = this;
            var params = {};
            params.page = 1;
            params.pageSize = this.pageSize;
            that.newApi.getHotTopicList(params).then((res) =>{
                that.top_topic_list = res.data;
            });
        },
    }
}
</script>

<style scoped>
.hot-topic .module_title{
    width:100%;height:50px;display: flex; justify-content: space-between;
}
.hot-topic .industry_title_text{
    line-height: 50px;margin-left: 10px;color:#031F88;font-size: 18px;font-weight: bold;
}
</style>
