<template>
    <div class="circle-favorite">
        <div style="width: 100%; overflow: hidden; margin-bottom: 10px; background-color: white; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
            <div style="font-size: 20px; font-weight: bold; line-height: 45px; width: 20%; margin: 10px 0 10px 20px; border-bottom: 2px solid rgb(255, 152, 0);">我的收藏（共{{allLabelLength}}条）</div>
            <div class="tab border_bottom up"  v-for="(item, index) in testFavoriteClass" :key="index" @click="checkoutTags(item.tag)">
                <label>
                    <div class="head_white">
                        <div :class="indexTags === item.tag ? 'head_white_word_act': 'head_white_word'">{{item.tagName}}</div>
                        <div class="head_white_line"  v-if="indexTags === item.tag"></div>
                    </div>
                </label>
            </div>
        </div>
        <div class="tag-content">
            <div style="width: 100%; overflow: hidden; margin-bottom: 2px; background-color: white; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
                <div class="tag-select">
                  <div v-if="allLabel.length > 0">
                    <div v-for="(item,index) in allLabel" :key="index">
                      <div style="font-size: 18px; font-weight: 600; margin: 12px 0 6px 0" v-if="item.label.length !== 0">{{item.key}}</div>
                      <span class="hand op sc" style="margin-right: 20px; display: inline-block; color: #0080ff" v-for="(info, indexInfo) in item.label" :key="indexInfo" @click="getlabelContent(info.id, item.key)">
                            {{info.name}}
                            <el-dropdown @command="moreAction($event,info)" style="height: 14px; margin-right: 2px; margin-top: 5px;" >
                                <span class="el-dropdown-link op sc" style="display: block; color: #0080ff">
                                    <i class="el-icon-arrow-down"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="edit">编辑标签</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </span>
                      <div style="width: 100%; overflow:hidden;" v-if="item.key == isShow">
                        <div v-for="(item,index) in content_list" v-bind:key="item.id" >
                          <ContentALL :data="item" v-if="item.status === 1" @deleteContent="deleteContent" :index="index"></ContentALL>
                          <div v-if="item.status === 0" class="box-dynamic">
                            <NullBox data="内容,原作者已删除"></NullBox>
                            <div @click="clearCollection(item.id, item.bgbType)" class="hand op" style="text-align: center; height: 40px; color: #FFA500">取消收藏</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <NullBox data="收藏"></NullBox>
                  </div>
                </div>
            </div>
<!--            <div style="width: 100%; overflow: hidden; margin-bottom: 10px; background-color: white; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">-->
<!--                <div style="padding: 20px">-->
<!--                    <div style="width: 100%; overflow:hidden;">-->
<!--                        <div v-for="(item,index) in content_list" v-bind:key="index" >-->
<!--                            <ContentALL :data="item"></ContentALL>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>

      <!--机构账号选择支付类型-->
      <el-dialog :visible.sync="showReName" width="40%" :show-close="false" class="event-form">
        <div style="text-align: center; padding-top: 10px; font-size: 18px; font-weight: 600">
          修改标签名称
          <img src="@/assets/imgs/index/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showReName = false">
        </div>
        <div style="padding: 20px 40px; overflow: hidden">
          <div style="margin-bottom: 30px">
            <el-input v-model="reName" placeholder="请输入标签名称"></el-input>
          </div>
          <div style="display: flex; justify-content: center; margin-bottom: 10px">
            <div class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF;" @click="updateName">确定</div>
          </div>
        </div>
      </el-dialog>

    </div>
</template>

<script>
    import ContentALL from "../circle/components/ContentAll";
    import NullBox from "./components/NullBox";
    export default {
        name: "favorite",
        components: {NullBox, ContentALL},
        data() {
            return {
                indexTags: '0',
                content_list: [],
                testFavoriteClass: [
                    {
                        tag: '0',
                        tagName: '全部'
                    },
                    {
                        tag: '1',
                        tagName: '动态'
                    },
                    {
                        tag: '2',
                        tagName: '笔记'
                    },
                    {
                        tag: '3',
                        tagName: '朋友圈图片'
                    },
                    {
                        tag: '4',
                        tagName: '朋友圈视频'
                    },
                    {
                        tag: '5',
                        tagName: '朋友圈附件'
                    },
                    {
                        tag: '6',
                        tagName: '知识仓库文章'
                    },
                    {
                        tag: '7',
                        tagName: '知识仓库图片'
                    },
                    {
                        tag: '8',
                        tagName: '知识仓库视频'
                    },
                    {
                        tag: '9',
                        tagName: '知识仓库附件'
                    }
                ],

                allLabel: [],
                allLabelLength: 0,
                isShow: '',

                tagId: '',
                tagKey: '',

                showReName: false,
                reName: '',
                selectId: ''
            }
        },

        mounted() {
            this.checkoutTags(this.indexTags);
            this.getLabelList();
        },

        methods: {
          // 标签重命名
          updateName() {
            this.newApi.editCollectionLabel({name: this.reName, id: this.selectId}).then(res => {
              if (res.isSuccess === 1) {
                this.utils.sus(res.data);
                this.selectId = '';
                this.showReName = false;
                this.reName = '';
                this.checkoutTags(this.indexTags);
              }
            })
          },

            //  取消收藏
            clearCollection(id, bgbType) {
              this.newApi.cancelCollection({id: id, bgbType: bgbType}).then(res => {
                if (res.isSuccess === 1) {
                  this.utils.sus(res.data)
                  this. getlabelContent(this.tagId, this.tagKey);
                }
              })
            },

            // 删除内容更新
            deleteContent(e) {
              this.content_list.splice(e, 1)
            },

            // 根据标签id获取内容
            getlabelContent(id, key) {
                this.isShow = key;
                this.content_list = [];
                this.tagId = id;
                this.tagKey = key
                this.newApi.getCollectionContent({id: id}).then(res =>{
                    this.content_list = res.data
                })
            },


            // 切换菜单
            checkoutTags(tag) {
                this.indexTags = tag;
                this.newApi.getUserCollectionList({type: tag}).then(res =>{
                    this.allLabel = res.data
                })
            },

            // 获取所有标签
            getLabelList() {
                this.newApi.getUserCollectionLabelList().then(res =>{
                    this.allLabelLength = res.data.length
                })
            },

            //字母移入事件
            mouseOver($e) {
                $e.currentTarget.className="letter active";
            },
            //字母移出事件
            mouseLeave($e) {
                $e.currentTarget.className="letter";
            },

            // 选择编辑和删除标签
            moreAction(e, item) {
                if (e === 'edit') {
                  this.selectId = item.id;
                  this.showReName = true;
                  this.reName = item.name
                }
            },

            sub_letter(e) {
                console.log(e)
            },
        }
    }
</script>

<style scoped>
    .tab {
        text-align: center;
        margin: auto 1%;
        float: left;
        text-align: center;
        cursor:pointer;
    }
    .up {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.1s;
    }
    .head_white {
        height: 25px;
        padding: 15px 0;
        margin: 0 10px;
    }
    .head_white_word {
        height: 26px;
        text-align: center;
        line-height:26px;
        color: black;
        font-size: 15px;
        font-weight: 600;
    }
    .head_white_word_act {
        height: 26px;
        text-align: center;
        line-height:26px;
        color: #2970FF;
        font-size: 15px;
        font-weight: 600;
    }
    .head_white_line {
        width: 100%;
        height: 6px;
        text-align: center;
        background-color: #FF9800;
        border-radius: 5px;
        margin-top: 8px;
    }

    .tag-select {
        font-size: 16px;
        padding: 10px 30px;
    }

    /*下划线*/
    .el-divider--horizontal {
        margin: 0;
    }

    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .sc:active{
        transform:scale(0.97);
        color: #4482FF;
    }

    .box-dynamic{
        width: 100%;
        box-sizing: border-box;
        margin: 10px 0;
        background-color: #ffffff;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        cursor: pointer;
    }

    .foot-btn {
      width: 128px;
      height: 20px;
      font-size: 16px;
      border-radius: 5px;
      text-align: center;
      padding: 10px;
    }
</style>
