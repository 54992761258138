<template>
    <div class="circle-message" ref="message">
        <div style="width: 100%; overflow: hidden; margin-bottom: 10px; background-color: white; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
            <div>
                <el-input placeholder="搜索私信内容" v-model="searchContent" class="input-with-select">
                    <div class="hand op sc" style="width: 40px; text-align: center" slot="append">
                        <img style="width: 18px; height: 18px;" src="../../assets/imgs/circle/searchIcon.png">
                    </div>
                </el-input>
            </div>
        </div>

        <div style="width: 100%; overflow: hidden; margin-bottom: 10px; background-color: white;  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);">
            <div class="tab border_bottom up">
                <label>
                    <div class="head_white">
                        <div class="head_white_word hand op" :style="indexTags === 'receive' ? 'color: #2970FF' : ''" @click="clickNavigation('receive')">最新私信</div>
                    </div>
                </label>
            </div>
            <div v-if="receiveMessageList.length > 0" style="height: 20px; margin-top: 18px; border-right: 2px solid #999999; float: left"></div>
            <div class="tab border_bottom up" v-for="(item, index) in receiveMessageList" :key="index">
                <label v-if="countStart <= index && index <= countEnd">
                    <div class="head_white">
                        <div class="head_white_word hand op" v-if="user.uuid === item.sendUserInfo.uuid" :style="indexTags === item.receiveUserInfo.uuid ? 'color: #2970FF' : ''" @click="clickNavigation(item.receiveUserInfo.uuid)">{{item.receiveUserInfo.nickName}}</div>
                        <div class="head_white_word hand op" v-if="user.uuid === item.receiveUserInfo.uuid" :style="indexTags === item.sendUserInfo.uuid ? 'color: #2970FF' : ''" @click="clickNavigation(item.sendUserInfo.uuid)">{{item.sendUserInfo.nickName}}</div>
                    </div>
                </label>
            </div>
        </div>

        <div v-if="indexTags === 'receive'">
            <div style="width: 1132px; padding: 24px 42px; margin-bottom: 10px; background-color: white; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
                <div v-for="(item, index) in receiveMessageList" :key="index">
                    <div v-if="user.uuid === item.receiveUserInfo.uuid">
                        <div style="display: flex; justify-content: space-between; margin-bottom: 12px">
                            <div style="display: flex">
                                <img :src="item.sendUserInfo.headIco" style="width: 36px; height: 36px; border-radius: 50%; margin-right: 12px">
                                <div style="font-size: 20px; font-weight: 600; margin-top: 2px; margin-right: 15px">{{item.sendUserInfo.nickName}}</div>
                                <div style="color: #999999; font-size: 14px; margin-top: 8px; margin-right: 20px">{{item.updateTime}}</div>
                            </div>
                            <div style="margin-top: 10px">
                                <el-popover
                                    placement="right"
                                    width="800"
                                    trigger="click">
                                    <div>
                                        <div>
                                            <el-input type="textarea" rows="4" style="width: 100%; margin-right: 12px" v-model="receiveContent"></el-input>
                                            <div style="display: flex; justify-content: end">
                                                <img @click="sendMessage(index)" src="../../assets/imgs/circle/send.png" class="hand op sc" style="width: 24px; height: 24px; margin-top: 6px"/>
                                            </div>
                                        </div>
                                    </div>
                                    <el-button slot="reference" style="background-color: #FF9800; color: #fff; width: 54px; height: 18px; font-size: 12px; line-height: 2px">回复</el-button>
                                </el-popover>
                            </div>
                        </div>
                        <div style="font-size: 16px; padding: 10px; background-color: #F3F3F3; border-radius: 5px">
                            {{item.content}}
                        </div>
                    </div>
                    <div v-if="user.uuid === item.sendUserInfo.uuid">
                        <div style="display: flex; justify-content: space-between; margin-bottom: 12px">
                            <div style="display: flex">
                                <img :src="item.receiveUserInfo.headIco" style="width: 36px; height: 36px; border-radius: 50%; margin-right: 12px">
                                <div style="font-size: 20px; font-weight: 600; margin-top: 2px; margin-right: 15px">{{item.receiveUserInfo.nickName}}</div>
                            </div>
                            <div style="margin-top: 20px">
                                <span style="margin-right: 20px">{{item.updateTime}}</span>
                                <span style="font-size: 14px; color: #2970FF">已发送</span>
                            </div>
                        </div>
                        <div style="font-size: 16px; padding: 10px; background-color: #F3F3F3; border-radius: 5px">
                            {{item.content}}
                        </div>
                    </div>
                    <el-divider></el-divider>
                </div>
            </div>
        </div>
        <div v-else>
            <div style="width: 100%; margin-bottom: 10px; background-color: white; box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600)">
                <ToMessage :uuid="indexTags"></ToMessage>
            </div>
        </div>
    </div>
</template>

<script>
    import ToMessage from "./components/ToMessage";
    export default {
        name: "message",
        components: {ToMessage},
        data() {
            return {
                indexTags: 'receive',
                receiveEditor: false,
                page: 1,
                more:1,
                pageSize: 10,
                receiveContent: '',  // 接收信息 回复内容
                receiveMessageList: [],

                moreUser: 1,
                pageUser: 1,
                userInfo: {},
                showMessage: false,
                sendContent: '',
                messageList: [],

                searchContent: '',
                countStart: 0,
                countEnd: 7,
            }
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },

        updated() {
            var that = this;
            window.onscroll = function(){
                that.topLong = window.scrollTop;
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                if(scrollHeight - scrollTop - windowHeight <= 0){
                    if (that.more == 1) {
                        that.getReceiveMessage()
                    }else{
                        console.log("已加载完");
                    }
                }
            }
        },

        mounted() {
            var that = this;
            that.backTop();
            that.getReceiveMessage();
            window.onscroll = function(){
                that.topLong = window.scrollTop;
                var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                //变量windowHeight是可视区的高度
                var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                //变量scrollHeight是滚动条的总高度
                var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
                if(scrollHeight - scrollTop - windowHeight <= 0){
                    if (that.more == 1) {
                        that.getReceiveMessage()
                    }else{
                        console.log("已加载完");
                    }
                }
            }
        },

        methods: {
            // 计数
            counterEnd() {
                if (this.countEnd < this.receiveMessageList.length - 1) {
                    this.countEnd ++;
                    this.countStart ++;
                }
            },
            counterStart() {
                if (this.countStart > 0) {
                    this.countEnd --;
                    this.countStart --;
                }
            },

            clickNavigation(id) {
                this.indexTags = id
            },

            // 返回顶部
            backTop () {
                document.documentElement.scrollTop = document.body.scrollTop = 0;
            },

            // 获取接收私信列表
            getReceiveMessage() {
                this.newApi.getMsgList({page: this.page, pageSize: this.pageSize}).then(res =>{
                    for (const item of res.data) {
                        this.receiveMessageList.push(item)
                    }
                    if (res.data.length < 10) {
                        this.more = 0;
                        this.utils.sus('全部私信加载完成！')
                    } else {
                        this.page ++;
                    }
                })
            },

            // 回复私信
            sendMessage(index) {
                var uuid = '';
                if (this.receiveMessageList[index].sendUserInfo.uuid == this.user.uuid) {
                    uuid = this.receiveMessageList[index].receiveUserInfo.uuid
                } else {
                    uuid = this.receiveMessageList[index].sendUserInfo.uuid
                }
                this.newApi.sendMsg({uuid: uuid, content: this.receiveContent}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.receiveContent = '';
                        this.receiveMessageList = [];
                        this.page = 1;
                        this.more = 1;
                        this.getReceiveMessage();
                        this.utils.sus(res.data)
                    }
                })
            }
        },
    }
</script>

<style scoped>
    .head_white{
        height: 26px;
        padding: 14px 0;
    }
    .head_white_word{
        height: 26px;
        text-align: center;
        line-height:26px;
        color: #999999;
        font-size: 17px;
        font-weight: 600;
        margin-left: 20px;
        float: left;
    }
    .inner-container {
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .inner-container::-webkit-scrollbar {
        display: none;
    }

    .tab {
        text-align: center;
        margin: auto 1%;
        float: left;
        text-align: center;
        cursor:pointer;
    }
    .up {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 0.1s;
    }

    .dialog-title {
        height: 30px;
        padding: 10px 0;
        font-weight: 600;
        font-size: 20px;
    }

    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .sc:active{
        transform:scale(0.97);
    }

    /deep/ .show-message .el-dialog__header{
        padding: 0;
    }

    /deep/ .show-message .el-dialog__body{
        padding: 0;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #EDC34E;
    }

    .message-divider .el-divider--horizontal {
        margin: 0;
    }

    /deep/ .el-input__inner {
        height: 38px;
        line-height: 38px;
        background-color: #FFFFFF;
        border-radius: 30px;
        border: none;
    }

</style>